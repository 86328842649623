import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import API from "../api";
import { getLanguage } from "../utils/utils";

const translations = {
  hr: {
    loginInformation: "Molimo unesite Vaše korisničke podatke.",
    email: "Email",
    password: "Lozinka",
    pleaseWait: "Molimo pričekajte...",
    login: "Prijava",
  },
  en: {
    loginInformation: "Please provide your login details.",
    email: "Email",
    password: "Password",
    pleaseWait: "Please wait...",
    login: "Submit",
  },
  sr: {
    loginInformation: "Molimo unesite Vaše korisničke podatke.",
    email: "Email",
    password: "Lozinka",
    pleaseWait: "Molimo pričekajte...",
    login: "Prijava",
  },
};

export default class Login extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      email: "",
      password: "",
      loggedIn: false,
      error: "",
      messages: translations[getLanguage()],
    };
  }

  componentWillMount() {
    localStorage.removeItem("token");
  }

  _inputOnChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  _formOnSubmit = async (event, callback) => {
    event.preventDefault();

    this.setState({
      error: "",
      isLoggingIn: true,
    });

    await API.post("", {
      action: "userLogin",
      email: this.state.email,
      password: this.state.password,
    })
      .then((response) => {
        if (response.data && response.data.token) {
          localStorage.setItem("token", response.data.token);
          localStorage.setItem(
            "certifier",
            JSON.stringify(response.data.user.certifier)
          );
          this.setState({
            loggedIn: true,
          });
        }
      })
      .catch((error) => {
        this.setState({
          error: "Kriva email adresa ili lozinka. Pokušajte ponovo.",
        });
      });

    this.setState({
      isLoggingIn: false,
    });
  };

  _onInputChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  render() {
    const { email, error, isLoggingIn, loggedIn, password, messages } =
      this.state;

    if (loggedIn) {
      return <Redirect to="/" />;
    }

    return (
      <div className="wrap">
        <div className="page__intro">
          <article>{messages.loginInformation}</article>
        </div>
        <form
          className="form form--login"
          method="post"
          onSubmit={this._formOnSubmit}
        >
          {error && <p className="error">{error}</p>}
          <fieldset className="fieldset">
            <label className="label">{messages.email}</label>
            <input
              type="email"
              required="required"
              name="email"
              className="input"
              value={email}
              onChange={this._onInputChange}
            />
          </fieldset>
          <fieldset className="fieldset">
            <label className="label">{messages.password}</label>
            <input
              type="password"
              required="required"
              name="password"
              className="input"
              value={password}
              onChange={this._onInputChange}
            />
          </fieldset>
          <fieldset className="fieldset">
            <button type="submit" className="button">
              {isLoggingIn ? messages.pleaseWait : messages.login}
            </button>
          </fieldset>
        </form>
      </div>
    );
  }
}
