import React, { Component } from "react";
import { NumberInput } from "../components";
import { getLanguage } from "../utils/utils";

const list = {
  hr: [
    {
      id: "1",
      label:
        "sastanci (npr. kolegiji, online sastanci, video konferencije, konferencijski pozivi, redoviti skupovi zaposlenika, kava s Predsjednikom Uprave i slično)",
    },
    {
      id: "2",
      label:
        "pisani komunikacijski materijali (npr. oglasne ploče, tiskano interno glasilo, okružnice, brošure, posteri i slično)",
    },
    {
      id: "3",
      label:
        "digitalne metode komunikacije (npr. službeni e-mail, pristup zajedničkim folderima (kroz SharePoint, OneDrive i slično), interne društvene mreže / kolaborativne platforme, interna web stranica (Intranet), mobilne aplikacije za krajnje korisnike, video walls, newsletteri i slično)",
    },
    {
      id: "4",
      label:
        "ankete / ispitivanje stavova zaposlenika (npr. ispitivanje zadovoljstva, ispitivanje organizacijske kulture i klime, kraća follow-up ispitivanja i slično)",
    },
    {
      id: "5",
      label:
        "aktivnosti u sklopu individualnog praćenja zaposlenika (npr. razgovori o radnoj uspješnosti i razvoju karijere, orijentacijski programi, ulazni intervju, stay intervjui i slično)",
    },
    {
      id: "6",
      label:
        "neformalni susreti zaposlenika (npr. teambuilding aktivnosti, virtualne kave, druženja na kraju radnog tjedna, osiguran prostor za zajednički ručak i slično)",
    },
    { id: "7", label: "ostalo" },
  ],
  en: [
    {
      id: "1",
      label:
        "sastanci (npr. kolegiji, online sastanci, video konferencije, konferencijski pozivi, redoviti skupovi zaposlenika, kava s Predsjednikom Uprave i slično)",
    },
    {
      id: "2",
      label:
        "pisani komunikacijski materijali (npr. oglasne ploče, tiskano interno glasilo, okružnice, brošure, posteri i slično)",
    },
    {
      id: "3",
      label:
        "digitalne metode komunikacije (npr. službeni imejl, pristup zajedničkim folderima (kroz SharePoint, OneDrive i slično), interne društvene mreže / kolaborativne platforme, interna web stranica (Intranet), mobilne aplikacije za krajnje korisnike, video walls, newsletteri i slično)",
    },
    {
      id: "4",
      label:
        "ankete / ispitivanje stavova zaposlenika (npr. ispitivanje zadovoljstva, ispitivanje organizacijske kulture i klime, kraća follow-up ispitivanja i slično)",
    },
    {
      id: "5",
      label:
        "aktivnosti u sklopu individualnog praćenja zaposlenika (npr. razgovori o radnoj uspešnosti i razvoju karijere, orijentacioni programi, ulazni intervju, stay intervjui i slično)",
    },
    {
      id: "6",
      label:
        "neformalni susreti zaposlenika (npr. teambuilding aktivnosti, virtualne kave, druženja na kraju radnog tjedna, osiguran prostor za zajednički ručak i slično)",
    },
    { id: "7", label: "ostalo" },
  ],
  sr: [
    {
      id: "1",
      label:
        "sastanci (npr. kolegiji, online sastanci, video konferencije, konferencijski pozivi, redoviti skupovi zaposlenika, kava s Predsjednikom Uprave i slično)",
    },
    {
      id: "2",
      label:
        "pisani komunikacijski materijali (npr. oglasne ploče, tiskano interno glasilo, okružnice, brošure, posteri i slično)",
    },
    {
      id: "3",
      label:
        "digitalne metode komunikacije (npr. službeni imejl, pristup zajedničkim folderima (kroz SharePoint, OneDrive i slično), interne društvene mreže / kolaborativne platforme, interna web stranica (Intranet), mobilne aplikacije za krajnje korisnike, video walls, newsletteri i slično)",
    },
    {
      id: "4",
      label:
        "ankete / ispitivanje stavova zaposlenika (npr. ispitivanje zadovoljstva, ispitivanje organizacijske kulture i klime, kraća follow-up ispitivanja i slično)",
    },
    {
      id: "5",
      label:
        "aktivnosti u sklopu individualnog praćenja zaposlenika (npr. razgovori o radnoj uspešnosti i razvoju karijere, orijentacioni programi, ulazni intervju, stay intervjui i slično)",
    },
    {
      id: "6",
      label:
        "neformalni susreti zaposlenika (npr. teambuilding aktivnosti, virtualne kave, druženja na kraju radnog tjedna, osiguran prostor za zajednički ručak i slično)",
    },
    { id: "7", label: "ostalo" },
  ],
};

export default class QuestionCommlist extends Component {
  constructor(props) {
    super(props);

    let data = {};
    let previousData = {};

    try {
      data = JSON.parse(this.props.data.value);
    } catch (error) {}

    try {
      previousData = JSON.parse(this.props.previousData.value);
    } catch (error) {}

    this.state = {
      data: data,
      previousData: previousData,
      items: list[getLanguage()],
    };
  }

  _inputOnChange = (event) => {
    let data = this.state.data;
    data[event.target.name] = event.target.value;

    this.setState({
      data: data,
    });

    this.props.onChange({
      target: {
        name: this.props.name,
        value: JSON.stringify(this.state.data),
        dataset: {
          id: this.props.id,
          question: this.props.question,
        },
      },
    });
  };

  render() {
    const { copied } = this.props;

    const { data, items, previousData } = this.state;

    return (
      <table className="table">
        <thead>
          <tr>
            <th>Mogući kanali komunikacije:</th>
            <th>Postotak zaposlenika</th>
            <th>Grupe zaposlenika koje su obuhvaćene</th>
          </tr>
        </thead>
        <tbody>
          {items.map((item, key) => (
            <tr key={key}>
              <td>{item.label}</td>
              <td style={{ whiteSpace: "nowrap" }}>
                <NumberInput
                  id={item.id}
                  question={item.id}
                  type="percentage"
                  label={""}
                  min={0}
                  max={100}
                  name={`comm_percentage_${item.id}`}
                  value={
                    data[`comm_percentage_${item.id}`]
                      ? data[`comm_percentage_${item.id}`]
                      : ""
                  }
                  previousValue={
                    previousData[`comm_percentage_${item.id}`]
                      ? previousData[`comm_percentage_${item.id}`]
                      : ""
                  }
                  copied={copied}
                  onChange={this._inputOnChange}
                />
              </td>
              <td>
                <input
                  type="text"
                  name={`comm_info_${item.id}`}
                  value={
                    data[`comm_info_${item.id}`]
                      ? data[`comm_info_${item.id}`]
                      : ""
                  }
                  className="input"
                  onChange={this._inputOnChange}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  }
}
