import React, { Component } from "react";
import ReportContent from "./ReportContent";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.bubble.css";

const editorModules = {
  syntax: false,
  toolbar: [
    ["bold", "italic", "underline"],
    [{ size: ["small", false, "large", "huge"] }],
    [
      {
        color: ["#000000", "#cc0000"],
      },
    ],
    [{ list: "ordered" }, { list: "bullet" }],
    ["clean"],
  ],
};

export default class ReportFullInput extends Component {
  render() {
    const { ab, id, editable, name, title, defaultValue, onChange } =
      this.props;

    return (
      <ReportContent
        id={id}
        ab={ab}
        title={title}
        additionalClass="report__section--padding-noend"
      >
        <ReactQuill
          readOnly={!editable}
          className="report__input report__input--full report__input--smaller"
          theme="bubble"
          modules={editorModules}
          onChange={(value) => {
            onChange({
              target: {
                name: name,
                value: value,
              },
            });
          }}
          defaultValue={defaultValue}
        />
      </ReportContent>
    );
  }
}
