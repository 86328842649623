import React, { Component } from 'react';
import { getLanguage } from '../utils/utils';

export default class Rating extends Component {
  render() {
    return <div className="page__content">
      <article className="article">
        {getLanguage() === 'hr' &&
          <>
            <h1>Način bodovanja</h1>
            <p>Ukupno se procjenjuje 7 područja i 30 tema.</p>
            <p>Unutar svakog područja obuhvaćene su dvije, tri ili pet tema koje se boduju na skali od 0 do 3. </p>
            <p>Kako bi ponijela Certifikat, organizacija treba ostvariti barem <strong>75% ukupno mogućih bodova</strong>, te najmanje <strong>ocjenu 2 </strong>na<strong> 10 ključnih tema</strong>:</p>
            <table>
            <tbody><tr>
              <td valign="top"><ul><li>Selekcija</li><li>Razvoj kompetencija</li><li>Leadership</li><li>Radna uspješnost</li><li>Uključenost i zadovoljstvo</li></ul></td>
              <td valign="top"><ul><li>Karijera i   nasljeđivanje</li><li>Nagrađivanje</li><li>Dobrobit</li><li>HR kapacitet</li><li>Strateško savjetovanje</li></ul></td>
            </tr>
            </tbody></table>
            <p>Ocjenu na svakom području dodjeljuje <strong>certifikacijski tim od 3 člana</strong> na temelju:</p>
            <ul><li>odabranih odgovora na postavljena pitanja</li><li>priložene dokumentacije</li><li>opisnih odgovora</li><li>informacija koje se pružaju na sastanku</li></ul>
            <p>Određena pitanja ne ulaze u sustav bodovanja. Uz takva pitanja nalazi se napomena: <strong>Ovo pitanje se ne boduje. </strong></p>
          </>
        }

        {getLanguage() === 'en' &&
          <>
            <h1>Scoring</h1>
            <p>A total of 30 HR topics are assessed, divided in 7 main areas.</p>
            <p>In every main area 2, 3 or 5 topics are assessed, with a scoring from 0 to 3.</p>
            <p>In order to be awarded the Certificate Employer Partner, the organization must achieve at least <strong>75% of the total score</strong> and at least a <strong>score 2 </strong>in<strong> 10 key topics</strong>:</p>
            <table>
            <tbody><tr>
              <td valign="top"><ul><li>Selection</li><li>Building competencies</li><li>Leadership</li><li>Performance</li><li>Engagement &amp; Satisfaction</li></ul></td>
              <td valign="top"><ul><li>Career &amp; Succession</li><li>Compensation</li><li>Well-being</li><li>HR capacity</li><li>Strategic advisory</li></ul></td>
            </tr>
            </tbody></table>
            <p>The team of 3 certificators scores every topic based on:</p>
            <ul><li>Your answered questions in the questionnaire</li><li>Documents that you attached</li><li>open questions where you described the details about the topic</li><li>information obtained during the meeting with certificators</li></ul>
            <p>Some questions don’t count for the scoring. These questions are marked by this notification: <strong>This question is not scored.</strong></p>
          </>
        }

        {getLanguage() === 'sr' &&
          <>
            <h1>Način bodovanja</h1>
            <p>Ukupno se procenjuje 7 područja i 30 tema.</p>
            <p>Unutar svakog područja obuhvaćene su dve, tri ili pet tema koje se boduju na skali od 0 do 3. </p>
            <p>Kako bi ponela sertifikat, organizacija treba da ostvari barem <strong>75% ukupno mogućih bodova</strong>, te najmanje <strong>ocenu 2 </strong>na<strong> 10 ključnih tema</strong>:</p>
            <table>
            <tbody><tr>
              <td valign="top"><ul><li>Selekcija</li><li>Razvoj kompetencija</li><li>Leadership</li><li>Radna uspešnost</li><li>Uključenost i zadovoljstvo</li></ul></td>
              <td valign="top"><ul><li>Karijera i nasleđivanje</li><li>Nagrađivanje</li><li>Dobrobit</li><li>HR kapacitet</li><li>Strateško savetovanje</li></ul></td>
            </tr>
            </tbody></table>
            <p>Ocenu na svakom području dodeljuje <strong>sertifikacioni tim od 3 člana</strong> na temelju:</p>
            <ul><li>odabranih odgovora na postavljena pitanja</li><li>priložene dokumentacije</li><li>opisnih odgovora</li><li>informacija koje se pružaju na sastanku</li></ul>
            <p>Određena pitanja ne ulaze u sistem bodovanja. Uz takva pitanja nalazi se napomena: <strong>Ovo pitanje se ne boduje. </strong></p>
          </>
        }
      </article>
    </div>
  }
}