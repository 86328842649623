import classNames from "classnames";
import React, { Component } from "react";
import { FileUpload } from "./";
import { getLanguage } from "../utils/utils";

const translations = {
  hr: {
    saving: "Spremam dokument...",
    pleaseWait: "Molimo pričekajte...",
    attachDocument: "Priloži dokument",
  },
  en: {
    saving: "Saving document...",
    pleaseWait: "Please wait...",
    attachDocument: "Attach document",
  },
  sr: {
    saving: "Spremam dokument...",
    pleaseWait: "Molimo pričekajte...",
    attachDocument: "Priloži dokument",
  },
};

export default class Documents extends Component {
  constructor(props) {
    super(props);

    this.state = {
      items: this.props.items,
      previousItems: this.props.previousItems,
      messages: translations[getLanguage()],
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.items !== prevProps.items) {
      this.setState({
        items: this.props.items,
      });
    }
  }

  _onUpload = async (event) => {
    this.setState({
      uploading: true,
    });

    const response = await this.props.onUpload(event);
    if (!response || response.error) {
      this.setState({
        uploading: false,
      });

      return;
    }

    const items = this.state.items ? this.state.items : [];
    items.push(response);

    this.setState({
      items: items,
      uploading: false,
    });
  };

  _onDelete = (event) => {
    if (!window.confirm("Jeste li sigurni da želite obrisati ovaj dokument?")) {
      return;
    }

    const documentId = parseInt(event.target.dataset.id, 10);
    let items = this.state.items;

    for (const i in items) {
      if (items[i].id === documentId) {
        delete items[i];
      }
    }

    this.setState({
      items: items,
    });

    this.props.onDelete(documentId);
  };

  _getFileType = (type) => {
    switch (type) {
      case "application/pdf":
        return "pdf";
      case "image/png":
        return "png";
      case "image/jpeg":
        return "jpg";
      case "application/msword":
      case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
        return "doc";
      case "application/vnd.ms-excel":
      case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
        return "xls";
      case "application/vnd.ms-powerpoint":
      case "application/vnd.openxmlformats-officedocument.presentationml.presentation":
      case "application/vnd.openxmlformats-officedocument.presentationml.slideshow":
        return "ppt";
      default:
        return "default";
    }
  };

  _documentExistsInList = (document, list) => {
    if (list) {
      for (const item of list) {
        if (item && document.filename === item.filename) {
          return true;
        }
      }
    }

    return false;
  };

  render() {
    const { disabled, entityId, entityType, inputName, copied } = this.props;

    const { items, previousItems, messages, uploading } = this.state;

    return (
      <div>
        {(items || uploading) && (
          <div className="documents">
            {items && (
              <>
                {items.map((file, key) => (
                  <span
                    key={key}
                    className={classNames(
                      "file",
                      {
                        "file--new":
                          !this._documentExistsInList(file, previousItems) &&
                          copied,
                      },
                      `file--${this._getFileType(file.filetype)}`
                    )}
                  >
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href={file.url}
                    >
                      {file.title}
                    </a>
                    <span
                      className="file__delete"
                      data-id={file.id}
                      onClick={this._onDelete}
                    ></span>
                  </span>
                ))}
              </>
            )}
            {previousItems && (
              <>
                {previousItems.map((file, key) => (
                  <>
                    {!this._documentExistsInList(file, items) && (
                      <span
                        key={key}
                        className={classNames(
                          "file",
                          "file--disabled",
                          `file--${this._getFileType(file.filetype)}`
                        )}
                      >
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          href={file.url}
                        >
                          {file.title}
                        </a>
                      </span>
                    )}
                  </>
                ))}
              </>
            )}
            {uploading && (
              <span className={`file file--default`}>{messages.saving}</span>
            )}
          </div>
        )}
        {!disabled && (
          <div className="answer__content__doc">
            <FileUpload
              module="documents"
              name={inputName}
              entityType={entityType}
              entityId={entityId}
              onChange={this._onUpload}
              cta={
                <span className="button button--small">
                  {messages.attachDocument}
                </span>
              }
              uploading={
                <span className="button button--small button--disabled">
                  {messages.pleaseWait}
                </span>
              }
            />
          </div>
        )}
      </div>
    );
  }
}
