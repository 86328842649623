import classNames from "classnames";
import React, { Component } from "react";
import { getLanguage } from "../utils/utils";

const translations = {
  hr: {
    previousComment: "Prošli komentar",
  },
  en: {
    previousComment: "Previous Comment",
  },
  sr: {
    previousComment: "Prošli komentar",
  },
};

export default class CommentInput extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentValue: this.props.value,
      messages: translations[getLanguage()],
    };
  }

  _inputOnChange = (event) => {
    this.setState({
      currentValue: event.target.value,
    });

    this.props.onChange(event);
  };

  render() {
    const { name, area, disabled, question, value, copied, previousValue } =
      this.props;

    const { currentValue, messages } = this.state;

    return (
      <div className="question__comments">
        {previousValue !== undefined &&
          previousValue !== "" &&
          previousValue !== currentValue && (
            <div className="comment--preview">
              <h4>{messages.previousComment}</h4>
              {previousValue}
            </div>
          )}
        <textarea
          className={classNames("input", "input-area", {
            "input--new":
              copied &&
              value &&
              (previousValue === undefined ||
                previousValue === "" ||
                previousValue !== currentValue),
          })}
          name={name}
          data-area={area}
          data-question={question ? question.id : null}
          value={value ? value : ""}
          onChange={this._inputOnChange}
          disabled={disabled}
        />
      </div>
    );
  }
}
