import React, { Component } from "react";
import classNames from "classnames";
import moment from "moment";
import API from "../api";
import { getLanguage } from "../utils/utils";
import CommentEdit from "./CommentEdit";

const translations = {
  hr: {
    edit: "Uredi",
    save: "Save",
    enterYourComments: "Upišite svoj komentar",
    addComment: "Dodaj komentar",
    yourComment: "Vaš komentar",
  },
  en: {
    edit: "Edit",
    save: "Save",
    enterYourComments: "Add your comments",
    addComment: "Add comment",
    yourComment: "Your comment",
  },
  sr: {
    edit: "Uredi",
    save: "Spremi",
    enterYourComments: "Upišite svoj komentar",
    addComment: "Dodaj komentar",
    yourComment: "Vaš komentar",
  },
};

export default class CommentList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      action: props.action,
      questionnaireId: props.questionnaireId,
      entityId: props.entityId,
      comment: props.comment,
      comments: props.comments ? props.comments : [],
      editing: false,
      messages: translations[getLanguage()],
      editingCommentId: null,
      loadingComments: false,
    };
  }

  _onAdd = () => {
    this.setState({
      editing: true,
    });
  };

  _onEdit = () => {
    this.setState({
      editing: true,
    });
  };

  _onEditComment = (event) => {
    this.setState({
      editingCommentId: parseInt(event.target.dataset.id, 10),
    });
  };

  _onCancelComment = (event) => {
    this.setState({
      editingCommentId: null,
    });
  };

  _onSaveComment = async (id, content) => {
    this.setState({
      editing: false,
      editingCommentId: null,
    });

    if (this.state.action) {
      await API.post("", {
        action: "certifierAreaQuestionCommentSet",
        id: this.state.questionnaireId,
        commentId: id,
        questionId: this.state.entityId,
        content: content,
      })
        .then((response) => {
          this._loadQuestionComments(
            this.state.questionnaireId,
            this.state.entityId
          );
        })
        .catch((error) => {
          alert(this.state.messages.errorAnswerNotSaved);
        });
    } else {
      await this.props.onSave(this.state.entityId, this.state.comment);
    }
  };

  _loadQuestionComments = async (questionnaireId, questionId) => {
    this.setState({
      loadingComments: true,
    });

    await API.post("", {
      action: "certifierAreaQuestionCommentList",
      questionnaireId: this.state.questionnaireId,
      questionId: this.state.entityId,
    })
      .then((response) => {
        this.setState({
          loadingComments: false,
          comments: response.data,
        });
      })
      .catch((error) => {});
  };

  _onSave = async (entityId, content) => {
    this.setState({
      editing: false,
    });

    if (this.state.action) {
      await API.post("", {
        action: "certifierAreaQuestionCommentSet",
        id: this.state.questionnaireId,
        questionId: this.state.entityId,
        content: content,
      })
        .then((response) => {
          this._loadQuestionComments(
            this.state.questionnaireId,
            this.state.entityId
          );
        })
        .catch((error) => {
          alert(this.state.messages.errorAnswerNotSaved);
        });
    } else {
      this.setState({
        comment: content,
      });
      await this.props.onSave(entityId, content);
    }
  };

  _onCancel = async (entityId, content) => {
    this.setState({
      editing: false,
    });
  };

  render() {
    const { disabled } = this.props;

    const {
      comment,
      comments,
      editing,
      entityId,
      messages,
      questionnaireId,
      loadingComments,
      editingCommentId,
    } = this.state;

    return (
      <div
        className={classNames("rate", "rate--question", {
          "rate--comment": comment || comments.length > 0,
          "rate--loading": loadingComments,
        })}
      >
        <div className="rate__main">
          {comments.length > 0 && (
            <div className="rate__commentlist">
              {comments.map((comment, key) => (
                <div
                  key={key}
                  className={classNames("rate__commentlist_item", {
                    "rate__commentlist_item--noncurrent":
                      questionnaireId !== comment.questionnaireId,
                  })}
                >
                  <div className="rate__commentlist_author">
                    <strong>
                      {comment.firstName} {comment.lastName}
                    </strong>{" "}
                    • {moment(comment.createdAt).format("ll")}
                  </div>
                  <div className="rate__commentlist_content">
                    {comment.id === editingCommentId ? (
                      <CommentEdit
                        entityId={comment.id}
                        comment={comment.content}
                        onSave={this._onSaveComment}
                        onCancel={this._onCancelComment}
                      />
                    ) : (
                      <>{comment.content}</>
                    )}
                  </div>
                  {comment.editable === 1 && (
                    <div className="answer__content__edit">
                      <span data-id={comment.id} onClick={this._onEditComment}>
                        {messages.edit}
                      </span>
                    </div>
                  )}
                </div>
              ))}
            </div>
          )}
          <div className="rate__comment">
            {!disabled && (
              <>
                {!comment && !editing && (
                  <span
                    className="rate__comment_add"
                    data-id={entityId}
                    onClick={this._onAdd}
                  >
                    {messages.addComment}
                  </span>
                )}
                {editing && (
                  <CommentEdit
                    entityId={entityId}
                    comment={comment}
                    onSave={this._onSave}
                    onCancel={this._onCancel}
                  />
                )}
              </>
            )}
            {comment && !editing && (
              <div className="answer__content answer__content--new">
                <h4 className="answer__content__title">
                  {messages.yourComment}:
                </h4>
                <div
                  className="answer__content__text text--preview"
                  dangerouslySetInnerHTML={{ __html: comment }}
                />
                <div className="answer__content__edit">
                  <span data-id={entityId} onClick={this._onEdit}>
                    {messages.edit}
                  </span>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}
