import React, { Component } from "react";
import { getLanguage } from "../utils/utils";

export default class CertifierInstructions extends Component {
  render() {
    return (
      <div className="page__content">
        <article className="article">
          {getLanguage() === "hr" && (
            <>
              <h1>Upute za certifikatore</h1>
              <h2>Priprema za sastanak</h2>
              <ul>
                <li>
                  <strong>Istražiti organizaciju</strong> na webu i društvenim
                  mrežama (aktualnosti, najnoviji projekti, employer branding
                  aktivnosti)
                </li>
                <li>
                  Obavezno pročitati <strong>Napomene za certifikatore</strong>
                  (crveni uskličnik) prilikom pripreme za sastanak – navedeno je
                  koja pitanja i ocjene su povezane (npr. ocjene na radnoj
                  uspješnosti s ocjenom na sustavu napredovanja)
                </li>
                <li>
                  Proučiti <strong>cijeli Upitnik</strong>, poglavlje{" "}
                  <strong>Podaci o organizaciji</strong> i sve dokumente
                </li>
                <li>
                  Obratiti posebnu pažnju na{" "}
                  <strong>nelogičnosti i nedosljednosti</strong> u odgovorima te
                  na pitanja na kojima nedostaju odgovori
                </li>
                <li>
                  Posebno proučiti sva pitanja na kojima{" "}
                  <u>nije postignuta maksimalna ocjena</u> (klijenti se češće
                  podcjenjuju nego precjenjuju)
                </li>
                <li>
                  Preporuka je ocjenjivati procese tijekom pripreme jer je tako
                  lakše uočiti informacije koje nedostaju
                </li>
                <li>
                  Obratiti pažnju na pitanja na kojima klijent nije priložio
                  dokument{" "}
                </li>
                <li>
                  <strong>Pripremiti pitanja za klijenta</strong> nekoliko dana
                  prije sastanka, kako bi voditelj tima imao vremena pročitati
                  pitanja i eventualno dati <em>feedback</em> (i na sadržaj i na
                  prioritet)
                </li>
                <li>
                  Svoja{" "}
                  <strong>
                    pitanja prioritizirati prema kategorijama važnosti{" "}
                  </strong>
                  (1, 2 i 3 ) te ih postavljati ovisno o količini dostupnog
                  vremena
                </li>
                <li>
                  Članovi certifikacijskog tima mogu vidjeti sva pitanja, dok ih
                  klijenti ne mogu vidjeti
                </li>
              </ul>
              <h2>Vođenje sastanka</h2>
              <ul>
                <li>
                  Pripaziti na<u> time management</u> (
                  <strong>cca 25 min po području</strong>)
                </li>
                <li>
                  <u>Voditelj certifikacijskog tima</u> započinje sastanak s:
                  <ul>
                    <li>Uvodnim pitanjima</li>
                    <li>poglavljem Podaci o organizaciji</li>
                  </ul>
                </li>
                <li>
                  Preporuka je da se nakon toga krene s područjem HR strateško
                  savjetovanje zbog dobivanja šire slike na početku sastanka
                </li>
                <li>
                  Pozorno <strong>slušati uvodni dio</strong> jer se često
                  dobiju odgovori na pitanja na kasnijim područjima (npr.
                  klijent spomene izazove s kojima se suočavaju, nove HR
                  projekte, planove za budućnost)
                </li>
                <li>
                  Za vrijeme sastanka, <strong>odgovore</strong> na uvodna
                  pitanja piše voditelj certifikacijskog tima, a bilješke za
                  svaku temu piše onaj član tima kojemu je dodijeljena (bilo
                  koji certifikator može slobodno upisati bilješke/pitanja na
                  bilo koju temu prije i nakon sastanka, primjerice ako uvidi
                  određenu nedosljednost ili želi skrenuti pozornost
                  certifikatora na neko pitanje)
                </li>
                <li>
                  Nakon sastanka (idealno u fazi ocjenjivanja),{" "}
                  <strong>urediti svoje bilješke</strong> da budu jasne, pismene
                  i korisne za idući certifikacijski tim
                </li>
              </ul>
              <h2>Ocjenjivanje</h2>
              <ul>
                <li>
                  Obavezno pročitati <strong>Napomene za certifikatore</strong>
                  su navedeni:
                  <ul>
                    <li>
                      <em>deal breakeri</em> za davanje maksimalne ocjene na
                      pojedinim temama{" "}
                    </li>
                    <li>
                      <em>deal breakeri</em> za davanje ocjene 2 na ključnim
                      temama{" "}
                    </li>
                    <li>
                      koja pitanja i ocjene su povezane (npr. ocjene na radnoj
                      uspješnosti s ocjenom na sustavu napredovanja)
                    </li>
                  </ul>
                </li>
                <li>
                  Kako <strong>procjenjujemo kvalitetu procesa</strong>:
                  <ul>
                    <li>
                      <em>Kako je proces dizajniran?</em>
                    </li>
                    <li>
                      <em>Koje sve elemente obuhvaća?</em>
                    </li>
                    <li>
                      <em>
                        Je li podržan procedurama, brošurama i edukacijama?
                      </em>
                    </li>
                    <li>
                      <em>Na koji način je komuniciran zaposlenicima?</em>
                    </li>
                    <li>
                      <em>U kolikoj je mjeri proces implementiran?</em>
                    </li>
                    <li>
                      <em>Je li proces digitaliziran?</em>
                    </li>
                    <li>
                      <em>Kako se provođenje procesa prati?</em>
                    </li>
                    <li>
                      <em>Kako se proces revidira?</em>
                    </li>
                    <li>
                      <em>Radi li proces ono što mu je svrha?</em>
                    </li>
                    <li>
                      <em>
                        Koliko takav proces ima smisla za tu organizaciju?
                      </em>
                    </li>
                  </ul>
                </li>
                <li>
                  Kod ocjenjivanja <u>uzeti u obzir</u>:
                  <ul>
                    <li>prosječnu ocjenu na svim pitanjima</li>
                    <li>prosječnu ocjenu na ključnim pitanjima</li>
                    <li>priložene dokumente</li>
                    <li>informacije prikupljene na sastanku</li>
                    <li>
                      kontekst organizacije (industrija, veličina, vlasništvo,
                      dislociranost i slično)
                    </li>
                  </ul>
                </li>
                <li>
                  Prijedlog <u>ocjene sustava</u> računa se na temelju ocjena na
                  svim pitanjima (ne samo ključnim), ocjena sustava služi
                  isključivo za <strong>informativne svrhe</strong>
                  <ul>
                    <li>
                      Rasponi po kojima sustav predlaže ocjene:
                      <ul>
                        <li>0.0 - 0.2 = 0</li>
                        <li>0.3 - 0.7 = 0.5</li>
                        <li>0.8 - 1.2 = 1</li>
                        <li>1.3 - 1.7 = 1.5</li>
                        <li>1.8 - 2.2 = 2</li>
                        <li>2.3 - 2.7 = 2.5</li>
                        <li>2.8 - 3.0 = 3</li>
                      </ul>
                    </li>
                  </ul>
                </li>
                <li>
                  Vrste pitanja:
                  <ul>
                    <li>
                      <strong>Ključna pitanja</strong> – označena crvenom
                      zvjezdicom
                    </li>
                    <li>
                      <strong>Poželjna pitanja</strong> (pitanja koja nisu
                      ključna, nemaju oznaku):
                      <ul>
                        <li>
                          odnose se na zaista izvrsne prakse koje ne očekujemo
                          za maksimalnu ocjenu
                        </li>
                        <li>
                          treba ih uzimati u obzir ovisno o kontekstu,
                          industriji ili veličini organizacije
                        </li>
                        <li>
                          češće služe <u>za "povisivanje" ocjene</u>, nego što
                          bi se na temelju tih pitanja ocjena trebala "sniziti"
                        </li>
                      </ul>
                    </li>
                    <li>
                      Pitanja sa<strong> zlatnom ocjenom</strong>
                      <ul>
                        <li>
                          Indiciraju da neki od ponuđenih odgovora predstavljaju
                          Above and Beyond prakse
                        </li>
                        <li>
                          Ako je klijent dao Above and Beyond odgovor, sustav će
                          ponuditi ocjenu 3+, no u ukupnom zbroju za Employer
                          Partner ta će se ocjena gledati kao 3
                        </li>
                      </ul>
                    </li>
                    <li>
                      Pitanja sa zlatnom ocjenom i{" "}
                      <strong>zlatnim okvirom</strong>
                      <ul>
                        <li>
                          Indiciraju pitanja koja se pojavljuju samo klijentima
                          koji su odabrali sudjelovati u Above and Beyond
                          certifikatu
                        </li>
                        <li>
                          Ta pitanja se ne uračunavaju u ukupni zbroj za
                          Employer Partner, već samo za Above and Beyond
                        </li>
                      </ul>
                    </li>
                  </ul>
                </li>
                <li>
                  Odabir ocjene:
                  <ul>
                    <li>Ocjena za pojedinu temu odabire se na kraju te teme</li>
                    <li>
                      „<strong>Plavu</strong>“ ocjenu daje svaki certifikator
                      zasebno, a nakon što dodijeli sve ocjene, potrebno je
                      odabrati <u>„Zaključi ocjene“</u> i donjem lijevom kutu
                      platforme (nije moguće vidjeti ocjene ostalih članova tima
                      dok svi članovi tima nisu zaključili ocjene)
                    </li>
                    <li>
                      Nakon dodjeljivanja ocjene na pojedine teme, bilo bi
                      idealno napisati{" "}
                      <strong>svoj osvrt na odabranu ocjenu</strong>
                    </li>
                    <li>
                      „<strong>Zelenu</strong>“ ocjenu može odabrati samo
                      voditelj tima prilikom usklađivanja ocjena (bit će
                      automatski odabrana u slučaju da su svi članovi tima dali
                      istu ocjenu)
                    </li>
                  </ul>
                </li>
              </ul>
              <h2>Izvještaj</h2>
              <ul>
                <li>
                  Izvještaj je trenutno moguće napraviti na <u>3 jezika</u> –
                  hrvatskom, engleskom i srpskom
                  <ul>
                    <li>
                      Izmjene napravljene na jednom jeziku neće se spremiti na
                      ostalim jezicima
                    </li>
                  </ul>
                </li>
              </ul>
              <ul>
                <li>
                  <u>Voditelj certifikacijskog tim ispunjava</u> slajdove:
                </li>
                <ul>
                  <li>s brojčanim pokazateljima</li>
                  <li>s potrebnim edukacijama</li>
                  <li>daljnjim koracima</li>
                  <li>
                    <em>Timeline</em> (obavezno{" "}
                    <u>konzultirati KAM-a tog klijenta)</u>
                  </li>
                </ul>
                <li>
                  Kod slanja izvještaja klijentu, voditelj tima treba u{" "}
                  <u>Cc</u> staviti i KAM-a tog klijenta, Mirtu Alfirev (PR) i
                  voditeljicu projekta Laru Šubić Šušu{" "}
                </li>
              </ul>
              <h2>Pisanje pohvala i sugestija</h2>
              <ul>
                <li>
                  <u>Primjeri pohvala i sugestija</u> koji se pojavljuju sa
                  strane uz <em>slideove</em> služe za inspiraciju i ne bi se
                  trebali direktno ubacivati u izvještaj, osim iznimno, ako se
                  baš dogodi da u potpunosti odgovaraju stanju kod klijenta
                  (maksimalno jednom po <em>slideu</em>)
                  <ul>
                    <li>
                      cijelo vrijeme tijekom pisanja sugestija potrebno je
                      gledati klijentov ispunjen upitnik i priloženu
                      dokumentaciju, ne samo izvještaj
                    </li>
                    <li>
                      primjere je potrebno obogatiti{" "}
                      <strong>konkretnim praksama iz upitnika</strong>
                    </li>
                    <li>
                      najčešće je više primjera potrebno spojiti u jednu
                      natuknicu
                    </li>
                    <li>
                      često će biti potrebno oformiti skroz novu natuknicu u
                      odnosu na primjere koji su se pojavili
                    </li>
                  </ul>
                </li>
                <li>
                  <em>Slideovi</em> trebaju biti, po mogućnosti, skroz{" "}
                  <u>ispunjeni</u> (no paziti da tekst ne prijeđe ispod granica{" "}
                  <em>slidea</em>)
                </li>
                <li>
                  Iako je predviđen jedan <em>slide</em> po području, unutar
                  njega pišemo{" "}
                  <strong>pohvale i sugestije za svaku temu</strong>
                </li>
                <li>
                  Ako je moguće, pišemo pohvale i sugestije i za{" "}
                  <strong>različite podteme</strong>:
                  <ul>
                    <li>
                      npr. napišite pohvalu i sugestiju i za poslovnu svrhu,
                      misiju i vrijednosti i za mjerenje organizacijske kulture
                      (teme koje sadrže različite podteme su: Poslovna svrha i
                      organizacijska kultura, Komunikacija i feedback kultura,
                      Karijera i nasljeđivanje, Benefiti i prepoznavanje,
                      Fleksibilan rad i balans rada i života, Restrukturiranje i
                      umirovljenje )
                    </li>
                  </ul>
                </li>
                <li>
                  I kod pohvala i kod sugestija, ako je moguće, uvijek krećemo{" "}
                  <strong>od strateških procesa prema operativnim</strong> i
                  administrativnim (krećemo od najbitnijih procesa u nekoj temi)
                </li>
                <li>
                  Ako se natuknice trebaju spojiti, onda se spajaju one
                  natuknice koje spadaju pod istu temu
                </li>
              </ul>
              <p>
                <u>Pohvale</u>
              </p>
              <ul>
                <li>
                  S obzirom da izvještaj sadrži nekoliko{" "}
                  <em>
                    <u>slideova</u>
                  </em>
                  <u>s brojčanim pokazateljima</u>, u dijelu s pohvalama ne
                  navodimo konkretne brojčane pokazatelje, ali ih možemo
                  kvalitativno pohvaliti za one brojčane pokazatelje na kojima
                  su postigli impresivne rezultate{" "}
                </li>
                <li>
                  Pohvale <u>ne bi trebale biti generičke</u>, već se trebamo
                  osvrtati na njihove projekte i inicijative koje su
                  implementirali
                </li>
                <li>
                  Ako je moguće, napisati pohvalu za one teme/rezultate na koje
                  je HR tim posebno ponosan, a što su naveli u uvodnom djelu
                  sastanka
                </li>
                <li>
                  <strong>Fokus je prvo na strateškim procesima</strong>, a tek
                  onda na operativnim (ono što je najbitnije u nekoj temi)
                  <ul>
                    <li>
                      ako klijent na određenoj temi ima lošu ocjenu na
                      najbitnijem procesu, onda ga pohvalimo za drugi najbitniji
                      proces{" "}
                    </li>
                    <li>
                      ako klijent ima ocjene 2 na svim važnim pitanjima, onda ih
                      možemo pohvaliti i za neki podproces koji su označili da
                      imaju unutar ocjene 2 ili npr. možemo napisati da imaju
                      većinu elemenata u nekom procesu{" "}
                    </li>
                    <li>
                      ako klijent ima ocjene 3 na gotovo svim pitanjima unutar
                      teme, onda možemo napraviti sažetak cijele teme, i
                      napisati u pohvali npr. da upravljaju iskustvom kandidata
                      u skladu s najboljim praksama
                    </li>
                  </ul>
                </li>
              </ul>
              <p>
                <u>Sugestije</u>
              </p>
              <ul>
                <li>
                  Osim na <u>strateške procese</u>, fokusiramo se i na one
                  procese koji će im pomoći da im se eventualno{" "}
                  <strong>podigne ocjena sljedeće godine</strong> i koji će
                  imati{" "}
                  <strong>
                    najveći <em>impact</em>
                  </strong>{" "}
                  na njihovu organizaciju
                </li>
                <li>
                  Ne bismo trebali pisati one sugestije koje{" "}
                  <u>nisu smislene za kontekst</u> njihove organizacije i
                  poslovne strategije{" "}
                </li>
                <li>
                  Ako klijent ima ocjene 3 na svim pitanjima unutar teme, onda
                  im možemo napisati sugestiju za neki manji podproces koji im
                  nedostaje unutar pojedinog pitanja (iako na tom pitanju imaju
                  ocjenu 3)
                </li>
                <li>
                  Ako klijent ima ocjene 1 ili 2 na gotovo svim pitanjima unutar
                  teme, onda možemo napraviti sažetak cijele teme
                </li>
              </ul>
            </>
          )}

          {getLanguage() === "en" && (
            <>
              <h1>Upute za certifikatore</h1>
              <h2>Priprema za sastanak</h2>
              <ul>
                <li>
                  <strong>Istražiti organizaciju</strong> na webu i društvenim
                  mrežama (aktualnosti, najnoviji projekti, employer branding
                  aktivnosti)
                </li>
                <li>
                  Obavezno pročitati <strong>Napomene za certifikatore</strong>
                  (crveni uskličnik) prilikom pripreme za sastanak – navedeno je
                  koja pitanja i ocjene su povezane (npr. ocjene na radnoj
                  uspješnosti s ocjenom na sustavu napredovanja)
                </li>
                <li>
                  Proučiti <strong>cijeli Upitnik</strong>, poglavlje{" "}
                  <strong>Podaci o organizaciji</strong> i sve dokumente
                </li>
                <li>
                  Obratiti posebnu pažnju na{" "}
                  <strong>nelogičnosti i nedosljednosti</strong> u odgovorima te
                  na pitanja na kojima nedostaju odgovori
                </li>
                <li>
                  Posebno proučiti sva pitanja na kojima{" "}
                  <u>nije postignuta maksimalna ocjena</u> (klijenti se češće
                  podcjenjuju nego precjenjuju)
                </li>
                <li>
                  Preporuka je ocjenjivati procese tijekom pripreme jer je tako
                  lakše uočiti informacije koje nedostaju
                </li>
                <li>
                  Obratiti pažnju na pitanja na kojima klijent nije priložio
                  dokument{" "}
                </li>
                <li>
                  <strong>Pripremiti pitanja za klijenta</strong> nekoliko dana
                  prije sastanka, kako bi voditelj tima imao vremena pročitati
                  pitanja i eventualno dati <em>feedback</em> (i na sadržaj i na
                  prioritet)
                </li>
                <li>
                  Svoja{" "}
                  <strong>
                    pitanja prioritizirati prema kategorijama važnosti{" "}
                  </strong>
                  (1, 2 i 3 ) te ih postavljati ovisno o količini dostupnog
                  vremena
                </li>
                <li>
                  Članovi certifikacijskog tima mogu vidjeti sva pitanja, dok ih
                  klijenti ne mogu vidjeti
                </li>
              </ul>
              <h2>Vođenje sastanka</h2>
              <ul>
                <li>
                  Pripaziti na<u> time management</u> (
                  <strong>cca 25 min po području</strong>)
                </li>
                <li>
                  <u>Voditelj certifikacijskog tima</u> započinje sastanak s:
                  <ul>
                    <li>Uvodnim pitanjima</li>
                    <li>poglavljem Podaci o organizaciji</li>
                  </ul>
                </li>
                <li>
                  Preporuka je da se nakon toga krene s područjem HR strateško
                  savjetovanje zbog dobivanja šire slike na početku sastanka
                </li>
                <li>
                  Pozorno <strong>slušati uvodni dio</strong> jer se često
                  dobiju odgovori na pitanja na kasnijim područjima (npr.
                  klijent spomene izazove s kojima se suočavaju, nove HR
                  projekte, planove za budućnost)
                </li>
                <li>
                  Za vrijeme sastanka, <strong>odgovore</strong> na uvodna
                  pitanja piše voditelj certifikacijskog tima, a bilješke za
                  svaku temu piše onaj član tima kojemu je dodijeljena (bilo
                  koji certifikator može slobodno upisati bilješke/pitanja na
                  bilo koju temu prije i nakon sastanka, primjerice ako uvidi
                  određenu nedosljednost ili želi skrenuti pozornost
                  certifikatora na neko pitanje)
                </li>
                <li>
                  Nakon sastanka (idealno u fazi ocjenjivanja),{" "}
                  <strong>urediti svoje bilješke</strong> da budu jasne, pismene
                  i korisne za idući certifikacijski tim
                </li>
              </ul>
              <h2>Ocjenjivanje</h2>
              <ul>
                <li>
                  Obavezno pročitati <strong>Napomene za certifikatore</strong>
                  su navedeni:
                  <ul>
                    <li>
                      <em>deal breakeri</em> za davanje maksimalne ocjene na
                      pojedinim temama{" "}
                    </li>
                    <li>
                      <em>deal breakeri</em> za davanje ocjene 2 na ključnim
                      temama{" "}
                    </li>
                    <li>
                      koja pitanja i ocjene su povezane (npr. ocjene na radnoj
                      uspješnosti s ocjenom na sustavu napredovanja)
                    </li>
                  </ul>
                </li>
                <li>
                  Kako <strong>procjenjujemo kvalitetu procesa</strong>:
                  <ul>
                    <li>
                      <em>Kako je proces dizajniran?</em>
                    </li>
                    <li>
                      <em>Koje sve elemente obuhvaća?</em>
                    </li>
                    <li>
                      <em>
                        Je li podržan procedurama, brošurama i edukacijama?
                      </em>
                    </li>
                    <li>
                      <em>Na koji način je komuniciran zaposlenicima?</em>
                    </li>
                    <li>
                      <em>U kolikoj je mjeri proces implementiran?</em>
                    </li>
                    <li>
                      <em>Je li proces digitaliziran?</em>
                    </li>
                    <li>
                      <em>Kako se provođenje procesa prati?</em>
                    </li>
                    <li>
                      <em>Kako se proces revidira?</em>
                    </li>
                    <li>
                      <em>Radi li proces ono što mu je svrha?</em>
                    </li>
                    <li>
                      <em>
                        Koliko takav proces ima smisla za tu organizaciju?
                      </em>
                    </li>
                  </ul>
                </li>
                <li>
                  Kod ocjenjivanja <u>uzeti u obzir</u>:
                  <ul>
                    <li>prosječnu ocjenu na svim pitanjima</li>
                    <li>prosječnu ocjenu na ključnim pitanjima</li>
                    <li>priložene dokumente</li>
                    <li>informacije prikupljene na sastanku</li>
                    <li>
                      kontekst organizacije (industrija, veličina, vlasništvo,
                      dislociranost i slično)
                    </li>
                  </ul>
                </li>
                <li>
                  Prijedlog <u>ocjene sustava</u> računa se na temelju ocjena na
                  svim pitanjima (ne samo ključnim), ocjena sustava služi
                  isključivo za <strong>informativne svrhe</strong>
                  <ul>
                    <li>
                      Rasponi po kojima sustav predlaže ocjene:
                      <ul>
                        <li>0.0 - 0.2 = 0</li>
                        <li>0.3 - 0.7 = 0.5</li>
                        <li>0.8 - 1.2 = 1</li>
                        <li>1.3 - 1.7 = 1.5</li>
                        <li>1.8 - 2.2 = 2</li>
                        <li>2.3 - 2.7 = 2.5</li>
                        <li>2.8 - 3.0 = 3</li>
                      </ul>
                    </li>
                  </ul>
                </li>
                <li>
                  Vrste pitanja:
                  <ul>
                    <li>
                      <strong>Ključna pitanja</strong> – označena crvenom
                      zvjezdicom
                    </li>
                    <li>
                      <strong>Poželjna pitanja</strong> (pitanja koja nisu
                      ključna, nemaju oznaku):
                      <ul>
                        <li>
                          odnose se na zaista izvrsne prakse koje ne očekujemo
                          za maksimalnu ocjenu
                        </li>
                        <li>
                          treba ih uzimati u obzir ovisno o kontekstu,
                          industriji ili veličini organizacije
                        </li>
                        <li>
                          češće služe <u>za "povisivanje" ocjene</u>, nego što
                          bi se na temelju tih pitanja ocjena trebala "sniziti"
                        </li>
                      </ul>
                    </li>
                    <li>
                      Pitanja sa<strong> zlatnom ocjenom</strong>
                      <ul>
                        <li>
                          Indiciraju da neki od ponuđenih odgovora predstavljaju
                          Above and Beyond prakse
                        </li>
                        <li>
                          Ako je klijent dao Above and Beyond odgovor, sustav će
                          ponuditi ocjenu 3+, no u ukupnom zbroju za Employer
                          Partner ta će se ocjena gledati kao 3
                        </li>
                      </ul>
                    </li>
                    <li>
                      Pitanja sa zlatnom ocjenom i{" "}
                      <strong>zlatnim okvirom</strong>
                      <ul>
                        <li>
                          Indiciraju pitanja koja se pojavljuju samo klijentima
                          koji su odabrali sudjelovati u Above and Beyond
                          certifikatu
                        </li>
                        <li>
                          Ta pitanja se ne uračunavaju u ukupni zbroj za
                          Employer Partner, već samo za Above and Beyond
                        </li>
                      </ul>
                    </li>
                  </ul>
                </li>
                <li>
                  Odabir ocjene:
                  <ul>
                    <li>Ocjena za pojedinu temu odabire se na kraju te teme</li>
                    <li>
                      „<strong>Plavu</strong>“ ocjenu daje svaki certifikator
                      zasebno, a nakon što dodijeli sve ocjene, potrebno je
                      odabrati <u>„Zaključi ocjene“</u> i donjem lijevom kutu
                      platforme (nije moguće vidjeti ocjene ostalih članova tima
                      dok svi članovi tima nisu zaključili ocjene)
                    </li>
                    <li>
                      Nakon dodjeljivanja ocjene na pojedine teme, bilo bi
                      idealno napisati{" "}
                      <strong>svoj osvrt na odabranu ocjenu</strong>
                    </li>
                    <li>
                      „<strong>Zelenu</strong>“ ocjenu može odabrati samo
                      voditelj tima prilikom usklađivanja ocjena (bit će
                      automatski odabrana u slučaju da su svi članovi tima dali
                      istu ocjenu)
                    </li>
                  </ul>
                </li>
              </ul>
              <h2>Izvještaj</h2>
              <ul>
                <li>
                  Izvještaj je trenutno moguće napraviti na <u>3 jezika</u> –
                  hrvatskom, engleskom i srpskom
                  <ul>
                    <li>
                      Izmjene napravljene na jednom jeziku neće se spremiti na
                      ostalim jezicima
                    </li>
                  </ul>
                </li>
              </ul>
              <ul>
                <li>
                  <u>Voditelj certifikacijskog tim ispunjava</u> slajdove:
                </li>
                <ul>
                  <li>s brojčanim pokazateljima</li>
                  <li>s potrebnim edukacijama</li>
                  <li>daljnjim koracima</li>
                  <li>
                    <em>Timeline</em> (obavezno{" "}
                    <u>konzultirati KAM-a tog klijenta)</u>
                  </li>
                </ul>
                <li>
                  Kod slanja izvještaja klijentu, voditelj tima treba u{" "}
                  <u>Cc</u> staviti i KAM-a tog klijenta, Mirtu Alfirev (PR) i
                  voditeljicu projekta Laru Šubić Šušu{" "}
                </li>
              </ul>
              <h2>Pisanje pohvala i sugestija</h2>
              <ul>
                <li>
                  <u>Primjeri pohvala i sugestija</u> koji se pojavljuju sa
                  strane uz <em>slideove</em> služe za inspiraciju i ne bi se
                  trebali direktno ubacivati u izvještaj, osim iznimno, ako se
                  baš dogodi da u potpunosti odgovaraju stanju kod klijenta
                  (maksimalno jednom po <em>slideu</em>)
                  <ul>
                    <li>
                      cijelo vrijeme tijekom pisanja sugestija potrebno je
                      gledati klijentov ispunjen upitnik i priloženu
                      dokumentaciju, ne samo izvještaj
                    </li>
                    <li>
                      primjere je potrebno obogatiti{" "}
                      <strong>konkretnim praksama iz upitnika</strong>
                    </li>
                    <li>
                      najčešće je više primjera potrebno spojiti u jednu
                      natuknicu
                    </li>
                    <li>
                      često će biti potrebno oformiti skroz novu natuknicu u
                      odnosu na primjere koji su se pojavili
                    </li>
                  </ul>
                </li>
                <li>
                  <em>Slideovi</em> trebaju biti, po mogućnosti, skroz{" "}
                  <u>ispunjeni</u> (no paziti da tekst ne prijeđe ispod granica{" "}
                  <em>slidea</em>)
                </li>
                <li>
                  Iako je predviđen jedan <em>slide</em> po području, unutar
                  njega pišemo{" "}
                  <strong>pohvale i sugestije za svaku temu</strong>
                </li>
                <li>
                  Ako je moguće, pišemo pohvale i sugestije i za{" "}
                  <strong>različite podteme</strong>:
                  <ul>
                    <li>
                      npr. napišite pohvalu i sugestiju i za poslovnu svrhu,
                      misiju i vrijednosti i za mjerenje organizacijske kulture
                      (teme koje sadrže različite podteme su: Poslovna svrha i
                      organizacijska kultura, Komunikacija i feedback kultura,
                      Karijera i nasljeđivanje, Benefiti i prepoznavanje,
                      Fleksibilan rad i balans rada i života, Restrukturiranje i
                      umirovljenje )
                    </li>
                  </ul>
                </li>
                <li>
                  I kod pohvala i kod sugestija, ako je moguće, uvijek krećemo{" "}
                  <strong>od strateških procesa prema operativnim</strong> i
                  administrativnim (krećemo od najbitnijih procesa u nekoj temi)
                </li>
                <li>
                  Ako se natuknice trebaju spojiti, onda se spajaju one
                  natuknice koje spadaju pod istu temu
                </li>
              </ul>
              <p>
                <u>Pohvale</u>
              </p>
              <ul>
                <li>
                  S obzirom da izvještaj sadrži nekoliko{" "}
                  <em>
                    <u>slideova</u>
                  </em>
                  <u>s brojčanim pokazateljima</u>, u dijelu s pohvalama ne
                  navodimo konkretne brojčane pokazatelje, ali ih možemo
                  kvalitativno pohvaliti za one brojčane pokazatelje na kojima
                  su postigli impresivne rezultate{" "}
                </li>
                <li>
                  Pohvale <u>ne bi trebale biti generičke</u>, već se trebamo
                  osvrtati na njihove projekte i inicijative koje su
                  implementirali
                </li>
                <li>
                  Ako je moguće, napisati pohvalu za one teme/rezultate na koje
                  je HR tim posebno ponosan, a što su naveli u uvodnom djelu
                  sastanka
                </li>
                <li>
                  <strong>Fokus je prvo na strateškim procesima</strong>, a tek
                  onda na operativnim (ono što je najbitnije u nekoj temi)
                  <ul>
                    <li>
                      ako klijent na određenoj temi ima lošu ocjenu na
                      najbitnijem procesu, onda ga pohvalimo za drugi najbitniji
                      proces{" "}
                    </li>
                    <li>
                      ako klijent ima ocjene 2 na svim važnim pitanjima, onda ih
                      možemo pohvaliti i za neki podproces koji su označili da
                      imaju unutar ocjene 2 ili npr. možemo napisati da imaju
                      većinu elemenata u nekom procesu{" "}
                    </li>
                    <li>
                      ako klijent ima ocjene 3 na gotovo svim pitanjima unutar
                      teme, onda možemo napraviti sažetak cijele teme, i
                      napisati u pohvali npr. da upravljaju iskustvom kandidata
                      u skladu s najboljim praksama
                    </li>
                  </ul>
                </li>
              </ul>
              <p>
                <u>Sugestije</u>
              </p>
              <ul>
                <li>
                  Osim na <u>strateške procese</u>, fokusiramo se i na one
                  procese koji će im pomoći da im se eventualno{" "}
                  <strong>podigne ocjena sljedeće godine</strong> i koji će
                  imati{" "}
                  <strong>
                    najveći <em>impact</em>
                  </strong>{" "}
                  na njihovu organizaciju
                </li>
                <li>
                  Ne bismo trebali pisati one sugestije koje{" "}
                  <u>nisu smislene za kontekst</u> njihove organizacije i
                  poslovne strategije{" "}
                </li>
                <li>
                  Ako klijent ima ocjene 3 na svim pitanjima unutar teme, onda
                  im možemo napisati sugestiju za neki manji podproces koji im
                  nedostaje unutar pojedinog pitanja (iako na tom pitanju imaju
                  ocjenu 3)
                </li>
                <li>
                  Ako klijent ima ocjene 1 ili 2 na gotovo svim pitanjima unutar
                  teme, onda možemo napraviti sažetak cijele teme
                </li>
              </ul>
            </>
          )}

          {getLanguage() === "sr" && (
            <>
              <h1>Upute za certifikatore</h1>
              <h2>Priprema za sastanak</h2>
              <ul>
                <li>
                  <strong>Istražiti organizaciju</strong> na webu i društvenim
                  mrežama (aktualnosti, najnoviji projekti, employer branding
                  aktivnosti)
                </li>
                <li>
                  Obavezno pročitati <strong>Napomene za certifikatore</strong>
                  (crveni uskličnik) prilikom pripreme za sastanak – navedeno je
                  koja pitanja i ocjene su povezane (npr. ocjene na radnoj
                  uspješnosti s ocjenom na sustavu napredovanja)
                </li>
                <li>
                  Proučiti <strong>cijeli Upitnik</strong>, poglavlje{" "}
                  <strong>Podaci o organizaciji</strong> i sve dokumente
                </li>
                <li>
                  Obratiti posebnu pažnju na{" "}
                  <strong>nelogičnosti i nedosljednosti</strong> u odgovorima te
                  na pitanja na kojima nedostaju odgovori
                </li>
                <li>
                  Posebno proučiti sva pitanja na kojima{" "}
                  <u>nije postignuta maksimalna ocjena</u> (klijenti se češće
                  podcjenjuju nego precjenjuju)
                </li>
                <li>
                  Preporuka je ocjenjivati procese tijekom pripreme jer je tako
                  lakše uočiti informacije koje nedostaju
                </li>
                <li>
                  Obratiti pažnju na pitanja na kojima klijent nije priložio
                  dokument{" "}
                </li>
                <li>
                  <strong>Pripremiti pitanja za klijenta</strong> nekoliko dana
                  prije sastanka, kako bi voditelj tima imao vremena pročitati
                  pitanja i eventualno dati <em>feedback</em> (i na sadržaj i na
                  prioritet)
                </li>
                <li>
                  Svoja{" "}
                  <strong>
                    pitanja prioritizirati prema kategorijama važnosti{" "}
                  </strong>
                  (1, 2 i 3 ) te ih postavljati ovisno o količini dostupnog
                  vremena
                </li>
                <li>
                  Članovi certifikacijskog tima mogu vidjeti sva pitanja, dok ih
                  klijenti ne mogu vidjeti
                </li>
              </ul>
              <h2>Vođenje sastanka</h2>
              <ul>
                <li>
                  Pripaziti na<u> time management</u> (
                  <strong>cca 25 min po području</strong>)
                </li>
                <li>
                  <u>Voditelj certifikacijskog tima</u> započinje sastanak s:
                  <ul>
                    <li>Uvodnim pitanjima</li>
                    <li>poglavljem Podaci o organizaciji</li>
                  </ul>
                </li>
                <li>
                  Preporuka je da se nakon toga krene s područjem HR strateško
                  savjetovanje zbog dobivanja šire slike na početku sastanka
                </li>
                <li>
                  Pozorno <strong>slušati uvodni dio</strong> jer se često
                  dobiju odgovori na pitanja na kasnijim područjima (npr.
                  klijent spomene izazove s kojima se suočavaju, nove HR
                  projekte, planove za budućnost)
                </li>
                <li>
                  Za vrijeme sastanka, <strong>odgovore</strong> na uvodna
                  pitanja piše voditelj certifikacijskog tima, a bilješke za
                  svaku temu piše onaj član tima kojemu je dodijeljena (bilo
                  koji certifikator može slobodno upisati bilješke/pitanja na
                  bilo koju temu prije i nakon sastanka, primjerice ako uvidi
                  određenu nedosljednost ili želi skrenuti pozornost
                  certifikatora na neko pitanje)
                </li>
                <li>
                  Nakon sastanka (idealno u fazi ocjenjivanja),{" "}
                  <strong>urediti svoje bilješke</strong> da budu jasne, pismene
                  i korisne za idući certifikacijski tim
                </li>
              </ul>
              <h2>Ocjenjivanje</h2>
              <ul>
                <li>
                  Obavezno pročitati <strong>Napomene za certifikatore</strong>
                  su navedeni:
                  <ul>
                    <li>
                      <em>deal breakeri</em> za davanje maksimalne ocjene na
                      pojedinim temama{" "}
                    </li>
                    <li>
                      <em>deal breakeri</em> za davanje ocjene 2 na ključnim
                      temama{" "}
                    </li>
                    <li>
                      koja pitanja i ocjene su povezane (npr. ocjene na radnoj
                      uspješnosti s ocjenom na sustavu napredovanja)
                    </li>
                  </ul>
                </li>
                <li>
                  Kako <strong>procjenjujemo kvalitetu procesa</strong>:
                  <ul>
                    <li>
                      <em>Kako je proces dizajniran?</em>
                    </li>
                    <li>
                      <em>Koje sve elemente obuhvaća?</em>
                    </li>
                    <li>
                      <em>
                        Je li podržan procedurama, brošurama i edukacijama?
                      </em>
                    </li>
                    <li>
                      <em>Na koji način je komuniciran zaposlenicima?</em>
                    </li>
                    <li>
                      <em>U kolikoj je mjeri proces implementiran?</em>
                    </li>
                    <li>
                      <em>Je li proces digitaliziran?</em>
                    </li>
                    <li>
                      <em>Kako se provođenje procesa prati?</em>
                    </li>
                    <li>
                      <em>Kako se proces revidira?</em>
                    </li>
                    <li>
                      <em>Radi li proces ono što mu je svrha?</em>
                    </li>
                    <li>
                      <em>
                        Koliko takav proces ima smisla za tu organizaciju?
                      </em>
                    </li>
                  </ul>
                </li>
                <li>
                  Kod ocjenjivanja <u>uzeti u obzir</u>:
                  <ul>
                    <li>prosječnu ocjenu na svim pitanjima</li>
                    <li>prosječnu ocjenu na ključnim pitanjima</li>
                    <li>priložene dokumente</li>
                    <li>informacije prikupljene na sastanku</li>
                    <li>
                      kontekst organizacije (industrija, veličina, vlasništvo,
                      dislociranost i slično)
                    </li>
                  </ul>
                </li>
                <li>
                  Prijedlog <u>ocjene sustava</u> računa se na temelju ocjena na
                  svim pitanjima (ne samo ključnim), ocjena sustava služi
                  isključivo za <strong>informativne svrhe</strong>
                  <ul>
                    <li>
                      Rasponi po kojima sustav predlaže ocjene:
                      <ul>
                        <li>0.0 - 0.2 = 0</li>
                        <li>0.3 - 0.7 = 0.5</li>
                        <li>0.8 - 1.2 = 1</li>
                        <li>1.3 - 1.7 = 1.5</li>
                        <li>1.8 - 2.2 = 2</li>
                        <li>2.3 - 2.7 = 2.5</li>
                        <li>2.8 - 3.0 = 3</li>
                      </ul>
                    </li>
                  </ul>
                </li>
                <li>
                  Vrste pitanja:
                  <ul>
                    <li>
                      <strong>Ključna pitanja</strong> – označena crvenom
                      zvjezdicom
                    </li>
                    <li>
                      <strong>Poželjna pitanja</strong> (pitanja koja nisu
                      ključna, nemaju oznaku):
                      <ul>
                        <li>
                          odnose se na zaista izvrsne prakse koje ne očekujemo
                          za maksimalnu ocjenu
                        </li>
                        <li>
                          treba ih uzimati u obzir ovisno o kontekstu,
                          industriji ili veličini organizacije
                        </li>
                        <li>
                          češće služe <u>za "povisivanje" ocjene</u>, nego što
                          bi se na temelju tih pitanja ocjena trebala "sniziti"
                        </li>
                      </ul>
                    </li>
                    <li>
                      Pitanja sa<strong> zlatnom ocjenom</strong>
                      <ul>
                        <li>
                          Indiciraju da neki od ponuđenih odgovora predstavljaju
                          Above and Beyond prakse
                        </li>
                        <li>
                          Ako je klijent dao Above and Beyond odgovor, sustav će
                          ponuditi ocjenu 3+, no u ukupnom zbroju za Employer
                          Partner ta će se ocjena gledati kao 3
                        </li>
                      </ul>
                    </li>
                    <li>
                      Pitanja sa zlatnom ocjenom i{" "}
                      <strong>zlatnim okvirom</strong>
                      <ul>
                        <li>
                          Indiciraju pitanja koja se pojavljuju samo klijentima
                          koji su odabrali sudjelovati u Above and Beyond
                          certifikatu
                        </li>
                        <li>
                          Ta pitanja se ne uračunavaju u ukupni zbroj za
                          Employer Partner, već samo za Above and Beyond
                        </li>
                      </ul>
                    </li>
                  </ul>
                </li>
                <li>
                  Odabir ocjene:
                  <ul>
                    <li>Ocjena za pojedinu temu odabire se na kraju te teme</li>
                    <li>
                      „<strong>Plavu</strong>“ ocjenu daje svaki certifikator
                      zasebno, a nakon što dodijeli sve ocjene, potrebno je
                      odabrati <u>„Zaključi ocjene“</u> i donjem lijevom kutu
                      platforme (nije moguće vidjeti ocjene ostalih članova tima
                      dok svi članovi tima nisu zaključili ocjene)
                    </li>
                    <li>
                      Nakon dodjeljivanja ocjene na pojedine teme, bilo bi
                      idealno napisati{" "}
                      <strong>svoj osvrt na odabranu ocjenu</strong>
                    </li>
                    <li>
                      „<strong>Zelenu</strong>“ ocjenu može odabrati samo
                      voditelj tima prilikom usklađivanja ocjena (bit će
                      automatski odabrana u slučaju da su svi članovi tima dali
                      istu ocjenu)
                    </li>
                  </ul>
                </li>
              </ul>
              <h2>Izvještaj</h2>
              <ul>
                <li>
                  Izvještaj je trenutno moguće napraviti na <u>3 jezika</u> –
                  hrvatskom, engleskom i srpskom
                  <ul>
                    <li>
                      Izmjene napravljene na jednom jeziku neće se spremiti na
                      ostalim jezicima
                    </li>
                  </ul>
                </li>
              </ul>
              <ul>
                <li>
                  <u>Voditelj certifikacijskog tim ispunjava</u> slajdove:
                </li>
                <ul>
                  <li>s brojčanim pokazateljima</li>
                  <li>s potrebnim edukacijama</li>
                  <li>daljnjim koracima</li>
                  <li>
                    <em>Timeline</em> (obavezno{" "}
                    <u>konzultirati KAM-a tog klijenta)</u>
                  </li>
                </ul>
                <li>
                  Kod slanja izvještaja klijentu, voditelj tima treba u{" "}
                  <u>Cc</u> staviti i KAM-a tog klijenta, Mirtu Alfirev (PR) i
                  voditeljicu projekta Laru Šubić Šušu{" "}
                </li>
              </ul>
              <h2>Pisanje pohvala i sugestija</h2>
              <ul>
                <li>
                  <u>Primjeri pohvala i sugestija</u> koji se pojavljuju sa
                  strane uz <em>slideove</em> služe za inspiraciju i ne bi se
                  trebali direktno ubacivati u izvještaj, osim iznimno, ako se
                  baš dogodi da u potpunosti odgovaraju stanju kod klijenta
                  (maksimalno jednom po <em>slideu</em>)
                  <ul>
                    <li>
                      cijelo vrijeme tijekom pisanja sugestija potrebno je
                      gledati klijentov ispunjen upitnik i priloženu
                      dokumentaciju, ne samo izvještaj
                    </li>
                    <li>
                      primjere je potrebno obogatiti{" "}
                      <strong>konkretnim praksama iz upitnika</strong>
                    </li>
                    <li>
                      najčešće je više primjera potrebno spojiti u jednu
                      natuknicu
                    </li>
                    <li>
                      često će biti potrebno oformiti skroz novu natuknicu u
                      odnosu na primjere koji su se pojavili
                    </li>
                  </ul>
                </li>
                <li>
                  <em>Slideovi</em> trebaju biti, po mogućnosti, skroz{" "}
                  <u>ispunjeni</u> (no paziti da tekst ne prijeđe ispod granica{" "}
                  <em>slidea</em>)
                </li>
                <li>
                  Iako je predviđen jedan <em>slide</em> po području, unutar
                  njega pišemo{" "}
                  <strong>pohvale i sugestije za svaku temu</strong>
                </li>
                <li>
                  Ako je moguće, pišemo pohvale i sugestije i za{" "}
                  <strong>različite podteme</strong>:
                  <ul>
                    <li>
                      npr. napišite pohvalu i sugestiju i za poslovnu svrhu,
                      misiju i vrijednosti i za mjerenje organizacijske kulture
                      (teme koje sadrže različite podteme su: Poslovna svrha i
                      organizacijska kultura, Komunikacija i feedback kultura,
                      Karijera i nasljeđivanje, Benefiti i prepoznavanje,
                      Fleksibilan rad i balans rada i života, Restrukturiranje i
                      umirovljenje )
                    </li>
                  </ul>
                </li>
                <li>
                  I kod pohvala i kod sugestija, ako je moguće, uvijek krećemo{" "}
                  <strong>od strateških procesa prema operativnim</strong> i
                  administrativnim (krećemo od najbitnijih procesa u nekoj temi)
                </li>
                <li>
                  Ako se natuknice trebaju spojiti, onda se spajaju one
                  natuknice koje spadaju pod istu temu
                </li>
              </ul>
              <p>
                <u>Pohvale</u>
              </p>
              <ul>
                <li>
                  S obzirom da izvještaj sadrži nekoliko{" "}
                  <em>
                    <u>slideova</u>
                  </em>{" "}
                  <u>s brojčanim pokazateljima</u>, u dijelu s pohvalama ne
                  navodimo konkretne brojčane pokazatelje, ali ih možemo
                  kvalitativno pohvaliti za one brojčane pokazatelje na kojima
                  su postigli impresivne rezultate{" "}
                </li>
                <li>
                  Pohvale <u>ne bi trebale biti generičke</u>, već se trebamo
                  osvrtati na njihove projekte i inicijative koje su
                  implementirali
                </li>
                <li>
                  Ako je moguće, napisati pohvalu za one teme/rezultate na koje
                  je HR tim posebno ponosan, a što su naveli u uvodnom djelu
                  sastanka
                </li>
                <li>
                  <strong>Fokus je prvo na strateškim procesima</strong>, a tek
                  onda na operativnim (ono što je najbitnije u nekoj temi)
                  <ul>
                    <li>
                      ako klijent na određenoj temi ima lošu ocjenu na
                      najbitnijem procesu, onda ga pohvalimo za drugi najbitniji
                      proces{" "}
                    </li>
                    <li>
                      ako klijent ima ocjene 2 na svim važnim pitanjima, onda ih
                      možemo pohvaliti i za neki podproces koji su označili da
                      imaju unutar ocjene 2 ili npr. možemo napisati da imaju
                      većinu elemenata u nekom procesu{" "}
                    </li>
                    <li>
                      ako klijent ima ocjene 3 na gotovo svim pitanjima unutar
                      teme, onda možemo napraviti sažetak cijele teme, i
                      napisati u pohvali npr. da upravljaju iskustvom kandidata
                      u skladu s najboljim praksama
                    </li>
                  </ul>
                </li>
              </ul>
              <p>
                <u>Sugestije</u>
              </p>
              <ul>
                <li>
                  Osim na <u>strateške procese</u>, fokusiramo se i na one
                  procese koji će im pomoći da im se eventualno{" "}
                  <strong>podigne ocjena sljedeće godine</strong> i koji će
                  imati{" "}
                  <strong>
                    najveći <em>impact</em>
                  </strong>{" "}
                  na njihovu organizaciju
                </li>
                <li>
                  Ne bismo trebali pisati one sugestije koje{" "}
                  <u>nisu smislene za kontekst</u> njihove organizacije i
                  poslovne strategije{" "}
                </li>
                <li>
                  Ako klijent ima ocjene 3 na svim pitanjima unutar teme, onda
                  im možemo napisati sugestiju za neki manji podproces koji im
                  nedostaje unutar pojedinog pitanja (iako na tom pitanju imaju
                  ocjenu 3)
                </li>
                <li>
                  Ako klijent ima ocjene 1 ili 2 na gotovo svim pitanjima unutar
                  teme, onda možemo napraviti sažetak cijele teme
                </li>
              </ul>
            </>
          )}
        </article>
      </div>
    );
  }
}
