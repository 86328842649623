import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet";
import API from "../api";
import classNames from "classnames";
import { getLanguage } from "../utils/utils";
import Loader from "./Loader";
import { CompanyContext } from "../context/CompanyContext";
import Toggle from "react-toggle";
import "react-toggle/style.css";

const translations = {
  hr: {
    system: "Sustav",
    certifier: "Certifikator",
    final: "Završno",
    certification: "Certifikacija",
    grades: "Ocjene",
    finalizeGrades: "Zaključi svoje ocjene",
    certificationConfirm: "Jeste li sigurni da želite zaključiti ocjene?",
    aboveAndBeyond: "Above&Beyond",
    aboutCEP: "O certifikatu",
    companySelection: "Odabir upitnika",
    documents: "Dokumenti",
    questions: "Pitanja",
    instructions: "Upute za ispunjavanje upitnika",
    instructionsCertifiers: "Upute za certifikatore",
    organizationInfo: "Podaci o organizaciji",
    scoring: "Bodovanje",
    questionnaire: "Upitnik",
    introQuestions: "Uvodna pitanja",
    report: "Izvještaj",
    viewReport: "Otvori izvještaj",
    options: "Opcije",
    questionnaireOptions: {
      inactive: "Upitnik vidljiv klijentu",
      locked: "Upitnik otključan klijentu",
      gradingLocked: "Certifikatori mogu ocjenjivati",
      reportLocked: "Izvještaj otključan za uređivanje",
    },
  },
  en: {
    system: "System",
    certifier: "Certifier",
    final: "Final",
    certification: "Certification",
    grades: "Grades",
    finalizeGrades: "Finalize your grades",
    certificationConfirm: "Are you sure you want to finalize grades?",
    aboveAndBeyond: "Above&Beyond",
    aboutCEP: "About the certificate",
    companySelection: "Questionnaire selection",
    documents: "Documents",
    questions: "Questions",
    instructions: "Questionnaire instructions",
    instructionsCertifiers: "Certifier instructions",
    organizationInfo: "Organization info",
    scoring: "Scoring",
    questionnaire: "Questionnaire",
    introQuestions: "Intro Questions",
    report: "Report",
    viewReport: "View Report",
    options: "Options",
    questionnaireOptions: {
      inactive: "Questionnaire visible to the client",
      locked: "Questionnaire open for the client",
      gradingLocked: "Certifiers can grade",
      reportLocked: "Report open for updates",
    },
  },
  sr: {
    system: "Sustav",
    certifier: "Certifikator",
    final: "Završno",
    certification: "Sertifikacija",
    grades: "Ocene",
    finalizeGrades: "Zaključi svoje ocene",
    certificationConfirm: "Jeste li sigurni da želite zaključiti ocjene?",
    aboveAndBeyond: "Above&Beyond",
    aboutCEP: "O sertifikatu",
    companySelection: "Odabir upitnika",
    documents: "Dokumenti",
    questions: "Pitanja",
    instructions: "Uputstvo za popunjavanje upitnika",
    instructionsCertifiers: "Upute za certifikatore",
    organizationInfo: "Podaci o organizaciji",
    scoring: "Bodovanje",
    questionnaire: "Upitnik",
    introQuestions: "Uvodna pitanja",
    report: "Izveštaj",
    viewReport: "Otvori izveštaj",
    options: "Opcije",
    questionnaireOptions: {
      inactive: "Upitnik vidljiv klijentu",
      locked: "Upitnik otključan klijentu",
      gradingLocked: "Certifikatori mogu ocjenjivati",
      reportLocked: "Izvještaj otključan za uređivanje",
    },
  },
};

export default class Menu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      company: {},
      categories: [],
      score: 0,
      refreshInterval: null,
      certifier: JSON.parse(localStorage.getItem("certifier")),
      messages: translations[getLanguage()],
      aboveBeyondStats: null,
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.id !== prevProps.id) {
      this.init();
    }
  }

  componentDidMount() {
    this.init();
    this.setState({
      refreshInterval: window.setInterval(this.loadData, 15000),
    });
  }

  componentWillUnmount() {
    const refreshInterval = this.state.refreshInterval;
    if (refreshInterval) {
      window.clearInterval(this.state.refreshInterval);
    }
  }

  init = async () => {
    this.setState({
      loading: true,
    });

    await this.loadCompany();
    await this.loadData();

    this.setState({
      loading: false,
    });
  };

  loadCompany = async () => {
    await API.get("", {
      params: {
        action: "info",
        id: this.props.id,
        language: getLanguage(),
      },
    })
      .then((response) => {
        this.setState({
          company: response.data,
        });
      })
      .catch((error) => {});
  };

  loadData = async () => {
    await API.get("", {
      params: {
        action: "areaCategoryList",
        id: this.props.id,
        language: getLanguage(),
      },
    })
      .then((response) => {
        let data = response.data;
        let totalPoints = 0;
        let totalPointsCertifier = 0;
        let totalPointsCertifierFinal = 0;
        let possiblePoints = 0;

        for (let i in data) {
          let completedType = "final";

          data[i].gradeSumSystem = 0;
          data[i].gradeSumCertifier = 0;
          data[i].gradeSumCertifierFinal = 0;

          for (let j in data[i].areas) {
            const certifierGrade =
              data[i].areas[j].certifierGrade !== null
                ? data[i].areas[j].certifierGrade
                : data[i].areas[j].grade;
            const certifierGradeFinal =
              data[i].areas[j].certifierFinalGrade !== null
                ? data[i].areas[j].certifierFinalGrade
                : data[i].areas[j].grade;

            possiblePoints += 3;
            totalPoints += data[i].areas[j].grade;
            totalPointsCertifier += certifierGrade;
            totalPointsCertifierFinal += certifierGradeFinal;

            if (
              completedType === "final" &&
              data[i].areas[j].certifierFinalGrade === null
            ) {
              completedType = "certifier";
            }

            if (
              completedType !== "system" &&
              data[i].areas[j].certifierGrade === null
            ) {
              completedType = "system";
            }

            data[i].gradeCompletedType = completedType;
            data[i].gradeSumSystem += data[i].areas[j].grade;
            data[i].gradeSumCertifier += certifierGrade;
            data[i].gradeSumCertifierFinal += certifierGradeFinal;
          }
        }

        this.setState({
          categories: data,
          score: Math.round((100 * totalPoints) / possiblePoints),
          scoreCertifier: Math.round(
            (100 * totalPointsCertifier) / possiblePoints
          ),
          scoreCertifierFinal: Math.round(
            (100 * totalPointsCertifierFinal) / possiblePoints
          ),
        });
      })
      .catch((error) => {});

    if (this.state.certifier) {
      await API.get("", {
        params: {
          id: this.props.id,
          action: "aboveBeyondStats",
          language: getLanguage(),
        },
      })
        .then((response) => {
          this.setState({
            aboveBeyondStats: response.data,
          });
        })
        .catch((error) => {});
    }
  };

  _onFinalizeClick = async (event) => {
    if (!window.confirm(this.state.messages.certificationConfirm)) {
      return false;
    }

    await API.post("", {
      id: this.props.id,
      action: "certifierFinalizeGrades",
    })
      .then((response) => {})
      .catch((error) => {});
  };

  _onSettingChange = async (event) => {
    await API.post("", {
      id: this.props.id,
      action: "questionnaireSettingUpdate",
      setting: event.target.name === "inactive" ? "active" : event.target.name,
      value:
        event.target.name === "inactive"
          ? event.target.checked
          : !event.target.checked,
    })
      .then((response) => {})
      .catch((error) => {});
  };

  render() {
    const {
      aboveBeyondStats,
      categories,
      certifier,
      company,
      loading,
      score,
      scoreCertifier,
      scoreCertifierFinal,
      messages,
    } = this.state;

    const { id } = this.props;

    if (loading) {
      return (
        <div className="page__sidebar">
          <Loader />
        </div>
      );
    }

    return (
      <CompanyContext.Consumer>
        {({ name, setName }) => (
          <div className="page__sidebar">
            <div className="menu__user">
              <NavLink
                className="menu__link--main"
                activeClassName="menu__link--active"
                to={`/${id}/questionnaires`}
              >
                {messages.companySelection}{" "}
                <span className="menu__link__info">{company.title}</span>
              </NavLink>
              <Helmet>
                <title>
                  {company.info.questionnaire
                    ? company.info.questionnaire.year.toString()
                    : ""}{" "}
                  - {company.title} - Employer Partner
                </title>
              </Helmet>
              <br />
              <NavLink
                className="menu__link--main"
                activeClassName="menu__link--active"
                to={`/${id}/about`}
                exact={true}
              >
                {messages.aboutCEP}
              </NavLink>
              <NavLink
                className="menu__link--main"
                activeClassName="menu__link--active"
                to={`/${id}/rating`}
              >
                {messages.scoring}
              </NavLink>
              <NavLink
                className="menu__link--main"
                activeClassName="menu__link--active"
                to={`/${id}/instructions`}
              >
                {messages.instructions}
              </NavLink>
              {certifier && (
                <NavLink
                  className="menu__link--main"
                  activeClassName="menu__link--active"
                  to={`/${id}/certifier-instructions`}
                >
                  {messages.instructionsCertifiers}
                </NavLink>
              )}
              <h3>{messages.questionnaire}</h3>
              {certifier && score > 0 && (
                <div className="totals">
                  {company.previousQuestionnaire && (
                    <div className="totals__item">
                      <h4>{company.previousQuestionnaire.year}</h4>
                      <div className={classNames("total total--default")}>
                        {company.previousQuestionnaire.overalResult}%
                      </div>
                    </div>
                  )}
                  <div className="totals__item">
                    <h4>{messages.system}</h4>
                    <div
                      className={classNames("total total--default", {
                        "total--warning": score < 75,
                      })}
                    >
                      {score}%
                    </div>
                  </div>
                  <div className="totals__item">
                    <h4>{messages.certifier}</h4>
                    <div
                      className={classNames("total", {
                        "total--warning": scoreCertifier < 75,
                      })}
                    >
                      {scoreCertifier}%
                    </div>
                  </div>
                  <div className="totals__item">
                    <h4>{messages.final}</h4>
                    <div
                      className={classNames("total total--final", {
                        "total--warning": scoreCertifierFinal < 75,
                      })}
                    >
                      {scoreCertifierFinal}%
                    </div>
                  </div>
                </div>
              )}
              <NavLink
                className="menu__link--main"
                activeClassName="menu__link--active"
                to={`/${id}/info`}
              >
                {messages.organizationInfo}
              </NavLink>
              <ul className="menu">
                {certifier && (
                  <li className="menu__section">
                    <NavLink
                      className="menu__title"
                      activeClassName="active"
                      to={`/${id}/intro-questions`}
                    >
                      0. {messages.introQuestions}
                    </NavLink>
                  </li>
                )}
                {categories.map((category, index) => {
                  const grade =
                    category.gradeCompletedType === "final"
                      ? category.gradeSumCertifierFinal
                      : category.gradeCompletedType === "certifier"
                      ? category.gradeSumCertifier
                      : category.gradeSumSystem;
                  return (
                    <li className="menu__section" key={index}>
                      <div className="menu__section__title">
                        {company.info.questionnaire.keyOnly ? (
                          <span className="menu__title">{category.title}</span>
                        ) : (
                          <NavLink
                            className="menu__title"
                            to={`/${id}/category/${category.slug}`}
                          >
                            {category.title}
                          </NavLink>
                        )}
                        {certifier && (
                          <span
                            className={classNames({
                              grade: true,
                              "grade--default":
                                category.gradeCompletedType === "system",
                              "grade--final":
                                category.gradeCompletedType === "final",
                            })}
                          >
                            {Math.round(
                              (100 * grade) / (category.areas.length * 3)
                            )}
                            %
                          </span>
                        )}
                      </div>
                      <ul className="menu__items">
                        {category.areas.map((item, key) => {
                          const grade =
                            item.certifierFinalGrade !== null
                              ? item.certifierFinalGrade
                              : item.certifierGrade !== null
                              ? item.certifierGrade
                              : item.grade;
                          return (
                            <li className="menu__item" key={key}>
                              <span
                                className={classNames("progress", {
                                  "progress--complete":
                                    item.completeness === 100,
                                })}
                              >
                                {!company.info.questionnaire.keyOnly ||
                                item.minGrade > 0 ? (
                                  <>{item.completeness}%</>
                                ) : (
                                  <>&nbsp;</>
                                )}
                              </span>
                              {company.info.questionnaire.keyOnly &&
                              item.minGrade === 0 ? (
                                <span>{item.title}</span>
                              ) : (
                                <NavLink
                                  activeClassName="menu__item--active"
                                  to={`/${id}/category/${category.slug}/${key}`}
                                >
                                  {item.title}
                                </NavLink>
                              )}
                              {item.minGrade > 0 && (
                                <span className="important">*</span>
                              )}
                              {certifier &&
                                (!company.info.questionnaire.keyOnly ||
                                  item.minGrade > 0) && (
                                  <>
                                    {item.warning && (
                                      <span className="warning">!</span>
                                    )}
                                    {(grade || grade === 0) && (
                                      <span
                                        className={classNames({
                                          grade: true,
                                          "grade--default":
                                            item.certifierGrade === null,
                                          "grade--final":
                                            item.certifierFinalGrade !== null,
                                          "grade--warning":
                                            item.minGrade > grade,
                                        })}
                                      >
                                        {grade}
                                      </span>
                                    )}
                                    {item.previousGrade !== undefined &&
                                      item.previousGrade !== null && (
                                        <span
                                          title={item.previousGrade}
                                          className={classNames({
                                            "previous-grade": true,
                                            "previous-grade--down":
                                              grade < item.previousGrade,
                                            "previous-grade--up":
                                              grade > item.previousGrade,
                                          })}
                                        ></span>
                                      )}
                                  </>
                                )}
                            </li>
                          );
                        })}
                      </ul>
                    </li>
                  );
                })}
                {certifier && (
                  <li className="menu__section">
                    <NavLink
                      className="menu__title"
                      to={`/${id}/above-beyond/area/0`}
                    >
                      {messages.aboveAndBeyond}
                    </NavLink>
                    {aboveBeyondStats && (
                      <ul className="menu__items">
                        {aboveBeyondStats.map((item, key) => {
                          return (
                            <li className="menu__item" key={key}>
                              <NavLink
                                activeClassName="menu__item--active"
                                to={`/${id}/above-beyond/area/${key}`}
                              >
                                {item.category}
                              </NavLink>
                              <span
                                className={classNames(
                                  "grade",
                                  "grade--default",
                                  {
                                    "grade--final": item.percentage >= 70,
                                  },
                                  {
                                    "grade--disabled":
                                      !company.questionnaire.advanced,
                                  }
                                )}
                              >
                                {item.percentage}%
                              </span>
                            </li>
                          );
                        })}
                      </ul>
                    )}
                  </li>
                )}
                <li className="menu__section">
                  <NavLink className="menu__title" to={`/${id}/documents`}>
                    {messages.documents}
                  </NavLink>
                </li>
              </ul>
            </div>

            {certifier && (
              <>
                <div className="menu__certifier">
                  <h2>{messages.certification}</h2>
                  <h3>{messages.grades}</h3>
                  <p style={{ display: "none" }}>
                    Ocjene trenutno nisu zaključene.
                  </p>
                  <p>
                    <span
                      onClick={this._onFinalizeClick}
                      className="button button--light button--small"
                    >
                      {messages.finalizeGrades}
                    </span>
                  </p>
                  <h3>{messages.report}</h3>
                  <p>
                    <NavLink
                      to={`/${id}/report`}
                      target="_blank"
                      className="button button--light button--small"
                    >
                      {messages.viewReport}
                    </NavLink>
                  </p>
                </div>
                <div className="menu__certifier">
                  <h2>{messages.options}</h2>
                  {Object.entries(company.questionnaire.settings).map(
                    (item, key) => (
                      <p key={key}>
                        <label className="toggle__label">
                          <Toggle
                            name={item[0]}
                            defaultChecked={item[1] === 0}
                            onChange={this._onSettingChange}
                            disabled={!company.questionnaire.mainCertifier}
                          />
                          <span>{messages.questionnaireOptions[item[0]]}</span>
                        </label>
                      </p>
                    )
                  )}
                </div>
              </>
            )}
          </div>
        )}
      </CompanyContext.Consumer>
    );
  }
}
