import React, { Component } from "react";
import { getLanguage } from "../../utils/utils";
import ReportBadge from "./ReportBadge";

const translations = {
  hr: {
    index: "Index",
    indexSelectio: "SELECTIO Grupa",
    indexAbout: "O certifikatu Employer Partner",
    indexOverallResult: "Ukupni rezultat",
    indexOverallResultBenchmark: "Ukupni rezultat – benchmark",
    indexKeyTopics: "Rezultati na ključnim temama",
    indexKeyTopicsBenchmark: "Rezultati na ključnim temama – benchmark",
    indexELC: "Rezultati na employee life cycle koracima",
    indexELCBenchmark: "Rezultati na employee life cycle koracima – benchmark",
    indexSteps: "7 koraka employee life cycle modela – snage i sugestije",
    indexHighlights: "Vaša briga za zaposlene u brojevima – snage",
    indexSuggestions: "Vaša briga za zaposlene u brojevima – sugestije",
    indexHighlightsBenchmark: "Vaša briga za zaposlene u brojevima - benchmark",
    indexConfirmation: "Autorizacija",
    indexEducations: "Edukacije koje bi vam mogle biti korisne",
    indexWhatsNext: "Što je sljedeće",
    indexTimeline: "Timeline",
    indexRoles: "Uloge u implementaciji HR procesa",
    indexKeyActivities: "Ključne potrebne aktivnosti",
    indexActionPlan: "Akcijski plan i <em>timeline</em>",
    indexNextSteps: "Daljnji koraci",
    indexAuthorization: "Autorizacija",
    indexStatusQuoRisks: "Rizici statusa quo",
    indexChangeManagement: "Upravljanje promjenama",
  },
  en: {
    index: "Index",
    indexSelectio: "SELECTIO Group",
    indexAbout: "About Employer Partner",
    indexOverallResult: "Overall result",
    indexOverallResultBenchmark: "Overall result - Benchmark",
    indexKeyTopics: "Results on the key topics",
    indexKeyTopicsBenchmark: "Results on the key topics - Benchmark",
    indexELC: "Results on the Employee Life Cycle steps",
    indexELCBenchmark: "Results on the Employee Life Cycle steps - Benchmark",
    indexSteps: "7 steps of Employee life Cycle – Highlights and Suggestions",
    indexHighlights: "Your care for employees in numbers - Highlights",
    indexSuggestions: "Your care for employees in numbers - Suggestions",
    indexHighlightsBenchmark: "Your care for employees in numbers - Benchmark",
    indexConfirmation: "Official confirmation",
    indexEducations: "Educations that we find useful for you",
    indexWhatsNext: "What's next",
    indexTimeline: "Timeline",
    indexRoles: "Roles in Implementing HR Processes",
    indexKeyActivities: "Key Activities",
    indexActionPlan: "Action plan and timeline",
    indexNextSteps: "Next steps",
    indexAuthorization: "Authorisation",
    indexStatusQuoRisks: "Risks of the status quo",
    indexChangeManagement: "Change management",
  },
  sr: {
    index: "Index",
    indexSelectio: "SELECTIO Grupa",
    indexAbout: "O sertifikatu Employer Partner",
    indexOverallResult: "Ukupni rezultat",
    indexOverallResultBenchmark: "Ukupni rezultat – benchmark",
    indexKeyTopics: "Rezultati na ključnim temama",
    indexKeyTopicsBenchmark: "Rezultati na ključnim temama – benchmark",
    indexELC: "Rezultati na employee life cycle koracima",
    indexELCBenchmark: "Rezultati na employee life cycle koracima – benchmark",
    indexSteps: "7 koraka employee life cycle modela – snage i sugestije",
    indexHighlights: "Vaša briga za zaposlene u brojevima – snage",
    indexSuggestions: "Vaša briga za zaposlene u brojevima – sugestije",
    indexHighlightsBenchmark: "Vaša briga za zaposlene u brojevima - benchmark",
    indexConfirmation: "Autorizacija",
    indexEducations: "Edukacije koje bi vam mogle biti korisne",
    indexWhatsNext: "Što je sledeće",
    indexTimeline: "Timeline",
    indexRoles: "Uloge u implementaciji HR procesa",
    indexKeyActivities: "Ključne potrebne aktivnosti",
    indexActionPlan: "Akcijski plan i <em>timeline</em>",
    indexNextSteps: "Daljnji koraci",
    indexAuthorization: "Autorizacija",
    indexStatusQuoRisks: "Rizici statusa quo",
    indexChangeManagement: "Rizici statusa quo",
  },
};

export default class ReportIndex extends Component {
  render() {
    const { passed } = this.props;

    const messages = translations[getLanguage()];

    return (
      <div className="report__page__wrapper">
        <div className="report__page">
          <div className="report__info report__section--padding">
            <h2>{messages.index}</h2>
            <ul>
              {!passed && (
                <li>
                  <a href="#page-selectio">{messages.indexSelectio}</a>
                </li>
              )}
              <li>
                <a href="#page-about">{messages.indexAbout}</a>
              </li>
              <li>
                <a href="#page-result">{messages.indexOverallResult}</a>
              </li>
              <li>
                <a href="#page-result-benchmark">
                  {messages.indexOverallResultBenchmark}
                </a>
              </li>
              <li>
                <a href="#page-key-topics">{messages.indexKeyTopics}</a>
              </li>
              <li>
                <a href="#page-key-topics-benchmark">
                  {messages.indexKeyTopicsBenchmark}
                </a>
              </li>
              <li>
                <a href="#page-elc">{messages.indexELC}</a>
              </li>
              <li>
                <a href="#page-elc-benchmark">{messages.indexELCBenchmark}</a>
              </li>
              <li>
                <a href="#page-steps">{messages.indexSteps}</a>
              </li>
              <li>
                <a href="#page-care-highlights">{messages.indexHighlights}</a>
              </li>
              <li>
                <a href="#page-care-suggestions">{messages.indexSuggestions}</a>
              </li>
              <li>
                <a href="#page-care-benchmark">
                  {messages.indexHighlightsBenchmark}
                </a>
              </li>
              {passed && (
                <>
                  <li>
                    <a href="#page-educations">{messages.indexEducations}</a>
                  </li>
                  <li>
                    <a href="#page-confirmation">
                      {messages.indexConfirmation}
                    </a>
                  </li>
                </>
              )}
              {passed ? (
                <>
                  <li>
                    <a href="#page-whatsnext">{messages.indexWhatsNext}</a>
                  </li>
                  <li>
                    <a href="#page-timeline">{messages.indexTimeline}</a>
                  </li>
                </>
              ) : (
                <>
                  <li>
                    <a href="#page-key-activities">
                      {messages.indexKeyActivities}
                    </a>
                  </li>
                  <li>
                    <a
                      href="#page-action-plan"
                      dangerouslySetInnerHTML={{
                        __html: messages.indexActionPlan,
                      }}
                    />
                  </li>
                  <li>
                    <a
                      href="#page-change-management"
                      dangerouslySetInnerHTML={{
                        __html: messages.indexChangeManagement,
                      }}
                    />
                  </li>
                  <li>
                    <a href="#page-roles">{messages.indexRoles}</a>
                  </li>
                  <li>
                    <a href="#page-confirmation">
                      {messages.indexAuthorization}
                    </a>
                  </li>
                  <li>
                    <a href="#page-risks">{messages.indexStatusQuoRisks}</a>
                  </li>
                  <li>
                    <a href="#page-timeline">{messages.indexNextSteps}</a>
                  </li>
                </>
              )}
            </ul>
          </div>
        </div>
        <ReportBadge />
      </div>
    );
  }
}
