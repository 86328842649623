import React, { Component } from 'react';
import ReportBadge from './ReportBadge';
import { info } from "./components/ReportTemplates";
import { getLanguage } from '../../utils/utils';

export default class ReportInfo extends Component {
  render() {
    return <div className="report__page__wrapper">
      <div className="report__page report__page--info">
        <div className="report__content__column">
          <div className="report__section__info" dangerouslySetInnerHTML={{__html: info[getLanguage()]}} />
        </div>
      </div>
      <ReportBadge/>
    </div>
  }
}