import React, { Component } from "react";
import { NavLink, Redirect } from "react-router-dom";
import API from "../api";
import { Loader } from "../components";
import { getLanguage } from "../utils/utils";

const translations = {
  hr: {
    questionnaires: "Upitnici",
    company: "Tvrtka",
    year: "Godina",
  },
  en: {
    questionnaires: "Questionnaires",
    company: "Company",
    year: "Year",
  },
  sr: {
    questionnaires: "Upitnici",
    company: "Tvrtka",
    year: "Godina",
  },
};

export default class Questionnaires extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: null,
      redirect: false,
      messages: translations[getLanguage()],
    };
  }

  componentDidMount() {
    this.loadData();
  }

  loadData = async (slug) => {
    await API.get("", {
      params: {
        action: "certifierQuestionnaireList",
      },
    })
      .then((response) => {
        this.setState({
          data: response.data,
        });
      })
      .catch((error) => {});
  };

  render() {
    const { data, messages, redirect } = this.state;

    if (redirect) {
      return <Redirect to="/" />;
    }

    if (!data) {
      return <Loader />;
    }

    return (
      <div className="page__content">
        <article className="article">
          <h2>{messages.questionnaires}</h2>
        </article>

        {data && (
          <>
            <table className="table table--simple table--striped">
              <tbody>
                <tr>
                  <th>{messages.company}</th>
                  <th>{messages.year}</th>
                </tr>
                {data.map((item, key) => (
                  <tr key={key}>
                    <td style={{ width: "100%" }}>
                      <NavLink
                        className="menu__link--main"
                        activeClassName="menu__link--active"
                        to={`/${item.id}/about`}
                        onClick={() => { localStorage.setItem('q', `${item.id}`) }}
                      >
                        {item.title}
                      </NavLink>
                    </td>
                    <td>
                      {item.year}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </>
        )}
      </div>
    );
  }
}
