import React, { Component } from "react";
import ReportBadge from "./ReportBadge";
import classNames from "classnames";

export default class ReportContent extends Component {
  render() {
    const {
      ab,
      additionalClass,
      background,
      hideBadge,
      id,
      title,
      subtitle,
      zoomed,
    } = this.props;

    return (
      <div className="report__page__wrapper" id={id}>
        <div
          className={classNames(
            "report__page",
            { "report__page--ab": ab },
            {
              "report__page--ab--zoomed": zoomed,
            }
          )}
          style={{
            backgroundImage: background ? `url(${background})` : "none",
          }}
        >
          <div
            className={classNames(
              "report__info",
              { "report__section--padding": !additionalClass },
              additionalClass
            )}
          >
            {title && <h2 dangerouslySetInnerHTML={{ __html: title }} />}
            {subtitle && (
              <h3
                className="subtitle"
                dangerouslySetInnerHTML={{ __html: subtitle }}
              />
            )}
            {this.props.children}
          </div>
          {ab && !hideBadge && (
            <>
              <div className="report__ab__badges">
                <img alt="" src="/assets/images/report/ab/badge.png" />
              </div>
            </>
          )}
        </div>
        {!ab && !hideBadge && <ReportBadge />}
      </div>
    );
  }
}
