import React, { Component } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Loader } from "../components";
import API from "../api";
import { getCountries, getLanguage } from "../utils/utils";

const otherSelectValue = "999999";

const translations = {
  hr: {
    changesSaved: "Promjene su uspješno spremljene!",
    changesError: "Došlo je do greške. Molimo pokušajte ponovo.",
    organizationInfo: "Podaci o organizaciji",
    organizationIntro:
      "Zamolili bismo Vas da popunite sve podatke prije prelaska na ostale dijelove upitnika",
    organizationName: "Naziv organizacije",
    organizationLogo: "Logo ogranizacije",
    organizationLogoDescription:
      "Molimo da priložite logo organizacije u SVG formatu. Uneseni logo smatra se službenim logotipom organizacije i koristi se na promotivnim materijalima.",
    brandName: "Ime brenda",
    brandNameDescription:
      "Uneseni naziv smatra se službenim nazivom organizacije, a koristi se u eksternoj komunikaciji, te je upisan na plaketu i certifikat.",
    address: "Adresa",
    city: "Grad",
    country: "Država",
    contactPerson: "Kontakt osoba",
    contactPersonEmail: "E-mail kontakt osobe",
    other: "Ostalo",
    primaryBusiness: "Industrija",
    primaryBusinessInfo: "Molimo upišite industriju",
    employeeCount: "Broj zaposlenika",
    employeeCountInfo: "Na datum 31.12. prethodne godine.",

    ownershipType: "Tip vlasništva",
    ownershipTypeIntro: "Molimo upišite tip vlasništva",
    newManagers:
      "Je li u protekloj godini u organizaciji bilo novih menadžera?",
    externalWorkforceUse:
      "Koristi li se u organizaciji eksterna radna snaga na period od barem 3 mjeseca punog radnog vremena godišnje?",
    externalWorkforceInfo:
      "Eksterna radna snaga: ustupljeni radnici, povremeni sezonski radnici, studenti, učenici na praksi, povremeni radnici preko autorskog ili ugovora o djelu, freelanceri, outsourcani radnici od vendora",
    no: "Ne",
    yes: "Da",
    syndicateInfo:
      "Je li u organizaciji organiziran i aktivan sindikat i/ili Radničko vijeće te potpisan Kolektivni Ugovor na nivou organizacije?",
    submit: "Spremi",
    categories: {
      1: "FMCG",
      2: "Financijska",
      3: "ICT",
      4: "Farmaceutska",
      5: "Turizam i ugostiteljstvo",
      6: "Maloprodaja",
      7: "Proizvodna",
      8: "Poljoprivredna",
      9: "Građevinska",
      10: "Logistika",
      11: "Stručne djelatnosti",
      12: "Mediji",
      13: "Igre na sreću",
      14: "Javni sektor",
      15: "Energetska",
      16: "Ostalo",
    },
    ownershipList: {
      "Privatna tvrtka u domaćem vlasništvu":
        "Privatna tvrtka u domaćem vlasništvu",
      "Privatna tvrtka u stranom vlasništvu":
        "Privatna tvrtka u stranom vlasništvu",
      "Državna tvrtka": "Državna tvrtka",
      "Javna ustanova": "Javna ustanova",
    },
    numberOfEmployeesHired: "Koliko ste osoba zaposlili u prošloj godini?",
    numberOfEmployeesLeft:
      "Koliko je zaposlenika u prošloj godini svojom odlukom napustilo organizaciju?",
    numberOfEmployeesLaidOff:
      "Koliko ste zaposlenika otpustili u prošloj godini?",
    countries: {
      al: "Albanija",
      at: "Austrija",
      ba: "Bosna i Hercegovina",
      hr: "Hrvatska",
      hu: "Mađarska",
      me: "Crna Gora",
      mk: "Sjeverna Makedonija",
      pl: "Poljska",
      rs: "Srbija",
      si: "Slovenija",
    },
  },
  en: {
    changesSaved: "The changes were successfully saved!",
    changesError: "There was an error. Please try again.",
    organizationInfo: "Information about the organization",
    organizationIntro:
      "Please fill in all the data before moving on to other parts of the questionnaire",
    organizationName: "Organization's name",
    organizationLogo: "Organization's logo",
    organizationLogoDescription:
      "Please attach the logo of the organization in SVG format. The entered logo is considered the official logo of the organization and is used for promotional purposes.",
    brandName: "Brand Name",
    brandNameDescription:
      "The entered name is considered the official name of the organization, and is used in external communication, and is written on the crystal award and certificate.",
    address: "Address",
    city: "Town",
    country: "Country",
    contactPerson: "Contact person",
    contactPersonEmail: "Her/his e-mail address",
    other: "Other",
    primaryBusiness: "Industry",
    primaryBusinessInfo: "Please provide an industry",
    employeeCount: "Number of employees",

    employeeCountInfo: "On the 31st of December of the previous year.",
    ownershipType: "Type of business ownership",
    ownershipTypeIntro: "Type of business ownership",
    newManagers:
      "Did the organization have any new managers in the previous year?",
    externalWorkforceUse:
      "Does the organization use the external workforce for a period of at least 3 months of full time work per year?",
    externalWorkforceInfo:
      "External workforce: leased workers, seasonal workers, students, high school trainees, temporary workers who signed copyright or a temporary service contract, freelancers, outsourced workers by vendors",
    no: "No",
    yes: "Yes",
    syndicateInfo:
      "Does the organization have a formal Workers' Council and/or union that is active and did it formalize a Collective Agreement?",
    submit: "Save",
    categories: {
      1: "FMCG",
      2: "Finance",
      3: "ICT",
      4: "Pharma",
      5: "Hospitality",
      6: "Retail",
      7: "Manufacturing",
      8: "Agriculture",
      9: "Construction",
      10: "Logistics",
      11: "Professional Services",
      12: "Media",
      13: "Gaming",
      14: "Public Sector",
      15: "Energy",
      16: "Other",
    },
    ownershipList: {
      "Privatna tvrtka u domaćem vlasništvu": "Locally owned private company",
      "Privatna tvrtka u stranom vlasništvu":
        "Private company with foreign ownership",
      "Državna tvrtka": "State-owned company",
      "Javna ustanova": "Public institution",
    },
    numberOfEmployeesHired: "How many people did you hire last year?",
    numberOfEmployeesLeft:
      "How many employees decided to leave the organization in the last year?",
    numberOfEmployeesLaidOff: "How many employees did you lay off last year?",
    countries: {
      al: "Albania",
      at: "Austria",
      ba: "Bosnia and Herzegovina",
      hr: "Croatia",
      hu: "Hungary",
      me: "Montenegro",
      mk: "North Macedonia",
      pl: "Poland",
      rs: "Serbia",
      si: "Slovenia",
    },
  },
  sr: {
    changesSaved: "Promene su uspešno spremljene!",
    changesSachangesErrorved: "Došlo je do greške. Molimo pokušajte ponovo.",
    organizationInfo: "Podaci o organizaciji",
    organizationIntro:
      "Zamolili bismo Vas da popunite sve podatke pre prelaska na ostale delove upitnika",
    organizationName: "Naziv organizacije",
    organizationLogo: "Logo ogranizacije",
    organizationLogoDescription:
      "Molimo da priložite logo organizacije u SVG formatu. Uneseni logo smatra se službenim logotipom organizacije i koristi se na promotivnim materijalima.",
    brandName: "Ime brenda",
    brandNameDescription:
      "Uneseni naziv smatra se službenim nazivom organizacije, a koristi se u eksternoj komunikaciji, te je upisan na plaketu i sertifikat.",
    address: "Adresa",
    city: "Grad",
    country: "Država",
    contactPerson: "Kontakt osoba",
    contactPersonEmail: "Imejl kontakt osobe",
    other: "Ostalo",
    primaryBusiness: "Industrija",
    primaryBusinessInfo: "Molimo upišite industriju",
    employeeCount: "Broj zaposlenih",
    employeeCountInfo: "Dana 31.12. prethodne godine.",
    ownershipType: "Tip vlasništva",
    ownershipTypeIntro: "Molimo upišite tip vlasništva",
    newManagers:
      "Da li je bilo novih menadžera u organizaciji u protekloj godini?",
    externalWorkforceUse:
      "Koristi li se u organizaciji eksterna radna snaga na period od najmanje 3 meseca punog radnog vremena godišnje?",
    externalWorkforceInfo:
      "Eksterna radna snaga: iznajmljeni radnici, povremeni sezonski radnici, studenti, učenici na praksi, povremeni radnici preko autorskog ili ugovora o delu, frilenseri, iznajmljeni radnici od dobavljača",
    no: "Ne",
    yes: "Da",
    syndicateInfo:
      "Da li je u organizaciji organizovan i aktivan sindikat i/ili radničko veće, kao i potpisan kolektivni ugovor na nivou organizacije?",
    submit: "Prihvati",
    categories: {
      1: "FMCG",
      2: "Finansijska",
      3: "ICT",
      4: "Farmaceutska",
      5: "Turizam i ugostiteljstvo",
      6: "Maloprodaja",
      7: "Proizvodna",
      8: "Poljoprivredna",
      9: "Građevinska",
      10: "Logistika",
      11: "Profesionalne delatnosti",
      12: "Mediji",
      13: "Igre na sreću",
      14: "Javni sektor",
      15: "Energetska",
      16: "Ostalo",
    },
    ownershipList: {
      "Privatna tvrtka u domaćem vlasništvu":
        "Privatna firma u domaćem vlasništvu",
      "Privatna tvrtka u stranom vlasništvu":
        "Privatna firma u stranom vlasništvu",
      "Državna tvrtka": "Državna firma",
      "Javna ustanova": "Javna ustanova",
    },
    numberOfEmployeesHired: "Koliko ste osoba zaposlili u prošloj godini?",
    numberOfEmployeesLeft:
      "Koliko je zaposlenih u prošloj godini svojom odlukom napustilo organizaciju?",
    numberOfEmployeesLaidOff:
      "Koliko ste zaposlenih otpustili u prošloj godini?",
    countries: {
      al: "Albanija",
      at: "Austrija",
      ba: "Bosna i Hercegovina",
      hr: "Hrvatska",
      hu: "Mađarska",
      me: "Crna Gora",
      mk: "Severna Makedonija",
      pl: "Poljska",
      rs: "Srbija",
      si: "Slovenija",
    },
  },
};

export default class Info extends Component {
  constructor(props) {
    super(props);

    const rater = JSON.parse(localStorage.getItem("certifier"));
    this.state = {
      id: this.props.match.params.id,
      loading: true,
      info: null,
      countries: [],
      selectedCategories: [],
      types: [],
      rater: rater ? true : false,
      messages: translations[getLanguage()],
    };
  }

  componentDidMount() {
    this._loadData();
  }

  _formOnSubmit = async (event) => {
    event.preventDefault();

    let info = this.state.info;
    info.typeId = info.typeId !== otherSelectValue ? info.typeId : null;
    info.categoryId =
      info.categoryId !== otherSelectValue ? info.categoryId : null;

    await API.post("", {
      action: "infoSave",
      info: info,
      countries: this.state.countries,
      categories: this.state.selectedCategories,
      id: this.state.id,
    })
      .then((response) => {
        toast(this.state.messages.changesSaved, {
          type: "success",
        });
      })
      .catch((error) => {
        toast(this.state.messages.changesError, {
          type: "error",
        });
      });
  };

  _inputOnChange = (event) => {
    let info = this.state.info;
    info[event.target.name] = event.target.value;
    this.setState({
      info: info,
    });
  };

  _countryOnChange = (event) => {
    const value = event.target.value;
    let countries = Array.from(this.state.countries);

    if (countries.includes(value)) {
      countries = countries.filter((item) => item !== value);
    } else {
      countries.push(value);
    }

    this.setState({
      countries: countries,
    });
  };

  _categoryOnChange = (event) => {
    const value = parseInt(event.target.value, 10);
    let categories = this.state.selectedCategories;

    console.log(value, categories);

    if (categories.includes(value)) {
      categories = categories.filter((item) => item !== value);
    } else {
      categories.push(value);
    }

    this.setState({
      selectedCategories: categories,
    });
  };

  _loadData = async () => {
    await API.get("", {
      params: {
        action: "info",
        id: this.state.id,
      },
    })
      .then((response) => {
        let info = response.data;
        info.typeId =
          info.typeDescription !== null ? otherSelectValue : info.typeId;
        info.categoryId =
          info.categoryDescription !== null
            ? otherSelectValue
            : info.categoryId;

        this.setState({
          info: response.data,
          countries: response.data.countries,
          selectedCategories: response.data.categories,
        });
      })
      .catch((error) => {});

    await API.get("", {
      params: {
        action: "companyTypes",
      },
    })
      .then((response) => {
        this.setState({
          types: response.data,
        });
      })
      .catch((error) => {});

    await API.get("", {
      params: {
        action: "companyCategories",
      },
    })
      .then((response) => {
        this.setState({
          categories: response.data,
        });
      })
      .catch((error) => {});

    this.setState({
      loading: false,
    });
  };

  render() {
    const {
      categories,
      selectedCategories,
      countries,
      loading,
      info,
      messages,
      rater,
      types,
    } = this.state;

    if (loading) {
      return <Loader />;
    }

    return (
      <div className="page__content">
        <article className="article">
          <h1>{messages.organizationInfo}</h1>
          <p className="warning">{messages.organizationIntro}</p>
          <form method="post" onSubmit={this._formOnSubmit}>
            <fieldset className="fieldset">
              <label className="label">{messages.organizationName}</label>
              <input
                type="text"
                name="title"
                value={info.title}
                required={!rater}
                className="input input--medium"
                onChange={this._inputOnChange}
                readOnly={true}
              />
            </fieldset>
            <fieldset className="fieldset" style={{ display: "none" }}>
              <label className="label">{messages.organizationLogo}</label>
              <p className="note">{messages.organizationLogoDescription}</p>
              <input
                type="file"
                name="logo"
                required={false}
                className="input input--medium"
                onChange={this._inputOnChange}
              />
            </fieldset>
            <fieldset className="fieldset">
              <label className="label">{messages.brandName}</label>
              <p className="note">{messages.brandNameDescription}</p>
              <input
                type="text"
                name="brand"
                value={info.brand !== null ? info.brand : ""}
                required={!rater}
                className="input input--medium"
                onChange={this._inputOnChange}
              />
            </fieldset>
            <fieldset className="fieldset">
              <label className="label">{messages.address}</label>
              <input
                type="text"
                name="address"
                value={info.address}
                required={!rater}
                className="input input--medium"
                onChange={this._inputOnChange}
              />
            </fieldset>
            <fieldset className="fieldset">
              <label className="label">{messages.city}</label>
              <input
                type="text"
                name="city"
                value={info.city}
                required={!rater}
                className="input input--medium"
                onChange={this._inputOnChange}
              />
            </fieldset>
            <fieldset className="fieldset">
              <label className="label">{messages.country}</label>
              <div>
                {getCountries().map((item, key) => (
                  <div key={key}>
                    <label>
                      <input
                        type="checkbox"
                        value={item}
                        checked={countries.includes(item)}
                        onChange={this._countryOnChange}
                      />{" "}
                      {messages.countries[item]}
                    </label>
                  </div>
                ))}
              </div>
            </fieldset>
            <fieldset className="fieldset">
              <label className="label">{messages.contactPerson}</label>
              <input
                type="text"
                name="contact"
                value={info.contact}
                required={!rater}
                className="input input--medium"
                onChange={this._inputOnChange}
              />
            </fieldset>
            <fieldset className="fieldset">
              <label className="label">{messages.contactPersonEmail}</label>
              <input
                type="email"
                name="name"
                value={info.email}
                required={!rater}
                className="input input--medium"
                onChange={this._inputOnChange}
              />
            </fieldset>

            <fieldset className="fieldset">
              <label className="label">{messages.primaryBusiness}</label>
              <div>
                {categories.map((item, key) => (
                  <div key={key}>
                    <label>
                      <input
                        type="checkbox"
                        value={item.id}
                        checked={selectedCategories.includes(
                          parseInt(item.id, 10)
                        )}
                        onChange={this._categoryOnChange}
                      />{" "}
                      {messages.categories[item.id]}
                    </label>
                  </div>
                ))}
              </div>
            </fieldset>
            <fieldset className="fieldset">
              <label className="label">{messages.employeeCount}</label>

              <p className="note">{messages.employeeCountInfo}</p>
              <input
                type="number"
                name="numberOfEmployees"
                value={info.numberOfEmployees}
                required={!rater}
                className="input input-number"
                onChange={this._inputOnChange}
              />
            </fieldset>
            <fieldset className="fieldset">
              <label className="label">{messages.ownershipType}</label>
              <select
                name="typeId"
                value={info.typeId}
                required={!rater}
                className="input input--medium"
                onChange={this._inputOnChange}
              >
                <option />
                {types.map((item, key) => (
                  <option key={key} value={item.id}>
                    {messages.ownershipList[item.title]}
                  </option>
                ))}
                <option value={otherSelectValue}>{messages.other}</option>
              </select>
              {info.typeId === otherSelectValue && (
                <div className="input__additional">
                  <input
                    type="typeDescription"
                    name="typeDescription"
                    placeholder={messages.ownershipTypeIntro}
                    value={info.typeDescription}
                    required={!rater}
                    className="input input--medium"
                    onChange={this._inputOnChange}
                  />
                </div>
              )}
            </fieldset>
            <fieldset className="fieldset">
              <label className="label">{messages.externalWorkforceUse}</label>
              <p className="note">{messages.externalWorkforceInfo}</p>
              <select
                name="externalWorkForce"
                value={
                  info.externalWorkForce === null ? "" : info.externalWorkForce
                }
                required={!rater}
                className="input input--medium"
                onChange={this._inputOnChange}
              >
                <option value="">-</option>
                <option value="0">{messages.no}</option>
                <option value="1">{messages.yes}</option>
              </select>
            </fieldset>
            <fieldset className="fieldset">
              <label className="label">{messages.syndicateInfo}</label>
              <select
                name="sindicate"
                value={info.sindicate === null ? "" : info.sindicate}
                required={!rater}
                className="input input--medium"
                onChange={this._inputOnChange}
              >
                <option value="">-</option>
                <option value="0">{messages.no}</option>
                <option value="1">{messages.yes}</option>
              </select>
            </fieldset>
            <fieldset className="fieldset">
              <label className="label">{messages.newManagers}</label>
              <select
                name="newManagers"
                value={info.newManagers === null ? "" : info.newManagers}
                required={!rater}
                className="input input--medium"
                onChange={this._inputOnChange}
              >
                <option value="">-</option>
                <option value="0">{messages.no}</option>
                <option value="1">{messages.yes}</option>
              </select>
            </fieldset>
            <fieldset className="fieldset">
              <label className="label">{messages.numberOfEmployeesHired}</label>
              <input
                type="number"
                name="numberOfEmployeesHired"
                value={info.numberOfEmployeesHired}
                required={!rater}
                className="input input--number"
                onChange={this._inputOnChange}
              />
            </fieldset>
            <fieldset className="fieldset">
              <label className="label">{messages.numberOfEmployeesLeft}</label>
              <input
                type="number"
                name="numberOfEmployeesLeft"
                value={info.numberOfEmployeesLeft}
                required={!rater}
                className="input input--number"
                onChange={this._inputOnChange}
              />
            </fieldset>
            <fieldset className="fieldset">
              <label className="label">
                {messages.numberOfEmployeesLaidOff}
              </label>
              <input
                type="number"
                name="numberOfEmployeesLaidOff"
                value={info.numberOfEmployeesLaidOff}
                required={!rater}
                className="input input--number"
                onChange={this._inputOnChange}
              />
            </fieldset>
            <fieldset className="fieldset fieldset--button">
              <button type="submit" className="button button--big">
                {messages.submit}
              </button>
            </fieldset>
          </form>
        </article>
        <ToastContainer />
      </div>
    );
  }
}
