import React, { Component } from "react";
import { NumberInput } from "../components";
import { getLanguage } from "../utils/utils";

const translations = {
  hr: {
    benefitsMonetary: "Moguće kratkoročne i dugoročne novčane nagrade",
    benefitsNonMonetary:
      "Moguće nenovčane nagrade koje predstavljaju indirektnu financijsku korist zaposleniku i dodatni trošak za poslodavca",
    employeePercentage: "Postotak zaposlenika",
    employeeGroup: "Grupe zaposlenika koje su obuhvaćene",
  },
  en: {
    benefitsMonetary: "Possible short- and long-term material remuneration",
    benefitsNonMonetary:
      "Possible non-material remuneration representing indirect financial benefit to the employee and additional cost to the employer",
    employeePercentage: "Employee percentage",
    employeeGroup: "Groups of employees that are covered",
  },
  sr: {
    benefitsMonetary: "Moguće kratkoročne i dugoročne novčane nagrade:",
    benefitsNonMonetary:
      "Moguće nenovčane nagrade koje predstavljaju indirektnu finansijsku korist zaposlenom i dodatni trošak za poslodavca:",
    employeePercentage: "Postotak zaposlenika",
    employeeGroup: "Grupe zaposlenih koje su obuhvaćene",
  },
};

const monetary = {
  hr: [
    {
      id: "1",
      label:
        "Dionice / udio u tvrtki / posebna dugoročna nagrada LTI bonus na temelju višegodišnjih parametara uspješnosti kompanije",
    },
    { id: "2", label: "Božićnica" },
    { id: "3", label: "Jubilarne nagrade" },
    { id: "4", label: "Dar za dijete" },
    { id: "5", label: "Regres" },
    { id: "6", label: "Jednokratna pomoć kod rođenja ili posvojenja djeteta" },
    {
      id: "7",
      label:
        "Novčana pomoć (za slučaj smrti, teške bolesti zaposlenika i/ili užeg člana obitelji, stipendije za djecu preminulih radnika)",
    },
    { id: "8", label: "Naknada za prijevoz" },
    {
      id: "9",
      label:
        "Programi nagrađivanja van bonus sheme i varijabilne plaće (npr. nagrađivanje inovacija, zaposlenik kvartala)",
    },
    { id: "10", label: "Subvencioniranje roditeljskog dopusta" },
    { id: "11", label: "Kreditne kartice" },
    {
      id: "12",
      label:
        "Otpremnine (za slučaj poslovno ili osobno uvjetovanog otkaza) iznad zakonskog minimuma",
    },
    { id: "13", label: "Otpremnine za umirovljenje iznad zakonskog minimuma" },
    { id: "14", label: "Povoljniji uvjeti bankovnog poslovanja" },
    {
      id: "15",
      label:
        "Pomoć zaposlenicima koji imaju djecu s posebnim potrebama ili skrbe o osobi sa invaliditetom",
    },
    { id: "16", label: "Naknada za prehranu" },
    { id: "17", label: "Naknada za stanovanje" },
    {
      id: "18",
      label: "Krediti ili zajmovi zaposlenicima od strane kompanije",
    },
    { id: "19", label: "Stipendiranje djece sadašnjih ili bivših radnika" },
    {
      id: "20",
      label: "Zaklada ili drugi oblik solidarnosti zaposlenika i/ili kompanije",
    },
  ],
  en: [
    {
      id: "1",
      label:
        "stock options/shares in the company/special LTI bonus based on the multi-annual performance parameters of the company",
    },
    { id: "2", label: "Christmas bonus" },
    { id: "3", label: "Jubilee Awards" },
    { id: "4", label: "gift for a child" },
    { id: "5", label: "vacation bonus" },
    {
      id: "6",
      label: "one-time financial aid for the birth or adoption of a child",
    },
    {
      id: "7",
      label:
        "financijal aid (in case of death, serious illness of the employee and/or immediate family member, scholarships for the children of deceased employees)",
    },
    { id: "8", label: "covered transport costs" },
    {
      id: "9",
      label:
        "remuneration schemes apart from the bonus scheme and variable salaries (e.g. rewarding innovation, employee of the year)",
    },
    { id: "10", label: "subsidizing parental leave" },
    { id: "11", label: "credit cards" },
    {
      id: "12",
      label:
        "severance pay (in the case of business or personally conditioned dismissal) above the legal minimum",
    },
    { id: "13", label: "severance pay for retirement above the legal minimum" },
    { id: "14", label: "more favourable conditions for banking operations" },
    {
      id: "15",
      label:
        "aid for employees who have children with special needs or care for a person with disabilities",
    },
    { id: "16", label: "sudsidized costs for meals" },
    { id: "17", label: "sudsidized costs for housing" },
    { id: "18", label: "credit or loans to employees by the company" },
    {
      id: "19",
      label: "scholarships for children of current or former employees",
    },
    {
      id: "20",
      label:
        "foundation or other form of solidarity of employees and/or company",
    },
  ],
  sr: [
    {
      id: "1",
      label:
        "Deonice / udeo u firmi / posebna dugoročna nagrada LTI bonus na osnovu višegodišnjih parametara uspešnosti kompanije",
    },
    { id: "2", label: "Novogodišnji bonus" },
    { id: "3", label: "Jubilarne nagrade" },
    { id: "4", label: "Poklon za dete" },
    { id: "5", label: "Regres" },
    { id: "6", label: "Jednokratna pomoć kod rođenja ili usvajanja deteta" },
    {
      id: "7",
      label:
        "Novčana pomoć (za slučaj smrti, teške bolesti zaposlenog i/ili užeg člana porodice, stipendije za decu preminulih radnika)",
    },
    { id: "8", label: "Naknada za prevoz" },
    {
      id: "9",
      label:
        "Programi nagrađivanja van bonus sheme i varijabilne plate (npr: nagrađivanje inovacija, radnik kvartala)",
    },
    { id: "10", label: "Subvencionisanje roditeljskog odsustva" },
    { id: "11", label: "Kreditne kartice" },
    {
      id: "12",
      label:
        "Otpremnine (za slučaj poslovno ili lično uslovljenog otkaza) iznad zakonskog minimuma",
    },
    { id: "13", label: "Otpremnine za penzionisanje iznad zakonskog minimuma" },
    { id: "14", label: "Povoljniji uslovi bankovnog poslovanja" },
    {
      id: "15",
      label:
        "Pomoć zaposlenima koji imaju decu s posebnim potrebama ili brinu o osobi sa invaliditetom",
    },
    { id: "16", label: "Naknada za prehranu" },
    { id: "17", label: "Naknada za stanovanje" },
    { id: "18", label: "Krediti ili zajmovi zaposlenima od strane kompanije" },
    { id: "19", label: "Stipendiranje dece sadašnjih ili bivših radnika" },
    {
      id: "20",
      label:
        "Fondacija ili drugi oblik solidarnosti zaposlenih i/ili kompanije",
    },
  ],
};

const nonMonetary = {
  hr: [
    { id: "1", label: "Službeni automobil u privatne svrhe" },
    { id: "2", label: "Parkirno mjesto" },
    { id: "3", label: "Dopunsko zdravstveno osiguranje" },
    { id: "4", label: "Dodatno zdravstveno osiguranje" },
    { id: "5", label: "Životno osiguranje" },
    {
      id: "6",
      label: "Izdvajanje poslodavca u 3.mirovinski stup za pojedinog radnika",
    },
    { id: "7", label: "Osiguranje od nezgoda (van zakonskog minimuma)" },
    { id: "8", label: "Sistematski pregledi (van zakonski propisanih)" },
    {
      id: "9",
      label: "Korištenje mobilnog uređaja i dio tarife u privatne svrhe",
    },
    {
      id: "10",
      label:
        "Dodatni slobodni dani / dani godišnjeg (iznad zakonskog minimuma)",
    },
    { id: "11", label: "Plaćeni dopust (primjerice za dodatne edukacije)" },
    { id: "12", label: "Povoljniji krediti u banci" },
    { id: "13", label: "Beneficirani obroci" },
    { id: "14", label: "Snacks (voće, kava, sokovi, čajevi, grickalice...) " },
    {
      id: "15",
      label:
        "Small incentive nagrade (kino ulaznice, poklon bonovi za parfumerije, knjižare, rođendanski poklon...)",
    },
    { id: "16", label: "Subvencionirane sportske aktivnosti" },
    { id: "17", label: "Organizirane sportske grupe unutar kompanije" },
    {
      id: "18",
      label:
        "Proizvodi tvrtke pod povoljnijim uvjetima odnosno popusti za zaposlenike na usluge kompanije",
    },
    { id: "19", label: "Popusti na usluge partnera" },
    { id: "20", label: "Dječji vrtić (korporativni vrtić)" },
    { id: "21", label: "Podrška u preseljenju" },
    { id: "22", label: "Psihološko savjetovanje" },
    {
      id: "23",
      label:
        "Sufinanciranje ili financiranje školovanja koje nije neophodno za obavljanje posla",
    },
    { id: "24", label: "Timski budžeti za teambuliding aktivnosti" },
  ],
  en: [
    { id: "1", label: "company car for private purposes" },
    { id: "2", label: "parking spot" },
    { id: "3", label: "supplementary health insurance" },
    { id: "4", label: "additional health insurance" },
    { id: "5", label: "life insurance" },
    {
      id: "6",
      label:
        "the employer who invests to the optional personal pension fund (3rd fund) for each employee",
    },
    { id: "7", label: "accident insurance (besides the legal minimum)" },
    { id: "8", label: "annual medical exams (besides the legally prescribed)" },
    {
      id: "9",
      label:
        "utilisation of a mobile device and part of the tariff for private purposes",
    },
    {
      id: "10",
      label:
        "supplementary days off / days of annual leave (besides the legal minimum)",
    },
    { id: "11", label: "paid leave (e.g. for additional education)" },
    { id: "12", label: "more affordable loans in the bank" },
    { id: "13", label: "offered meals" },
    { id: "14", label: "snacks (fruits, coffee, juices, teas, snacks...)" },
    {
      id: "15",
      label:
        "small incentive awards (cinema tickets, gift vouchers for perfumeries, bookstores, birthday present etc.)",
    },
    { id: "16", label: "subsidized sports activities" },
    { id: "17", label: "organized sports groups within the company" },
    {
      id: "18",
      label:
        "products of the company with a more favorable price/conditions or reductions for employees on the services that the company offers",
    },
    { id: "19", label: "discounts on partner services" },
    { id: "20", label: "Kindergarten (corporate kindergarten)" },
    { id: "21", label: "relocation support" },
    { id: "22", label: "psychological counseling" },
    {
      id: "23",
      label:
        "co-financing or financing of education that is not necessary for the succeeding on the job",
    },
    { id: "24", label: "team budgets for teambuliding activities" },
  ],
  sr: [
    { id: "1", label: "Službeni automobil u privatne svrhe" },
    { id: "2", label: "Parking mesto" },
    { id: "3", label: "Dopunsko zdravstveno osiguranje" },
    { id: "4", label: "Dodatno zdravstveno osiguranje" },
    { id: "5", label: "Životno osiguranje" },
    {
      id: "6",
      label:
        "Izdvajanje poslodavca u dodatno penziono osiguranje za pojedinog radnika",
    },
    { id: "7", label: "Osiguranje od nezgoda (van zakonskog minimuma)" },
    { id: "8", label: "Sistematski pregledi (van zakonski propisanih)" },
    {
      id: "9",
      label: "Korišćenje mobilnog uređaja i deo tarife u privatne svrhe",
    },
    {
      id: "10",
      label:
        "Dodatni slobodni dani / dani godišnjeg (iznad zakonskog minimuma)",
    },
    { id: "11", label: "Plaćeno odsustvo (na primer za dodatne edukacije)" },
    { id: "12", label: "Povoljniji krediti u banci" },
    { id: "13", label: "Beneficirani obroci" },
    {
      id: "14",
      label: "Osveženje (voće, kafa, sokovi, čajevi, grickalice...)",
    },
    {
      id: "15",
      label:
        "Small incentive nagrade (bioskopske ulaznice, poklon-bonovi za parfimerije, knjižare, rođendanski poklon...)",
    },
    { id: "16", label: "Subvencionisane sportske aktivnosti" },
    { id: "17", label: "Organizovane sportske grupe unutar kompanije" },
    {
      id: "18",
      label:
        "Proizvodi firme pod povoljnijim uslovima odnosno popusti za zaposlene na usluge kompanije",
    },
    { id: "19", label: "Popusti na usluge partnera" },
    { id: "20", label: "Dečji vrtić (korporativni vrtić)" },
    { id: "21", label: "Podrška u preseljenju" },
    { id: "22", label: "Psihološko savetovanje" },
    {
      id: "23",
      label:
        "Sufinansiranje ili finansiranje školovanja koje nije neophodno za obavljanje posla",
    },
    { id: "24", label: "Timski budžeti za teambuliding aktivnosti" },
  ],
};

export default class QuestionBenefits extends Component {
  constructor(props) {
    super(props);

    let data = {};
    let previousData = {};

    try {
      data = JSON.parse(this.props.data.value);
    } catch (error) {}

    try {
      previousData = JSON.parse(this.props.previousData.value);
    } catch (error) {}

    this.state = {
      data: data,
      previousData: previousData,
      listMonetary: monetary[getLanguage()],
      listNonMonetary: nonMonetary[getLanguage()],
      messages: translations[getLanguage()],
    };
  }

  _inputOnChange = (event) => {
    let data = this.state.data;
    data[event.target.name] = event.target.value;

    this.setState({
      data: data,
    });

    this.props.onChange({
      target: {
        name: this.props.name,
        value: JSON.stringify(this.state.data),
        dataset: {
          id: this.props.id,
          question: this.props.question,
        },
      },
    });
  };

  render() {
    const { copied } = this.props;

    const { data, listMonetary, listNonMonetary, messages, previousData } =
      this.state;

    return (
      <div>
        <table className="table">
          <thead>
            <tr>
              <th>{messages.benefitsMonetary}</th>
              <th>{messages.employeePercentage}</th>
              <th>{messages.employeeGroup}</th>
            </tr>
          </thead>
          <tbody>
            {listMonetary.map((item, key) => (
              <tr key={key}>
                <td>{item.label}</td>
                <td style={{ whiteSpace: "nowrap" }}>
                  <NumberInput
                    id={item.id}
                    question={item.id}
                    type="percentage"
                    label={""}
                    min={0}
                    max={100}
                    name={`input_monetary_percentage_${item.id}`}
                    value={
                      data[`input_monetary_percentage_${item.id}`]
                        ? data[`input_monetary_percentage_${item.id}`]
                        : ""
                    }
                    previousValue={
                      previousData[`input_monetary_percentage_${item.id}`]
                        ? previousData[`input_monetary_percentage_${item.id}`]
                        : ""
                    }
                    copied={copied}
                    onChange={this._inputOnChange}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    name={`input_monetary_info_${item.id}`}
                    value={
                      data[`input_monetary_info_${item.id}`]
                        ? data[`input_monetary_info_${item.id}`]
                        : ""
                    }
                    className="input"
                    onChange={this._inputOnChange}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <table className="table">
          <thead>
            <tr>
              <th>{messages.benefitsNonMonetary}</th>
              <th>{messages.employeePercentage}</th>
              <th>{messages.employeeGroup}</th>
            </tr>
          </thead>
          <tbody>
            {listNonMonetary.map((item, key) => (
              <tr key={key}>
                <td>{item.label}</td>
                <td style={{ whiteSpace: "nowrap" }}>
                  <NumberInput
                    id={item.id}
                    question={item.id}
                    type="percentage"
                    label={""}
                    min={0}
                    max={100}
                    name={`input_nonmonetary_percentage_${item.id}`}
                    value={
                      data[`input_nonmonetary_percentage_${item.id}`]
                        ? data[`input_nonmonetary_percentage_${item.id}`]
                        : ""
                    }
                    previousValue={
                      previousData[`input_nonmonetary_percentage_${item.id}`]
                        ? previousData[
                            `input_nonmonetary_percentage_${item.id}`
                          ]
                        : ""
                    }
                    copied={copied}
                    onChange={this._inputOnChange}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    name={`input_nonmonetary_info_${item.id}`}
                    value={
                      data[`input_nonmonetary_info_${item.id}`]
                        ? data[`input_nonmonetary_info_${item.id}`]
                        : ""
                    }
                    className="input"
                    onChange={this._inputOnChange}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  }
}
