import React, { Component } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Loader } from "../components";
import API from "../api";
import { getLanguage } from "../utils/utils";

const translations = {
  hr: {
    introQuestions: "Uvodna pitanja",
    biggestChallenges: "Što su vam u protekloj godini bili najveći izazovi?",
    mainFocus: "Što je HR odjelu u protekloj godini bio glavni fokus?",
    previousPeriod:
      "Iz perspektive biznisa, koji su događaji obilježili prethodni period?",
    proudOf: "Na što ste najviše ponosni?",
    changesSaved: "Promjene su uspješno spremljene!",
    changesError: "Došlo je do greške. Molimo pokušajte ponovo.",
    submit: "Spremi",
  },
  en: {
    introQuestions: "Intro Questions",
    biggestChallenges:
      "What have been your biggest challenges in the past year?",
    mainFocus:
      "What has been the main focus of the HR department in the past year?",
    previousPeriod:
      "From a business perspective, what events marked the previous period?",
    proudOf: "What are you most proud of?",
    changesSaved: "The changes were successfully saved!",
    changesError: "There was an error. Please try again.",
    submit: "Save",
  },
  sr: {
    introQuestions: "Uvodna pitanja",
    biggestChallenges: "Koji su bili vaši najveći izazovi u protekloj godini?",
    mainFocus:
      "Koji je bio glavni fokus sektora za ljudske resurse u protekloj godini?",
    previousPeriod:
      "Iz biznis perspektive, koji događaji su obeležili prethodni period?",
    proudOf: "Na šta ste najponosniji?",
    changesSaved: "Promene su uspešno spremljene!",
    changesSachangesErrorved: "Došlo je do greške. Molimo pokušajte ponovo.",
    submit: "Prihvati",
  },
};

export default class IntroQuestions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: props.match.params.id,
      loading: true,
      info: null,
      types: [],
      messages: translations[getLanguage()],
      saveInterval: null,
    };
  }

  componentDidMount() {
    this._loadData();
  }

  _formOnSubmit = async (event) => {
    event.preventDefault();
    await this._saveInfo();
    toast(this.state.messages.changesSaved, {
      type: "success",
    });
  };

  _saveInfo = async () => {
    let info = this.state.info;

    await API.post("", {
      action: "infoAdditionalSave",
      info: info,
      id: this.state.id,
    })
      .then((response) => {})
      .catch((error) => {
        toast(this.state.messages.changesError, {
          type: "error",
        });
      });
  };

  _inputOnChange = (event) => {
    if (this.state.saveInterval) {
      clearTimeout(this.state.saveInterval);
    }

    let info = this.state.info;
    info[event.target.name] = event.target.value;

    this.setState({
      info: info,
      saveInterval: setTimeout(this._saveInfo, 2000),
    });
  };

  _loadData = async () => {
    await API.get("", {
      params: {
        action: "infoAdditional",
        id: this.state.id,
      },
    })
      .then((response) => {
        this.setState({
          info: response.data,
          loading: false,
        });
      })
      .catch((error) => {});
  };

  render() {
    const { loading, info, messages } = this.state;

    if (loading) {
      return <Loader />;
    }

    return (
      <div className="page__content">
        <article className="article">
          <h1>{messages.introQuestions}</h1>
          <form method="post" onSubmit={this._formOnSubmit}>
            <fieldset className="fieldset">
              <label className="label">{messages.biggestChallenges}</label>
              <textarea
                type="text"
                name="biggestChallenges"
                value={info.biggestChallenges}
                className="input input-area input--medium"
                onChange={this._inputOnChange}
              />
            </fieldset>
            <fieldset className="fieldset">
              <label className="label">{messages.mainFocus}</label>
              <textarea
                type="text"
                name="mainFocus"
                value={info.mainFocus}
                className="input input-area input--medium"
                onChange={this._inputOnChange}
              />
            </fieldset>
            <fieldset className="fieldset">
              <label className="label">{messages.previousPeriod}</label>
              <textarea
                type="text"
                name="previousPeriod"
                value={info.previousPeriod}
                className="input input-area input--medium"
                onChange={this._inputOnChange}
              />
            </fieldset>
            <fieldset className="fieldset">
              <label className="label">{messages.proudOf}</label>
              <textarea
                type="text"
                name="proudOf"
                value={info.proudOf}
                className="input input-area input--medium"
                onChange={this._inputOnChange}
              />
            </fieldset>
            <fieldset className="fieldset fieldset--button">
              <button type="submit" className="button button--big">
                {messages.submit}
              </button>
            </fieldset>
          </form>
        </article>
        <ToastContainer />
      </div>
    );
  }
}
