import React, { Component } from "react";
import ReportBadge from "./ReportBadge";

export default class ReportOveral extends Component {
  render() {
    return (
      <div className="report__page__wrapper" id="page-result">
        <div className="report__page report_page--split_11">
          <div className="report__info report__section--padding">
            <h2>{this.props.title}</h2>
            <h3>{this.props.subtitle}</h3>
            <div dangerouslySetInnerHTML={{ __html: this.props.description }} />
          </div>
          <div
            className="report__chart"
            style={{
              alignItems: "flex-start",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            {this.props.children}
          </div>
        </div>
        <ReportBadge />
      </div>
    );
  }
}
