import React, { Component } from "react";
import classNames from "classnames";

export default class RateBox extends Component {
  constructor(props) {
    super(props);

    this.state = {
      grade: props.grade,
    };
  }

  _onClick = async (event) => {
    const entityId = parseInt(this.props.entityId, 10);
    let grade = parseFloat(event.target.dataset.grade, 1);

    if (this.state.grade === grade) {
      grade = undefined;
    }

    this.setState({
      grade: grade,
    });

    await this.props.onClick(entityId, grade);
  };

  render() {
    const { grade } = this.state;

    const { disabled, entityId, title } = this.props;

    const gradeLabels = [0, 0.5, 1, 1.5, 2, 2.5, 3];

    return (
      <div className={classNames("rate", this.props.additionalClass)}>
        <div className="rate__main">
          <div className="rate__content">{title}:</div>
          <div className="rate__grade">
            {gradeLabels.map((gradeLabel, gradeIndex) => (
              <button
                onClick={disabled ? null : this._onClick}
                key={gradeIndex}
                className={classNames(
                  "button",
                  "button--grade",
                  { "button--grade--disabled": disabled },
                  { "button--light": grade !== gradeLabel }
                )}
                data-grade={gradeLabel}
                data-area={entityId}
              >
                {gradeLabel}
              </button>
            ))}
          </div>
          {this.props.children}
        </div>
      </div>
    );
  }
}
