import React, { Component } from "react";
const classNames = require("classnames");

export default class Tabs extends Component {
  constructor(props) {
    super(props);

    this.state = {
      items: this.props.items,
    };
  }

  _onTabClick = (event) => {
    this.props.onClick(event);
  };

  render() {
    const { items } = this.state;
    const { active } = this.props;

    console.log(items);

    return (
      <div className="tabs">
        {items.map((item, key) => (
          <span
            key={key}
            className={classNames(
              "tab",
              { " tab--active": active === key },
              { " tab--disabled": item.disabled }
            )}
            data-tab={key}
            data-link={item.link}
            onClick={item.disabled ? null : this._onTabClick}
          >
            {item.title}
          </span>
        ))}
      </div>
    );
  }
}
