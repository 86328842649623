import React, { Component } from "react";
import { getLanguage } from "../utils/utils";

const translations = {
  hr: {
    cancel: "Odustani",
    save: "Spremi",
    enterYourComments: "Upišite svoj komentar",
  },
  en: {
    cancel: "Cancel",
    save: "Save",
    enterYourComments: "Add your comments",
  },
  sr: {
    cancel: "Odustani",
    save: "Spremi",
    enterYourComments: "Upišite svoj komentar",
  },
};

export default class CommentEdit extends Component {
  constructor(props) {
    super(props);

    this.state = {
      entityId: props.entityId,
      comment: props.comment,
      messages: translations[getLanguage()],
    };
  }

  _onChange = (event) => {
    this.setState({
      comment: event.target.value,
    });
  };

  _onSave = async () => {
    await this.props.onSave(this.state.entityId, this.state.comment);
  };

  _onCancel = async () => {
    await this.props.onCancel(this.state.entityId);
  };

  render() {
    const { comment, entityId, messages } = this.state;

    return (
      <div className="comment">
        <div className="comment__input__box">
          <textarea
            className="answer__input"
            data-id={entityId}
            placeholder={messages.enterYourComments}
            onChange={this._onChange}
            value={comment ? comment : ""}
          ></textarea>
        </div>
        <div className="comment__save">
          <button data-id={entityId} className="button" onClick={this._onSave}>
            {messages.save}
          </button>
          {` `}
          <button
            data-id={entityId}
            className="button button--light"
            onClick={this._onCancel}
          >
            {messages.cancel}
          </button>
        </div>
      </div>
    );
  }
}
