import React, { Component } from "react";
import { getLanguage } from "../utils/utils";

export default class About extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isFlipped: false,
    };
  }

  _imageFlip = () => {
    this.setState({
      isFlipped: !this.state.isFlipped,
    });
  };

  render() {
    return (
      <div className="page__content">
        <article className="article">
          {getLanguage() === "hr" && (
            <>
              <h1>Certifikat Poslodavac Partner</h1>
              <p>
                Certifikat Poslodavac Partner za izvrsnost u upravljanju
                ljudskim resursima, dodjeljuje se onim organizacijama koje
                zadovolje unaprijed postavljene standarde, a na osnovu bodovanja
                različitih faza koje zaposlenici prolaze unutar organizacije.
              </p>
            </>
          )}

          {getLanguage() === "en" && (
            <>
              <h1>About Certificate Employer Partner</h1>
              <p>
                The Employer Partner Certificate, for excellence in Human
                Resource Management, is awarded to organizations which comply
                with the Human Resource Management quality standards, based on
                scoring of the different phases that employees experience within
                the organization.
              </p>
            </>
          )}

          {getLanguage() === "sr" && (
            <>
              <h1>Sertifikat Poslodavac Partner</h1>
              <p>
                Sertifikat Poslodavac Partner za izuzetnost u upravljanju
                ljudskim resursima, dodeljuje se onim organizacijama koje
                zadovolje unapred postavljene standarde, a na osnovu bodovanja
                različitih faza koje zaposleni prolaze unutar organizacije.
              </p>
            </>
          )}

          <p style={{ textAlign: "center" }}>
            <img
              style={{ maxWidth: "800px" }}
              alt=""
              src={`/assets/images/info/info-em-${getLanguage()}.png`}
            />
          </p>
          <p style={{ textAlign: "center" }}>
            <img
              style={{ maxWidth: "800px" }}
              alt=""
              src={`/assets/images/info/info-ee-${getLanguage()}.png`}
            />
          </p>
        </article>
      </div>
    );
  }
}
