import React, { Component } from "react";
import { NumberInput } from "../components";
import { getLanguage } from "../utils/utils";

const translations = {
  hr: {
    record: "Evidencija",
    previousData: "Podatak za prethodnu godinu",
    currentData: "Aktualni podatak",
    womanPercentage: "Koji je postotak žena u organizaciji?",
    womanManagerPercentage: "Koji je postotak žena na rukovodećim pozicijama?",
    womanTalentPoolPercentage: "Koji je postotak žena u talent poolu?",
    averageAge: "Koja je prosječna dob zaposlenika u organizaciji?",
    disabilityPercentage:
      "Koji je postotak osoba s invaliditetom u organizaciji?",
    disabilityManagerPercentage:
      "Koliki je postotak osoba s invaliditetom na rukovodećim pozicijama?",
  },
  en: {
    record: "Records",
    previousData: "Previous Year",
    currentData: "Current Year",
    womanPercentage: "What is the percentage of women in the organisation?",
    womanManagerPercentage:
      "What percentage of women are in management positions?",
    womanTalentPoolPercentage:
      "What is the percentage of women in the talent pool?",
    averageAge: "What is the average age of employees in the organisation?",
    disabilityPercentage:
      "What is the percentage of people with disabilities in the organisation?",
    disabilityManagerPercentage:
      "What is the percentage of people with disabilities in management positions?",
  },
  sr: {
    record: "Evidencija",
    previousData: "Podatak za prethodnu godinu",
    currentData: "Aktualni podatak",
    womanPercentage: "Koji je postotak žena u organizaciji?",
    womanManagerPercentage: "Koji je postotak žena na rukovodećim pozicijama?",
    womanTalentPoolPercentage: "Koji je postotak žena u talent poolu?",
    averageAge: "Koja je prosečna dob zaposlenih u organizaciji?",
    disabilityPercentage:
      "Koji je postotak osoba s invaliditetom u organizaciji?",
    disabilityManagerPercentage:
      "Koliki je postotak osoba s invaliditetom na rukovodećim pozicijama?",
  },
};

export default class QuestionDiversity extends Component {
  constructor(props) {
    super(props);

    let data = {};
    let previousData = {};

    try {
      data = JSON.parse(this.props.data.value);
    } catch (error) {}

    try {
      previousData = JSON.parse(this.props.previousData.value);
    } catch (error) {}

    this.state = {
      data: data,
      previousData: previousData,
      messages: translations[getLanguage()],
    };
  }

  _inputOnChange = (event) => {
    let data = this.state.data;
    data[event.target.name] = event.target.value;

    this.setState({
      data: data,
    });

    this.props.onChange({
      target: {
        name: this.props.name,
        value: JSON.stringify(this.state.data),
        dataset: {
          id: this.props.id,
          question: this.props.question,
        },
      },
    });
  };

  render() {
    const { copied } = this.props;

    const { data, messages, previousData } = this.state;

    return (
      <div>
        <table className="table">
          <thead>
            <tr>
              <th>{messages.record}</th>
              <th>{messages.previousData}</th>
              <th>{messages.currentData}</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{messages.womanPercentage}</td>
              <td style={{ whiteSpace: "nowrap" }}>
                <NumberInput
                  type="percentage"
                  label={""}
                  min={0}
                  max={100}
                  name="female_past"
                  value={data["female_past"] ? data["female_past"] : ""}
                  previousValue={
                    previousData["female_past"]
                      ? previousData["female_past"]
                      : ""
                  }
                  copied={copied}
                  onChange={this._inputOnChange}
                />
              </td>
              <td>
                <NumberInput
                  type="percentage"
                  label={""}
                  min={0}
                  max={100}
                  name="female_current"
                  value={data["female_current"] ? data["female_current"] : ""}
                  previousValue={
                    previousData["female_current"]
                      ? previousData["female_current"]
                      : ""
                  }
                  copied={copied}
                  onChange={this._inputOnChange}
                />
              </td>
            </tr>
            <tr>
              <td>{messages.womanManagerPercentage}</td>
              <td style={{ whiteSpace: "nowrap" }}>
                <NumberInput
                  type="percentage"
                  label={""}
                  min={0}
                  max={100}
                  name="female_manager_past"
                  value={
                    data["female_manager_past"]
                      ? data["female_manager_past"]
                      : ""
                  }
                  previousValue={
                    previousData["female_manager_past"]
                      ? previousData["female_manager_past"]
                      : ""
                  }
                  copied={copied}
                  onChange={this._inputOnChange}
                />
              </td>
              <td>
                <NumberInput
                  type="percentage"
                  label={""}
                  min={0}
                  max={100}
                  name="female_manager_current"
                  value={
                    data["female_manager_current"]
                      ? data["female_manager_current"]
                      : ""
                  }
                  previousValue={
                    previousData["female_manager_current"]
                      ? previousData["female_manager_current"]
                      : ""
                  }
                  copied={copied}
                  onChange={this._inputOnChange}
                />
              </td>
            </tr>
            <tr>
              <td>{messages.womanTalentPoolPercentage}</td>
              <td style={{ whiteSpace: "nowrap" }}>
                <NumberInput
                  type="percentage"
                  label={""}
                  min={0}
                  max={100}
                  name="talent_pool_past"
                  value={
                    data["talent_pool_past"] ? data["talent_pool_past"] : ""
                  }
                  previousValue={
                    previousData["talent_pool_past"]
                      ? previousData["talent_pool_past"]
                      : ""
                  }
                  copied={copied}
                  onChange={this._inputOnChange}
                />
              </td>
              <td>
                <NumberInput
                  type="percentage"
                  label={""}
                  min={0}
                  max={100}
                  name="talent_pool_current"
                  value={
                    data["talent_pool_current"]
                      ? data["talent_pool_current"]
                      : ""
                  }
                  previousValue={
                    previousData["talent_pool_current"]
                      ? previousData["talent_pool_current"]
                      : ""
                  }
                  copied={copied}
                  onChange={this._inputOnChange}
                />
              </td>
            </tr>
            <tr>
              <td>{messages.averageAge}</td>
              <td style={{ whiteSpace: "nowrap" }}>
                <NumberInput
                  type="number"
                  label={""}
                  min={0}
                  max={100}
                  name="age_past"
                  value={data["age_past"] ? data["age_past"] : ""}
                  previousValue={
                    previousData["age_past"] ? previousData["age_past"] : ""
                  }
                  copied={copied}
                  onChange={this._inputOnChange}
                />
              </td>
              <td>
                <NumberInput
                  type="number"
                  label={""}
                  min={0}
                  max={100}
                  name="age_current"
                  value={data["age_current"] ? data["age_current"] : ""}
                  previousValue={
                    previousData["age_current"]
                      ? previousData["age_current"]
                      : ""
                  }
                  copied={copied}
                  onChange={this._inputOnChange}
                />
              </td>
            </tr>
            <tr>
              <td>{messages.disabilityPercentage}</td>
              <td style={{ whiteSpace: "nowrap" }}>
                <NumberInput
                  type="percentage"
                  label={""}
                  min={0}
                  max={100}
                  name="disability_past"
                  value={data["disability_past"] ? data["disability_past"] : ""}
                  previousValue={
                    previousData["disability_past"]
                      ? previousData["disability_past"]
                      : ""
                  }
                  copied={copied}
                  onChange={this._inputOnChange}
                />
              </td>
              <td>
                <NumberInput
                  type="percentage"
                  label={""}
                  min={0}
                  max={100}
                  name="disability_current"
                  value={
                    data["disability_current"] ? data["disability_current"] : ""
                  }
                  previousValue={
                    previousData["disability_current"]
                      ? previousData["disability_current"]
                      : ""
                  }
                  copied={copied}
                  onChange={this._inputOnChange}
                />
              </td>
            </tr>
            <tr>
              <td>{messages.disabilityManagerPercentage}</td>
              <td style={{ whiteSpace: "nowrap" }}>
                <NumberInput
                  type="percentage"
                  label={""}
                  min={0}
                  max={100}
                  name="disability_manager_past"
                  value={
                    data["disability_manager_past"]
                      ? data["disability_manager_past"]
                      : ""
                  }
                  previousValue={
                    previousData["disability_manager_past"]
                      ? previousData["disability_manager_past"]
                      : ""
                  }
                  copied={copied}
                  onChange={this._inputOnChange}
                />
              </td>
              <td>
                <NumberInput
                  type="percentage"
                  label={""}
                  min={0}
                  max={100}
                  name="disability_manager_current"
                  value={
                    data["disability_manager_current"]
                      ? data["disability_manager_current"]
                      : ""
                  }
                  previousValue={
                    previousData["disability_manager_current"]
                      ? previousData["disability_manager_current"]
                      : ""
                  }
                  copied={copied}
                  onChange={this._inputOnChange}
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  }
}
