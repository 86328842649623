import React, { Component } from 'react';
import {
  Menu,
} from '../components';

class QuestionnaireLayout extends Component {
  render() {
    const { id } = this.props.match.params;

    return (
      <div className="page__wrapper">
        <Menu id={id}/>
        {this.props.children}
      </div>
    )
  }
}
export default QuestionnaireLayout;