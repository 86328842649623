import React, { Component } from "react";
import { ReportCircleChart } from "./components";
import ReportBadge from "./ReportBadge";

export default class ReportCategoryChart extends Component {
  render() {
    const { title, previousValue, value } = this.props;

    return (
      <div className="report__page__wrapper">
        <div className="report__page">
          <div className="report_category__side">
            <div>
              <ReportCircleChart
                previousValue={previousValue}
                value={value}
                size={120}
                stroke={20}
              />
              <h2>{title}</h2>
            </div>
          </div>
          <div className="report_category__content">{this.props.children}</div>
        </div>
        <ReportBadge />
      </div>
    );
  }
}
