import React, { Component } from "react";
import classnames from "classnames";
import ReportBadge from "./ReportBadge";

import ReactQuill from "react-quill";
import "react-quill/dist/quill.bubble.css";

const editorModules = {
  syntax: false,
  toolbar: [
    ["bold", "italic", "underline"],
    [{ size: ["small", false, "large", "huge"] }],
    [{ list: "ordered" }, { list: "bullet" }],
    ["clean"],
  ],
};

export default class ReportHightlights extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentContent: this.props.value ? this.props.value : "",
    };
  }

  shouldComponentUpdate(nextProps, nextState) {
    return JSON.stringify(this.state) !== JSON.stringify(nextState);
  }

  _inputOnChange = (value) => {
    this.props.onChange({
      target: {
        name: this.props.name,
        value: value,
      },
    });

    this.setState({
      currentContent: value,
    });
  };

  _suggestionOnClick = (event) => {
    let current = this.state.currentContent;
    const adding = event.currentTarget.innerHTML;

    if (!current || current.indexOf("<ul>") === -1) {
      current = (current ? current : "") + `<ul><li>${adding}</li></ul>`;
    } else {
      current = current.replace("</ul>", `<li>${adding}</li></ul>`);
    }

    this.setState({
      currentContent: current,
    });
  };

  render() {
    const { id, content, editable, suggestions, title, type } = this.props;

    const { currentContent } = this.state;

    return (
      <div className="report__page__wrapper" id={id}>
        <div className="report__page">
          <div
            className={classnames("report_highlights", {
              "report_hightlights--dimmed": type === "dimmed",
            })}
          >
            <div className="report_highlights__list">
              <ReactQuill
                readOnly={!editable}
                className="report__input"
                theme="bubble"
                modules={editorModules}
                value={currentContent}
                defaultValue={content}
                onChange={this._inputOnChange}
              />
            </div>
            <div className="report_highlights__heading">
              <h2 dangerouslySetInnerHTML={{ __html: title }} />
            </div>
          </div>
        </div>
        <div className="report__options">
          {suggestions.map((item, key) => (
            <div key={key} className="report__options__checkitem">
              <p
                onClick={editable ? this._suggestionOnClick : null}
                dangerouslySetInnerHTML={{ __html: item.content }}
              />
            </div>
          ))}
        </div>
        <ReportBadge light={true} />
      </div>
    );
  }
}
