import React, { Component } from "react";
import moment from "moment";
import "moment/locale/hr";
import "moment/locale/sr";
import { getLanguage } from "../../utils/utils";

moment.locale(getLanguage());

const translations = {
  hr: {
    title: "<strong>Employer Partner<br/> Finalno izvješće</strong>",
    resultsWillBeShown: "Rezultati su prikazani za:",
    statusCertified: "Status organizacije: Certificirana",
    certifiedInTheYear: "Godina certifikacije:",
    auditTitle:
      "<strong>Employer Partner</strong><br/> Strateški razvoj HR procesa",
    auditResults: "Izvještaj i rezultati za:",
  },
  en: {
    title: "<strong>Employer Partner<br/> Final Report</strong>",
    resultsWillBeShown: "Results will be shown for:",
    statusCertified: "Status: Certificated",
    certifiedInTheYear: "Certificated in the year:",
    auditTitle:
      "<strong>Employer Partner</strong><br/> Strategic HR processes<br/> development",
    auditResults: "Report and results for:",
  },
  sr: {
    title: "<strong>Employer Partner<br/> Finalni izveštaj</strong>",
    resultsWillBeShown: "Rezultati su prikazani za:",
    statusCertified: "Status organizacije: Sertifikovana",
    certifiedInTheYear: "Godina sertifikacije:",
    auditTitle:
      "<strong>Employer Partner</strong><br/> Strateški razvoj HR procesa",
    auditResults: "Izveštaj i rezultati za:",
  },
};

export default class ReportCover extends Component {
  constructor(props) {
    super(props);

    this.state = {
      categories: [],
      messages: translations[getLanguage()],
    };
  }

  shouldComponentUpdate(nextProps) {
    return false;
  }

  render() {
    const { content, editable, info, onChange } = this.props;
    const { messages } = this.state;

    return (
      <div className="report__page__wrapper">
        <div className="report__page report_page--cover">
          <div className="report__section--padding">
            <h1
              style={{ fontWeight: "normal" }}
              dangerouslySetInnerHTML={{
                __html: info.passed ? messages.title : messages.auditTitle,
              }}
            />
            <p style={{ marginBottom: 0 }}>
              {info.passed
                ? messages.resultsWillBeShown
                : messages.auditResults}
            </p>
            <h2 style={{ maxWidth: "360px" }}>{info.name}</h2>
            {!info.passed && (
              <p>
                <strong
                  onInput={(event) =>
                    onChange({
                      target: {
                        name: "reportDate",
                        value: event.currentTarget.textContent,
                      },
                    })
                  }
                  contentEditable={editable}
                  suppressContentEditableWarning={true}
                >
                  {content.reportDate
                    ? content.reportDate
                    : moment(info.date, "YYYY-MM-DD").format("LL")}
                </strong>
              </p>
            )}
            {info.passed && (
              <p>
                <strong>{messages.statusCertified}</strong>
                <br />
                {messages.certifiedInTheYear} {info.year}
              </p>
            )}
          </div>
        </div>
      </div>
    );
  }
}
