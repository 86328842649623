import React, { Component } from 'react';
import DropzoneS3Uploader from 'react-dropzone-s3-uploader';
import { config } from '../config';

export default class FileUpload extends Component {
  constructor(props) {
    super(props);

    this.state = {
      fileName: this.props.fileName,
      fileUrl: ''
    }
  }

  _uploadOnFinish = (info) => {
    let fileName = info.signedUrl.split('?').splice(-2, 1)[0].split('/').pop();

    this.setState({
      fileName: fileName,
      fileUrl: this.props.module + '/' + fileName
    });

    this._onInputChange({
      target: {
        entityType: this.props.entityType,
        entityId: this.props.entityId,
        name: this.props.name,
        value: fileName,
        file: {
          name: info.file.name,
          type: info.file.type,
        }
      }
    });
  }

  _onInputChange = async (event) => {
    this.props.onChange(event);
  }

  render() {
    const { module } = this.props;
    const { fileUrl } = this.state;

    const uploadOptions = {
      server: config.apiUrl,
      signingUrl: '',
      signingUrlQueryParams: {
        action: 'mediaGetSignedUrl',
        module: module,
      },
    }

    return (
      <DropzoneS3Uploader
        onFinish={this._uploadOnFinish}
        s3Url={config.s3Url}
        upload={uploadOptions}
        filename={fileUrl}
      >
        <UploadDisplay
          cta={this.props.cta}
          uploading={this.props.uploading}
        />
      </DropzoneS3Uploader>
    );
  }
}

class UploadDisplay extends React.Component {
  
  renderFileUpload = (uploadedFile, i) => {
    const {
      file,
    } = uploadedFile
 
    return (
      <div key={i}>
        <p>{file.name}</p>
      </div>
    )
  }
 
  render() {
    const {
      cta,
      uploading,
      progress
    } = this.props

    if (progress !== null && progress < 100) {
      return uploading;
    }
    
    return (
      <div>
        {cta}
      </div>
    )
  }
}