import React, { Component } from 'react';
import { getLanguage } from '../utils/utils';

export default class Instructions extends Component {
  render() {
    return <div className="page__content">
      <article className="article">
        {getLanguage() === 'hr' &&
          <>
            <h1>Upute za ispunjavanje upitnika</h1>
            <p>Prije početka ispunjavanja upitnika, otvorite dio „<strong>Podaci o organizaciji</strong>“ te popunite podatke koji nedostaju.</p>
            <p>Upitnik je podijeljen na <strong>7 područja i 30 tema</strong>. Svaka tema sadrži nekoliko pitanja na koja ćemo Vas zamoliti da date odgovor koji najbolje odgovara stanju u organizaciji.</p>
            <p>Uneseni odgovori <u>automatski se spremaju</u> te ih je <u>moguće mijenjati</u> sve do konačnog završetka ispunjavanja upitnika. Popunjeno pitanje će dobiti zelenu boju te je na taj način lako pratiti na koja ste pitanja dali odgovor, a koja su još neodgovorena. </p>
            <p>Upitnik <u>nije</u> potrebno riješiti odjednom već se njemu možete vraćati sve dok ne zaključite upitnik i pošaljete konačne odgovore.</p>
            <p>Kada se u upitniku govori o <strong>zaposlenicima</strong>, uvijek se misli na <strong>aktivne zaposlenike</strong>, odnosno aktivni HC (Head Count) – broj ugovora o radu umanjen za dugotrajna bolovanja (iznad 42 dana), porodiljni i rodiljni dopust, bolovanje zbog komplikacije u trudnoći i izaslane radnike.</p>
            <p>Važno je da prilikom ispunjavanja obratite pažnju na vremensko razdoblje na koje se pitanje odnosi – nekada je riječ o periodu od zadnjih <strong>godinu dana</strong>, a nekada zadnje <strong>dvije godine</strong>. </p>
            <p>Ako se pitanje odnosi na menadžere, obratite pažnju je li riječ o <strong>svim</strong> menadžerima ili samo o <strong>novim</strong> menadžerima. </p>
            <p>U upitniku koristimo <strong>nekoliko vrsta pitanja i ponuđenih odgovora:</strong></p>
            <ul><li>Pitanja na koja je moguće odgovoriti <u>samo jednim</u> od ponuđenih odgovora (odgovori su označeni kružićima i velikim tiskanim slovima)
            <br/><img style={{ maxWidth: '60px', display: 'none' }} alt="" src="/assets/content/01.png"/></li><li>Pitanja na koja je moguće označiti <u>više</u> ponuđenih odgovora (odgovori su označeni kvadratićima i brojevima)<br/><img style={{ maxWidth: '60px', display: 'none' }} alt="" src="/assets/content/02.png"/></li><li>Pitanja koja za odgovor traže unošenje <u>postotka</u>, <u>broja</u> ili <u>valute</u> (ponuđeno je polje za unošenje odgovora te naznaka koja vrsta odgovora se traži)<br/><img style={{ maxWidth: '260px', display: 'none' }} alt="" src="/assets/content/03.png"/></li><li>Pitanja koja uz svaki ponuđeni odgovor traže unošenje dodatnih informacija (odgovori su označeni rimskim brojevima te postoji polje za unošenje dodatnih informacija)<br/><img style={{ maxWidth: '300px', display: 'none' }} alt="" src="/assets/content/04.png"/></li></ul>
            <p>Uz određena pitanja predviđena je mogućnost prilaganja relevantnih dokumenata ili prostor za upisivanja duljeg komentara. Ta mogućnost bit će jasno naznačena pored pitanja.</p>
            <p style={{ display: 'none' }}>
              <img style={{ maxWidth: '140px' }} alt="" src="/assets/content/05.png"/>
              <img style={{ maxWidth: '400px' }} alt="" src="/assets/content/06.png"/>
            </p>
            <p>Neka pitanja nude mogućnost otvaranja 'oblačića' s dodatnim pojašnjenjem određenih pojmova korištenih u pitanju ili u ponuđenim odgovorima. To će biti naznačeno kroz znak za info te će se 'oblačić' otvarati prelaskom miša preko teksta pitanja ili tog znaka.</p>
            <p style={{ display: 'none' }}><img style={{ maxWidth: '50px' }} alt="" src="/assets/content/07.png"/></p>
            <p>Nakon zadnjeg pitanja unutar svake teme postoji prostor za prilaganje dodatne dokumentacije i prostor za upisivanje dodatnog komentara, ako je potrebno. Tu možete priložiti svu dodatnu dokumentaciju vezanu za tu temu koju niste imali prilike priložiti ranije. Možete upisati i bilo kakve dodatne informacije za koje smatrate da su relevantne, a nisu pokrivene kroz pitanja. Također, u slučaju da se na nekim pitanjima dogodi da nijedan od ponuđenih odgovora ne opisuje dobro situaciju u kompaniji, u ovom polju možete to detaljnije pojasniti.</p>
            <p style={{ display: 'none' }}><img style={{ maxWidth: '600px' }} alt="" src="/assets/content/08.png"/></p>
          </>
        }

        {getLanguage() === 'en' &&
          <>
            <h1>Instructions for filling out the questionnaire</h1>
            <p>Before you start filling out the questionnaire, please open the “Organization Information” section and fill in the missing information.</p>
            <p>The questionnaire is divided into 7 areas and 30 topics. Each topic contains several questions. We will ask you to give an answer that best suits the situation in your organization.</p>
            <p>Your answers are automatically saved and can be changed until the whole questionnaire is completed. The completed question will turn green, making it easy to keep track of questions you have answered and the one which are still unanswered.</p>
            <p>You don’t need to complete the questionnaire at once. You can return to filling out the questionnaire until you finalize it and send the final answers.</p>
            <p>When the questionnaire mentions <strong>employees</strong>, we always refer to <strong>active employees</strong>, i.e. active HC (Head Count) – number of employment contracts minus the number of long-term sick leaves (more than 42 days), maternity or parental leaves, sick leaves due to pregnancy complications and expats.</p>
            <p>It is important that when filling in the questionnaire, you pay attention to the time period to which the question is referring to – sometimes the question refers to last year and sometimes to last two years.</p>
            <p>If the question refers to managers, please pay attention whether it refers to all managers or just new managers.</p>
            <p>In the questionnaire we use several types of questions and answers:</p>
            <ul><li>Questions that can be answered with only one of the answers suggested (those answers are marked with circles and capital letters)
            <br/><img style={{ maxWidth: '60px', display: 'none' }} alt="" src="/assets/content/01.png"/></li><li>Multiple answer questions (those answers are marked with squares and numbers)<br/><img style={{ maxWidth: '60px', display: 'none' }} alt="" src="/assets/content/02.png"/></li><li>Questions that require that you enter a percentage, a number or a currency (a box is proposed to enter your response and the description above the box indicated what type of response is required)<br/><img style={{ maxWidth: '260px', display: 'none' }} alt="" src="/assets/content/03.png"/></li><li>Questions that, together with the proposed answer, require you to enter additional information (those answers are marked with Roman numerals and there is a box for entering additional information)<br/><img style={{ maxWidth: '300px', display: 'none' }} alt="" src="/assets/content/04.png"/></li></ul>
            <p>Next to certain questions, you have the possibility to attach relevant documents or and additional box for entering longer comments. This possibility will be clearly indicated next to the question.</p>
            <p style={{ display: 'none' }}>
              <img style={{ maxWidth: '140px' }} alt="" src="/assets/content/05.png"/>
              <img style={{ maxWidth: '400px' }} alt="" src="/assets/content/06.png"/>
            </p>
            <p>Some questions offer the possibility to open an ‘information’ box with additional explanation of certain terms used in the question or in the suggested answer. This will be indicated through the ‘info’ sign and the &#39;information&#39; box will be opened by passing the mouse over the text of the question or that sign.</p>
            <p style={{ display: 'none' }}><img style={{ maxWidth: '50px' }} alt="" src="/assets/content/07.png"/></p>
            <p>After the last question within each topic, there is a place where you can attach additional documents and type additional explanation and comments, if necessary. Here you can attach any additional documentation related to this topic that you have not had the opportunity to attach in the previous questions. You can also enter any additional information that you believe is relevant and not covered through proposed questions. Also, in the case that for some questions you can’t fit your organization’s situation in the answers that are suggested, you can use this space to clarify the situation and answer the concerning questions.</p>
            <p style={{ display: 'none' }}><img style={{ maxWidth: '600px' }} alt="" src="/assets/content/08.png"/></p>
          </>
        }

        {getLanguage() === 'sr' &&
          <>
            <h1>Uputstvo za popunjavanje upitnika</h1>
            <p>Pre početka popunjavanja upitnika, otvorite deo „<strong>Podaci o organizaciji</strong>“ i popunite podatke koji nedostaju.</p>
            <p>Upitnik je podeljen na <strong>7 područja i 30 tema</strong>. Svaka tema sadrži nekoliko pitanja na koja ćemo Vas zamoliti da date odgovor koji najbolje odgovara stanju u organizaciji.</p>
            <p>Uneseni odgovori <u>automatski se prihvataju,</u> pa ih je <u>moguće menjati</u> sve do konačnog završetka popunjavanja upitnika. Popunjeno pitanje će dobiti zelenu boju, pa je na taj način lako pratiti na koja ste pitanja dali odgovor, a koja su još neodgovorena. </p>
            <p>Upitnik <u>nije</u> potrebno rešiti odjednom već se njemu možete vraćati sve dok ne zaključite upitnik i pošaljete konačne odgovore.</p>
            <p>Kada se u upitniku govori o <strong>zaposlenima</strong>, uvek se misli na <strong>aktivne zaposlene</strong>, odnosno aktivni HC (<em>Head Count</em>) – broj ugovora o radu umanjen za dugotrajna bolovanja (iznad 42 dana), trudničko i porodiljsko odsustvo, bolovanje zbog komplikacije u trudnoći i radnike poslate na rad u inostranstvo.</p>
            <p>Važno je da prilikom popunjavanja obratite pažnju na vremenski period na koji se pitanje odnosi – nekada je reč o periodu od poslednjih <strong>godinu dana</strong>, a nekada poslednje <strong>dve godine</strong>. </p>
            <p>Ako se pitanje odnosi na menadžere, obratite pažnju da li je reč o <strong>svim</strong> menadžerima ili samo o <strong>novim</strong> menadžerima. </p>
            <p>U upitniku koristimo <strong>nekoliko vrsta pitanja i ponuđenih odgovora:</strong></p>
            <ul><li>Pitanja na koja je moguće odgovoriti <u>samo jednim</u> od ponuđenih odgovora (odgovori su označeni kružićima i velikim štampanim slovima)
            <br/><img style={{ maxWidth: '60px', display: 'none' }} alt="" src="/assets/content/01.png"/></li><li>Pitanja na koja je moguće označiti <u>više</u> ponuđenih odgovora (odgovori su označeni kvadratićima i brojevima)<br/><img style={{ maxWidth: '60px', display: 'none' }} alt="" src="/assets/content/02.png"/></li><li>Pitanja koja za odgovor traže unošenje <u>procenta</u>, <u>broja</u> ili <u>valute</u> (ponuđeno je polje za unošenje odgovora, kao i naznaka koja vrsta odgovora se traži)<br/><img style={{ maxWidth: '260px', display: 'none' }} alt="" src="/assets/content/03.png"/></li><li>Pitanja koja uz svaki ponuđeni odgovor traže unošenje dodatnih informacija (odgovori su označeni rimskim brojevima i postoji polje za unošenje dodatnih informacija)<br/><img style={{ maxWidth: '300px', display: 'none' }} alt="" src="/assets/content/04.png"/></li></ul>
            <p>Uz određena pitanja predviđena je mogućnost prilaganja relevantnih dokumenata ili prostor za upisivanje dužeg komentara. Ta mogućnost biće jasno naznačena pored pitanja.</p>
            <p style={{ display: 'none' }}>
              <img style={{ maxWidth: '140px' }} alt="" src="/assets/content/05.png"/>
              <img style={{ maxWidth: '400px' }} alt="" src="/assets/content/06.png"/>
            </p>
            <p>Neka pitanja nude mogućnost otvaranja „oblačića“ s dodatnim pojašnjenjem određenih pojmova korišćenih u pitanju ili u ponuđenim odgovorima. To će biti naznačeno kroz znak za info, pa će se „oblačić“ otvarati prelaskom miša preko teksta pitanja ili tog znaka.</p>
            <p style={{ display: 'none' }}><img style={{ maxWidth: '50px' }} alt="" src="/assets/content/07.png"/></p>
            <p>Nakon poslednjeg pitanja, unutar svake teme postoji prostor za prilaganje dodatne dokumentacije i prostor za upisivanje dodatnog komentara, ako je potrebno. Tu možete priložiti svu dodatnu dokumentaciju vezanu za tu temu koju niste imali prilike da priložite ranije. Možete upisati i bilo kakve dodatne informacije za koje smatrate da su relevantne, a nisu pokrivene kroz pitanja. Takođe, u slučaju da se na nekim pitanjima dogodi da nijedan od ponuđenih odgovora ne opisuje dobro situaciju u kompaniji, u ovom polju možete to detaljnije pojasniti.</p>
            <p style={{ display: 'none' }}><img style={{ maxWidth: '600px' }} alt="" src="/assets/content/08.png"/></p>
          </>
        }
      </article>
    </div>;
  }
}