import React, { Component } from 'react';
import ReportBadge from './ReportBadge';
import { elc } from "./components/ReportTemplates";
import { getLanguage } from '../../utils/utils';

export default class ReportIntro extends Component {
  render() {
    return <div className="report__page__wrapper">
      <div className="report__page report_page--split_11">
        <div className="report__default">
          <img alt="" src={`/assets/images/info/info-ee-${getLanguage()}.png`}/>
        </div>
        <div className="report__info report__section--padding" dangerouslySetInnerHTML={{__html: elc[getLanguage()]}}/>
      </div>
      <ReportBadge/>
    </div>
  }
}