import classNames from "classnames";
import React, { Component } from "react";
import ReportBadge from "./ReportBadge";
import moment from "moment";
import { getLanguage } from "../../utils/utils";

const translations = {
  hr: {
    timelineGained: "uspješno stečen Employer Partner status",
    timelineCampaign: "početak cjelogodišnje employer branding kampanje",
    timelineInvoicing: "obnova ugovora i faktura",
    timelineUpdate: "ažuriranje Employer Partner upitnika",
    timelineRenewal: "završena obnova Employer Partner statusa",
    timelineFinalReport: "Finalno izvješće o postignutim rezultatima",
    timelineActionPlan: "Prijedlog HR strategije i akcijski plan",
    timelinePresentation: "Prezentacija rezultata",
    timelineResearchStart: "Provedba ispitivanja zadovoljstva i klime",
    timelineResearchEnd: "Rezultati ispitivanja zadovoljstva i klime",
    timelineAgain: "Ponovna procjena",
    monthStart: "siječanj",
    monthEnd: "srpanj",
    monthLast: "prosinac",
  },
  en: {
    timelineGained: "successfully gained Employer Partner status",
    timelineCampaign: "start of year-around employer branding campaign",
    timelineInvoicing: "contract renewal and invoicing",
    timelineUpdate: "updating the Employer Partner questionnaire",
    timelineRenewal: "renewal of Employer Partner status completed",
    timelineFinalReport: "Finalno izvješće o postignutim rezultatima",
    timelineActionPlan: "Prijedlog HR strategije i akcijski plan",
    timelinePresentation: "Prezentacija rezultata",
    timelineResearchStart: "Provedba ispitivanja zadovoljstva i klime",
    timelineResearchEnd: "Rezultati ispitivanja zadovoljstva i klime",
    timelineAgain: "Ponovna procjena",
    monthStart: "January",
    monthEnd: "March",
    monthLast: "December",
  },
  sr: {
    timelineGained: "uspešno stečen Employer Partner status",
    timelineCampaign: "početak celogodišnje employer branding kampanje",
    timelineInvoicing: "obnova ugovora i faktura",
    timelineUpdate: "ažuriranje Employer Partner upitnika",
    timelineRenewal: "završena obnova Employer Partner statusa",
    timelineFinalReport: "Finalno izvješće o postignutim rezultatima",
    timelineActionPlan: "Prijedlog HR strategije i akcijski plan",
    timelinePresentation: "Prezentacija rezultata",
    timelineResearchStart: "Provedba ispitivanja zadovoljstva i klime",
    timelineResearchEnd: "Rezultati ispitivanja zadovoljstva i klime",
    timelineAgain: "Ponovna procjena",
    monthStart: "januar",
    monthEnd: "Jul",
    monthLast: "Decembar",
  },
};

export default class ReportTimeline extends Component {
  constructor(props) {
    super(props);

    const messages = translations[getLanguage()];
    const date = this.props.date;
    const passed = this.props.passed;

    const defaultItems = passed
      ? [
          {
            position: "right",
            top: 70,
            title: moment(date).format("MMMM, YYYY"),
            subtitle: messages.timelineGained,
          },
          {
            position: "left",
            top: 90,
            title: moment(date).format("MMMM, YYYY"),
            subtitle: messages.timelineCampaign,
          },
          {
            position: "left",
            top: 200,
            title: moment(date).format(`[${messages.monthLast}, ]YYYY`),
            subtitle: messages.timelineInvoicing,
          },
          {
            position: "right",
            top: 270,
            title: moment(date)
              .add(1, "year")
              .format(
                `[${messages.monthStart}] YYYY [- ${messages.monthEnd}] YYYY`
              ),
            subtitle: messages.timelineUpdate,
          },
          {
            position: "left",
            top: 390,
            title: moment(date).add(1, "year").format("MMMM, YYYY"),
            subtitle: messages.timelineRenewal,
          },
        ]
      : [
          {
            position: "right",
            top: 90,
            title: moment(date).format("MMMM, YYYY"),
            subtitle: messages.timelineFinalReport,
          },
          {
            position: "left",
            top: 130,
            title: moment(date).format("MMMM, YYYY"),
            subtitle: messages.timelineActionPlan,
          },
          {
            position: "right",
            top: 200,
            title: moment(date).format(`[${messages.monthLast}, ]YYYY`),
            subtitle: messages.timelinePresentation,
          },
          {
            position: "left",
            top: 310,
            title: moment(date)
              .add(1, "year")
              .format(`[${messages.monthStart}] YYYY`),
            subtitle: messages.timelineResearchStart,
          },
          {
            position: "right",
            top: 350,
            title: moment(date).add(1, "year").format("MMMM, YYYY"),
            subtitle: messages.timelineResearchEnd,
          },
          {
            position: "left",
            top: 400,
            title: moment(date).add(1, "year").format("MMMM, YYYY"),
            subtitle: messages.timelineAgain,
          },
        ];

    let content = undefined;

    try {
      content = JSON.parse(this.props.content);
    } catch (e) {}

    this.state = {
      items: content ? content : defaultItems,
      updated: [],
    };
  }

  shouldComponentUpdate(nextProps, nextState) {
    console.log(
      "SHOULD UPDATE",
      JSON.stringify(this.state) !== JSON.stringify(nextState)
    );
    console.log(this.state);
    console.log(nextState);
    return this.state.items.length !== nextState.items.length;
    //return JSON.stringify(this.props) !== JSON.stringify(nextProps);
  }

  _itemOnDelete = (event) => {
    if (!window.confirm("Are you sure you want to delete this item?")) {
      return false;
    }

    let items = Array.from(this.state.items);
    items.splice(parseInt(event.target.dataset.key, 10), 1);

    this.setState({
      items: items,
    });

    this.props.onChange({
      target: {
        name: "timeline",
        value: JSON.stringify(items),
      },
    });
  };

  _lineOnClick = (event) => {
    const rect = event.target.getBoundingClientRect();

    var newItem = {
      position: event.target.dataset.position,
      top: event.clientY - rect.top + 50,
      title: "Title...",
      subtitle: "subtitle...",
    };

    this.setState({
      items: [...this.state.items, newItem],
    });

    console.log("AFTER SET STATE");
  };

  _timelineItemOnInput = (event) => {
    let items = this.state.items;
    let updated = this.state.updated;
    let data = event.currentTarget.dataset;

    updated[`${data.index}_${data.field}`] = event.currentTarget.textContent;

    this.setState({
      updated: updated,
    });

    console.log(updated);

    for (const i in items) {
      if (updated[`${i}_title`]) {
        items[i].title = updated[`${i}_title`];
      }

      if (updated[`${i}_subtitle`]) {
        items[i].subtitle = updated[`${i}_subtitle`];
      }
    }

    this.props.onChange({
      target: {
        name: "timeline",
        value: JSON.stringify(items),
      },
    });
  };

  render() {
    const { editable } = this.props;
    const { items } = this.state;

    return (
      <div className="report__page__wrapper" id="page-timeline">
        <div className="report__page">
          <div id="page-timeline" className="report__timeline">
            <div
              className="report__timeline__line"
              data-position="left"
              onClick={editable ? this._lineOnClick : null}
            />
            <div
              className="report__timeline__line"
              data-position="right"
              onClick={editable ? this._lineOnClick : null}
            />
            {items.map((item, key) => (
              <div
                key={key}
                className={classNames("report__timeline__item", {
                  "report__timeline__item--left": item.position === "left",
                })}
                style={{ top: `${item.top}px` }}
              >
                <div
                  className="report__timeline__item__dot"
                  onClick={editable ? this._itemOnDelete : null}
                  data-key={key}
                ></div>
                <div
                  data-index={key}
                  data-field="title"
                  className="report__timeline__item__title"
                  contentEditable={editable}
                  suppressContentEditableWarning={true}
                  onInput={this._timelineItemOnInput}
                >
                  {item.title}
                </div>
                <div
                  data-index={key}
                  data-field="subtitle"
                  className="report__timeline__item__subtitle"
                  contentEditable={editable}
                  suppressContentEditableWarning={true}
                  onInput={this._timelineItemOnInput}
                >
                  {item.subtitle}
                </div>
              </div>
            ))}
          </div>
        </div>
        <ReportBadge />
      </div>
    );
  }
}
