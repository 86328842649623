import React, { Component } from "react";
import ReactTooltip from "react-tooltip";
import CurrencyInput from "react-currency-input-field";
import {
  Documents,
  Loader,
  CommentInput,
  NumberInput,
  QuestionBenefits,
  QuestionCommlist,
  QuestionDiversity,
  QuestionTargets,
  RateBox,
  Tabs,
} from "../components";
import API from "../api";
import classNames from "classnames";
import { getLanguage } from "../utils/utils";
import CommentList from "../components/CommentList";

const questionConfig = {
  "single-choice": {
    type: "radio",
    className: "sentence__checkbox",
  },
  "multi-choice": {
    type: "checkbox",
    className: "sentence__checkbox",
  },
  "multi-choice-input": {
    type: "checkbox",
    className: "sentence__checkbox",
  },
  "multi-choice-percentage": {
    type: "checkbox",
    className: "sentence__checkbox",
  },
  "multi-choice-number": {
    type: "checkbox",
    className: "sentence__checkbox",
  },
  "multi-choice-currency": {
    type: "checkbox",
    className: "sentence__checkbox",
  },
  "multi-choice-targets": {
    type: "checkbox",
    className: "sentence__checkbox",
  },
  number: {
    type: "number",
    min: 0.0,
    max: 99999999,
  },
  percentage: {
    type: "number",
    min: 0.0,
    max: 100.0,
  },
  currency: {
    type: "number",
    min: 0.0,
    max: 99999999,
  },
};

const translations = {
  hr: {
    edit: "Uredi",
    save: "Spremi",
    enterYourComments: "Upišite svoj komentar",
    addComment: "Dodaj komentar",
    yourComment: "Vaš komentar",
    editAnswer: "Uredi odgovor",
    lockAnswer: "Zaključaj odgovor",
    averageGrade: "Prosječna ocjena",
    totalGrades: "Ukupne ocjene",
    keyQuestions: "Ključna pitanja",
    selectGrade: "Odaberite ocjenu za ovu temu",
    selectFinalGrade: "Odaberite konačnu ocjenu za ovu temu",
    previousYear: "Prošla godina:",
    additionalDocumentation: "Dodatna dokumentacija (nije obavezno)",
    additionalDocumentationInfo:
      "Molimo priložite dodatnu dokumentaciju te unesite dodatni komentar za ovo područje ako je potrebno.",
    errorAnswerNotSaved:
      "Vaš odgovor nije spremljen! Molimo ponovno učitajte stranicu i provjerite jeste li spojeni na mrežu.",
  },
  en: {
    edit: "Edit",
    save: "Save",
    enterYourComments: "Add your comments",
    addComment: "Add comment",
    yourComment: "Your comment",
    editAnswer: "Edit answer",
    lockAnswer: "Lock answer",
    averageGrade: "Average grade",
    totalGrades: "Ukupne ocjene",
    keyQuestions: "Key questions",
    selectGrade: "Select grade for this area",
    selectFinalGrade: "Select final grade for this area",
    previousYear: "Previous year:",
    additionalDocumentation: "Additional documentation",
    additionalDocumentationInfo:
      "Please attach additional documents and any additional explanation/comments concerning this topic, if necessary.",
    errorAnswerNotSaved:
      "Your answer was not saved! Please reload the page and check your internet connection.",
  },
  sr: {
    edit: "Uredi",
    save: "Spremi",
    enterYourComments: "Upišite svoj komentar",
    addComment: "Dodaj komentar",
    yourComment: "Vaš komentar",
    editAnswer: "Uredi odgovor",
    lockAnswer: "Zaključaj odgovor",
    averageGrade: "Prosječna ocjena",
    totalGrades: "Ukupne ocjene",
    keyQuestions: "Ključna pitanja",
    selectGrade: "Odaberite ocenu za ovu temu",
    selectFinalGrade: "Odaberite finalnu ocenu za ovu temu",
    previousYear: "Prošla godina:",
    additionalDocumentation: "Dodatna dokumentacija (nije obavezno)",
    additionalDocumentationInfo: "Dodatna dokumentacija (nije obavezno)",
    errorAnswerNotSaved:
      "Vaš odgovor nije spremljen! Molimo ponovno učitajte stranicu i provjerite jeste li spojeni na mrežu.",
  },
};

export default class Category extends Component {
  constructor(props) {
    super(props);

    this.state = {
      id: this.props.match.params.id,
      rater: JSON.parse(localStorage.getItem("certifier")),
      final: localStorage.getItem("final") ? true : false,
      loading: true,
      data: null,
      form: {},
      previousForm: {},
      answers: {},
      pendingAnswers: {},
      grades: {},
      certifiers: [],
      certifierEditing: false,
      certifierGrades: {},
      certifierFinalGrades: {},
      certifierFinalGradesPrevious: {},
      certifierComments: {},
      certifierFinalComments: {},
      certifierQuestionEditing: null,
      systemGrades: {},
      systemGradesImportant: {},
      comments: {},
      documents: {},
      previousDocuments: {},
      completed: {},
      activeAreaIndex: 0,
      saveTimeout: null,
      messages: translations[getLanguage()],
    };
  }

  componentDidMount() {
    this._init();
  }

  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      this._init();
    }
  }

  _init = () => {
    const { id, slug, index } = this.props.match.params;

    this.setState(
      {
        aboveBeyond: this.props.aboveBeyond,
      },
      () => {
        this._loadData(id, slug, index);
      }
    );
  };

  _loadData = async (id, slug, index) => {
    this.setState({
      loading: true,
    });

    await API.get("", {
      params: {
        action: this.state.aboveBeyond ? "aboveBeyond" : "areaCategory",
        id: id,
        slug: slug,
        language: getLanguage(),
      },
    })
      .then((response) => {
        this.setState({
          data: response.data,
          form: response.data.responses,
          previousForm: response.data.previousResponses,
          documents: response.data.documents,
          previousDocuments: response.data.previousDocuments,
          certifiers: response.data.certifiers,
          certifierGrades: response.data.certifierGrades,
          certifierFinalGrades: response.data.certifierFinalGrades,
          certifierFinalGradesPrevious: response.data
            .certifierFinalGradesPrevious
            ? response.data.certifierFinalGradesPrevious
            : {},
          certifierComments: response.data.certifierComments,
          certifierFinalComments: response.data.certifierFinalComments,
          systemGrades: response.data.systemGrades,
          systemGradesImportant: response.data.systemGradesImportant,
          activeAreaIndex: index ? parseInt(index, 10) : 0,
          loading: false,
          aboveBeyondUpdates: {},
        });
      })
      .catch((error) => {
        return;
      });

    if (this.state.rater && this.state.aboveBeyond) {
      await API.get("", {
        params: {
          id: this.state.id,
          action: "aboveBeyondCertifierUpdates",
        },
      })
        .then((response) => {
          this.setState({
            aboveBeyondUpdates: response.data,
          });
        })
        .catch((error) => {
          return;
        });
    }
  };

  _saveAnswers = async () => {
    const pending = this.state.pendingAnswers;
    console.log("SAVING ANSWERS", pending);

    if (Object.keys(pending).length === 0) {
      console.log("SKIP");
      return;
    }

    await API.post("", {
      action: "questionnaireAnswersSave",
      id: this.state.id,
      answers: pending,
    })
      .then((response) => {
        console.log("SAVED");
        let currentPending = this.state.pendingAnswers;

        for (let i in currentPending) {
          if (pending[i] === currentPending[i]) {
            console.log("REMOVE " + i);
            console.log(pending[i], currentPending[i]);
            delete currentPending[i];
          }
        }

        this.setState({
          pendingAnswers: currentPending,
        });
      })
      .catch((error) => {
        alert(this.state.messages.errorAnswerNotSaved);
      });
  };

  _inputOnClick = (event) => {
    const name = event.target.name;
    const type = event.target.type;
    const value =
      type === "checkbox" ? event.target.checked : event.target.value;
    let form = this.state.form;

    if (
      type === "radio" &&
      form[name] !== undefined &&
      form[name].value === value
    ) {
      console.log("DELETE");
      delete form[name];

      const pending = this.state.pendingAnswers;
      pending[name] = false;

      this.setState({
        form: form,
        pendingAnswers: pending,
        saveTimeout: setTimeout(this._saveAnswers, 10),
      });

      event.preventDefault();
    }
  };

  _inputOnChange = (event) => {
    console.log(`INPUT ON CHANGE ${event.target.value}`);
    if (this.state.saveTimeout) {
      console.log(`CLEAR TIMEOUT ${event.target.value}`);
      clearTimeout(this.state.saveTimeout);
      this.setState({
        saveTimeout: null,
      });
    }

    const name = event.target.name;
    const type = event.target.type;
    const value =
      type === "checkbox" ? event.target.checked : event.target.value;
    const questionId = parseInt(event.target.dataset.question, 10);
    const areaId = parseInt(event.target.dataset.area, 10);
    const answerId = parseInt(event.target.dataset.id, 10);
    let form = this.state.form;

    const valueData = {
      questionId: questionId,
      answerId: answerId,
      areaId: areaId,
      type: type,
      value: value,
      content: typeof value === "string" ? value : null,
    };

    form[name] = valueData;

    if (name.indexOf("additional") !== false) {
      const mainAnswerName = name.replace("additional_", "");
      if (
        (value.length > 0 && form[mainAnswerName] === undefined) ||
        (value.length === 0 && form[mainAnswerName] !== undefined)
      ) {
        document.getElementsByName(mainAnswerName)[0].click();
      }
    }

    const pending = this.state.pendingAnswers;
    pending[name] = valueData;

    console.log(`SET TIMEOUT ${event.target.value}`);

    this.setState({
      form: form,
      pendingAnswers: pending,
      saveTimeout: setTimeout(this._saveAnswers, 200),
    });
  };

  _questionIsCompleted = (question) => {
    const form = this.state.form;

    switch (question.type) {
      default:
        if (form[`question_${question.id}`]) {
          return true;
        }
        for (let i in question.answers) {
          const answer = question.answers[i];
          if (
            form[`answer_${answer.id}`] &&
            form[`answer_${answer.id}`].value !== false
          ) {
            return true;
          }
        }
        break;
    }

    return false;
  };

  _documentOnUpload = async (event) => {
    let value = null;

    await API.post("", {
      ...{
        id: this.state.id,
        action: "questionnaireDocumentSubmit",
      },
      ...event.target,
    })
      .then((response) => {
        value = response.data;
      })
      .catch((error) => {
        value = {
          error: true,
        };

        alert(this.state.messages.errorAnswerNotSaved);
      });

    return value;
  };

  _documentOnDelete = async (documentId) => {
    await API.post("", {
      id: this.state.id,
      action: "questionnaireDocumentDelete",
      documentId: documentId,
    })
      .then((response) => {})
      .catch((error) => {});
  };

  _questionIsDisabled = (question) => {
    return (
      (!this.state.rater && question.locked) ||
      this._questionIsDefaultDisabled(question) ||
      (this.state.rater && this.state.certifierQuestionEditing !== question.id)
    );
  };

  _questinnaireIsDisabled = () => {
    return (
      (!this.state.rater && this.state.data.locked) ||
      (this.state.rater && !this.state.certifierEditing)
    );
  };

  _questionIsDefaultDisabled = (question) => {
    const form = this.state.form;
    return (
      question.disabled === 1 ||
      (question.disabledOnQuestionId &&
        form[`question_${question.disabledOnQuestionId}`] !== undefined &&
        form[`question_${question.disabledOnQuestionId}`].value ===
          String(question.disabledOnAnswerId))
    );
  };

  _certifierCommentOnSave = async (entityId, content) => {
    await API.post("", {
      action: "certifierAreaQuestionCommentSet",
      id: this.state.id,
      questionId: entityId,
      content: content,
    })
      .then((response) => {})
      .catch((error) => {
        alert(this.state.messages.errorAnswerNotSaved);
      });
  };

  _certifierAreaCommentOnSave = async (entityId, content) => {
    await API.post("", {
      action: "certifierAreaCommentSet",
      id: this.state.id,
      areaId: entityId,
      content: content,
    })
      .then((response) => {})
      .catch((error) => {
        alert(this.state.messages.errorAnswerNotSaved);
      });
  };

  _certifierAreaFinalCommentOnSave = async (entityId, content) => {
    await API.post("", {
      action: "certifierAreaFinalCommentSet",
      id: this.state.id,
      areaId: entityId,
      content: content,
    })
      .then((response) => {})
      .catch((error) => {
        alert(this.state.messages.errorAnswerNotSaved);
      });
  };

  _certifierAreaCommentFinalOnSave = async (entityId, content) => {
    await API.post("", {
      action: "certifierAreaCommentFinalSet",
      id: this.state.id,
      areaId: entityId,
      content: content,
    })
      .then((response) => {})
      .catch((error) => {
        alert(this.state.messages.errorAnswerNotSaved);
      });
  };

  _certifierQuestionOnEdit = (event) => {
    const id = parseInt(event.target.dataset.id, 10);
    this.setState({
      certifierQuestionEditing:
        this.state.certifierQuestionEditing === id ? null : id,
    });
  };

  _certifierQuestionnaireOnEdit = (event) => {
    this.setState({
      certifierEditing: !this.state.certifierEditing,
    });
  };

  _commentAddOnClick = (event) => {
    this.setComment(event.target.dataset.id, true);
  };

  _commentEditOnClick = (event) => {
    this.setComment(event.target.dataset.id, true);
  };

  _commentOnChange = (event) => {
    this.setComment(event.target.dataset.id, true, event.target.value);
  };

  _commentSaveOnClick = (event) => {
    this.setComment(
      event.target.dataset.id,
      false,
      document.getElementById(`comment_input_${event.target.dataset.id}`).value
    );
  };

  _onGradeClick = async (entityId, grade) => {
    let grades = this.state.certifierGrades;

    grades[`area_${entityId}`] = grade;

    this.setState({
      certifierGrades: grades,
    });

    await API.post("", {
      action: "certifierAreaGradeSet",
      id: this.state.id,
      areaId: entityId,
      grade: grade,
    })
      .then((response) => {})
      .catch((error) => {
        alert(this.state.messages.errorAnswerNotSaved);
      });
  };

  _onFinalGradeClick = async (entityId, grade) => {
    const index = `area_${entityId}`;
    let grades = this.state.certifierFinalGrades;
    grades[index] = grade;

    this.setState({
      certifierFinalGrades: grades,
    });

    await API.post("", {
      action: "certifierAreaFinalGradeSet",
      id: this.state.id,
      areaId: entityId,
      grade: grade,
    })
      .then((response) => {})
      .catch((error) => {
        alert(this.state.messages.errorAnswerNotSaved);
      });
  };

  _onTabClick = (event) => {
    const link = event.target.dataset.link;

    this.setState(
      {
        activeAreaIndex: parseInt(event.target.dataset.tab, 10),
      },
      () => {
        this.props.history.push({
          pathname: link,
        });

        ReactTooltip.rebuild();
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      }
    );
  };

  _onAbClick = async (event) => {
    const dataset = event.target.dataset;
    let updates = this.state.aboveBeyondUpdates;
    const index = `question_${dataset.id}`;
    const currentValue = updates[index];
    updates[index] =
      currentValue === undefined
        ? parseFloat(dataset.grade) === 4
          ? false
          : true
        : !currentValue;

    this.setState({
      aboveBeyondUpdates: updates,
    });

    await API.post("", {
      action: "aboveBeyondUpdate",
      id: this.state.id,
      questionId: dataset.id,
      grade: updates[index],
    })
      .then((response) => {})
      .catch((error) => {
        alert(this.state.messages.errorAnswerNotSaved);
      });
  };

  setCertifierComment = (id, editing, content = null) => {
    const data = this.state.data;
    const activeAreaId = this.state.activeAreaIndex;
    const activeArea = data.areas[activeAreaId];

    for (const i in activeArea.questions) {
      const item = activeArea.questions[i];
      console.log("SKIPPING ITEM");

      if (item.id !== parseInt(id, 10)) {
        continue;
      }

      console.log("FOUND ITEM");

      item.certifierCommentEditing = editing;

      if (content !== null) {
        item.certifierComment = content;
      }

      activeArea.questions[i] = item;
      data.areas[activeAreaId] = activeArea;

      this.setState({
        data: data,
      });

      return item.certifierComment;
    }
  };

  setComment = (id, editing, content = null) => {
    let comments = this.state.comments;

    if (!comments[id]) {
      comments[id] = {};
    }

    comments[id].editing = editing;

    if (content !== null) {
      comments[id].content = content;
    }

    this.setState({
      comments: comments,
    });
  };

  hasAnswerChanged = (question, answerId) => {
    if (!question.originalQuestionnaireId) return false;

    const currentAnswer = this.isAnswerSelected(answerId);
    const previousAnswer = this.wasPreviousAnswerSelected(answerId);

    return (
      currentAnswer !== previousAnswer && (currentAnswer || previousAnswer)
    );
  };

  hasQuestionAnswerChanged = (question, answerId) => {
    if (!question.originalQuestionnaireId) return false;

    const currentAnswer = this.getQuestionAnswer(question.id);
    const previousAnswer = this.getQuestionPreviousAnswer(question.id);

    return (
      previousAnswer &&
      currentAnswer !== previousAnswer &&
      (currentAnswer === answerId || previousAnswer === answerId)
    );
  };

  isAnswerSelected = (answerId) => {
    const form = this.state.form;
    return form[`answer_${answerId}`] !== undefined
      ? form[`answer_${answerId}`].value
      : false;
  };

  wasPreviousAnswerSelected = (answerId) => {
    const form = this.state.previousForm;
    return form[`answer_${answerId}`] !== undefined
      ? form[`answer_${answerId}`].value
      : false;
  };

  getQuestionAnswer = (questionId) => {
    const form = this.state.form;
    return parseInt(
      form[`question_${questionId}`] !== undefined
        ? form[`question_${questionId}`].value
        : 0,
      10
    );
  };

  getQuestionPreviousAnswer = (questionId) => {
    const form = this.state.previousForm;
    return parseInt(
      form[`question_${questionId}`] !== undefined
        ? form[`question_${questionId}`].value
        : 0,
      10
    );
  };

  render() {
    const {
      id,
      aboveBeyond,
      aboveBeyondUpdates,
      activeAreaIndex,
      rater,
      data,
      form,
      previousForm,
      certifiers,
      certifierEditing,
      certifierGrades,
      certifierFinalGrades,
      certifierFinalGradesPrevious,
      certifierComments,
      certifierFinalComments,
      certifierQuestionEditing,
      systemGrades,
      systemGradesImportant,
      loading,
      documents,
      previousDocuments,
      messages,
    } = this.state;

    if (loading) {
      return <Loader />;
    }

    const activeArea = data.areas[activeAreaIndex];
    const areaIndex = `area_${activeArea.id}`;

    const tabs = [];

    for (const item of data.areas) {
      tabs.push({
        ...item,
        ...{
          link: `/${id}/${aboveBeyond ? "above-beyond" : "category"}/${
            item.slug
          }/${item.position - 1}`,
          disabled: data.questionnaire.keyOnly && item.minGrade === 0,
        },
      });
    }

    return (
      <div className="page__content">
        <ReactTooltip />
        <article className="article">
          <h1>{data.title}</h1>
          <div dangerouslySetInnerHTML={{ __html: data.content }} />
        </article>
        <Tabs
          id={id}
          active={activeAreaIndex}
          items={tabs}
          onClick={this._onTabClick}
        />
        {data && (
          <>
            <div className="questions">
              {activeArea.questions && (
                <>
                  {activeArea.questions.map((item, questionIndex) => (
                    <div
                      key={item.id}
                      className={classNames(
                        "question",
                        { "question--advanced": item.advanced === 1 },
                        {
                          "question--completed":
                            this._questionIsCompleted(item),
                        },
                        {
                          "question--disabled": this._questionIsDisabled(item),
                        },
                        { "question--system_disabled": item.disabled },
                        {
                          "question--default_disabled":
                            this._questionIsDefaultDisabled(item),
                        },
                        { "question--rater": rater }
                      )}
                    >
                      <article className="article">
                        <div className="question__heading">
                          <h3 className="question__title" data-tip={item.info}>
                            {questionIndex + 1}.{" "}
                            <span
                              dangerouslySetInnerHTML={{ __html: item.title }}
                            />
                            {rater && item.important === 1 && (
                              <span className="important">*</span>
                            )}
                            {item.info && (
                              <img
                                className="question__tooltip"
                                alt=""
                                src="/assets/info.svg"
                              />
                            )}
                          </h3>
                          {rater && (
                            <div className="question__grade__options">
                              <div
                                className={classNames("question__grade", {
                                  "question__grade--advanced":
                                    item.advancedCategory,
                                })}
                                data-tip={item.criteria}
                              >
                                {item.grade !== null
                                  ? item.grade === 4
                                    ? "3+"
                                    : item.grade
                                  : "-"}
                              </div>
                              {rater && item.certifierNotes && (
                                <div
                                  className="question__grade__notes"
                                  data-tip={item.certifierNotes}
                                >
                                  !
                                </div>
                              )}
                              {aboveBeyond && (
                                <div
                                  onClick={this._onAbClick}
                                  data-id={item.id}
                                  data-grade={item.grade}
                                  className={classNames("question__grade__ab", {
                                    "question__grade__ab--checked":
                                      aboveBeyondUpdates[
                                        `question_${item.id}`
                                      ] === true ||
                                      (item.grade === 4 &&
                                        aboveBeyondUpdates[
                                          `question_${item.id}`
                                        ] === undefined),
                                  })}
                                >
                                  A&amp;B
                                </div>
                              )}
                            </div>
                          )}
                        </div>
                        {rater && (
                          <div
                            className="question__enable"
                            data-id={item.id}
                            onClick={this._certifierQuestionOnEdit}
                          >
                            {certifierQuestionEditing === item.id ? (
                              <>{messages.lockAnswer}</>
                            ) : (
                              <>{messages.editAnswer}</>
                            )}
                          </div>
                        )}
                        <div className="question__info">{item.intro}</div>
                        {item.answers && (
                          <>
                            {item.type.indexOf("multi-choice") === 0 && (
                              <div className="input_box--multioptions">
                                {item.answers.map((answer, key) => (
                                  <div key={key}>
                                    <div
                                      className="label--checkmark"
                                      data-id={answer.id}
                                    >
                                      <input
                                        id={answer.id}
                                        className={`answer__checkmark ${
                                          questionConfig[item.type].className
                                        }`}
                                        type={questionConfig[item.type].type}
                                        name={`answer_${answer.id}`}
                                        value={answer.id}
                                        disabled={this._questionIsDisabled(
                                          item
                                        )}
                                        checked={this.isAnswerSelected(
                                          answer.id
                                        )}
                                        data-question={item.id}
                                        data-id={answer.id}
                                        onChange={this._inputOnChange}
                                      />
                                      <span
                                        className={classNames("answer__label", {
                                          "answer__label--changed":
                                            this.hasAnswerChanged(
                                              item,
                                              answer.id
                                            ),
                                        })}
                                      >
                                        {answer.mark && (
                                          <label
                                            htmlFor={answer.id}
                                            className="answer__mark"
                                          >
                                            {answer.mark}
                                          </label>
                                        )}
                                        <span
                                          data-id={answer.id}
                                          dangerouslySetInnerHTML={{
                                            __html: answer.content,
                                          }}
                                        />
                                      </span>
                                    </div>
                                    {answer.skipAdditional === 0 && (
                                      <>
                                        {[
                                          "multi-choice-percentage",
                                          "multi-choice-number",
                                        ].indexOf(item.type) !== -1 && (
                                          <NumberInput
                                            key={key}
                                            id={answer.id}
                                            question={item.id}
                                            type={
                                              item.type ===
                                              "multi-choice-percentage"
                                                ? "percentage"
                                                : "number"
                                            }
                                            label={""}
                                            min={questionConfig[item.type].min}
                                            max={questionConfig[item.type].max}
                                            name={`answer_additional_${answer.id}`}
                                            value={
                                              form[
                                                `answer_additional_${answer.id}`
                                              ]
                                                ? form[
                                                    `answer_additional_${answer.id}`
                                                  ].value
                                                : ""
                                            }
                                            previousValue={
                                              rater &&
                                              previousForm[
                                                `answer_additional_${answer.id}`
                                              ]
                                                ? previousForm[
                                                    `answer_additional_${answer.id}`
                                                  ].value
                                                : ""
                                            }
                                            onChange={this._inputOnChange}
                                            disabled={this._questionIsDisabled(
                                              item
                                            )}
                                            copied={
                                              item.originalQuestionnaireId !==
                                              null
                                            }
                                          />
                                        )}
                                        {["multi-choice-currency"].indexOf(
                                          item.type
                                        ) !== -1 && (
                                          <div className="input_box--number">
                                            <CurrencyInput
                                              id={answer.id}
                                              data-id={answer.id}
                                              data-question={item.id}
                                              label={""}
                                              name={`answer_additional_${answer.id}`}
                                              defaultValue={
                                                form[
                                                  `answer_additional_${answer.id}`
                                                ]
                                                  ? form[
                                                      `answer_additional_${answer.id}`
                                                    ].value
                                                  : ""
                                              }
                                              decimalsLimit={2}
                                              disabled={this._questionIsDisabled(
                                                item
                                              )}
                                              onValueChange={(value, name) => {
                                                this._inputOnChange({
                                                  target: {
                                                    name: name,
                                                    value: value,
                                                    type: item.type,
                                                    dataset: {
                                                      id: answer.id,
                                                      question: item.id,
                                                    },
                                                  },
                                                });
                                              }}
                                              className="input input--number input--currency"
                                            />
                                            <span
                                              className={classNames(
                                                "input__sufix",
                                                {
                                                  "input__sufix--new":
                                                    item.originalQuestionnaireId !==
                                                    null,
                                                }
                                              )}
                                            >
                                              EUR
                                            </span>

                                            {rater &&
                                              previousForm[
                                                `answer_additional_${answer.id}`
                                              ] && (
                                                <div className="input__previous">
                                                  {
                                                    previousForm[
                                                      `answer_additional_${answer.id}`
                                                    ].value
                                                  }
                                                </div>
                                              )}
                                          </div>
                                        )}
                                        {["multi-choice-targets"].indexOf(
                                          item.type
                                        ) !== -1 && (
                                          <div className="input_box--number">
                                            <CurrencyInput
                                              id={answer.id}
                                              data-id={answer.id}
                                              data-question={item.id}
                                              label={""}
                                              name={`answer_additional_${answer.id}`}
                                              defaultValue={
                                                form[
                                                  `answer_additional_${answer.id}`
                                                ]
                                                  ? form[
                                                      `answer_additional_${answer.id}`
                                                    ].value
                                                  : ""
                                              }
                                              decimalsLimit={2}
                                              disabled={this._questionIsDisabled(
                                                item
                                              )}
                                              onValueChange={(value, name) => {
                                                this._inputOnChange({
                                                  target: {
                                                    name: name,
                                                    value: value,
                                                    type: item.type,
                                                    dataset: {
                                                      id: answer.id,
                                                      question: item.id,
                                                    },
                                                  },
                                                });
                                              }}
                                              className="input input--number input--currency"
                                            />
                                            <span
                                              className={classNames(
                                                "input__sufix",
                                                {
                                                  "input__sufix--new":
                                                    item.originalQuestionnaireId !==
                                                    null,
                                                }
                                              )}
                                            >
                                              EUR
                                            </span>
                                          </div>
                                        )}
                                      </>
                                    )}
                                  </div>
                                ))}
                              </div>
                            )}
                            {["single-choice"].indexOf(item.type) !== -1 && (
                              <div>
                                {item.answers.map((answer, key) => (
                                  <div key={key}>
                                    <div
                                      className="label--checkmark"
                                      data-id={answer.id}
                                    >
                                      <input
                                        id={answer.id}
                                        className={`answer__checkmark ${
                                          questionConfig[item.type].className
                                        }`}
                                        name={`question_${item.id}`}
                                        type={questionConfig[item.type].type}
                                        value={answer.id}
                                        checked={
                                          this.getQuestionAnswer(item.id) ===
                                          answer.id
                                        }
                                        onChange={this._inputOnChange}
                                        onClick={this._inputOnClick}
                                        data-question={item.id}
                                        data-id={answer.id}
                                        disabled={this._questionIsDisabled(
                                          item
                                        )}
                                      />
                                      <span
                                        className={classNames("answer__label", {
                                          "answer__label--changed":
                                            this.hasQuestionAnswerChanged(
                                              item,
                                              answer.id
                                            ),
                                        })}
                                      >
                                        {answer.mark && (
                                          <label
                                            htmlFor={answer.id}
                                            className="answer__mark"
                                          >
                                            {answer.mark}
                                          </label>
                                        )}
                                        <span
                                          data-id={answer.id}
                                          dangerouslySetInnerHTML={{
                                            __html: answer.content,
                                          }}
                                        />
                                      </span>
                                    </div>
                                  </div>
                                ))}
                              </div>
                            )}
                            {["number", "percentage"].indexOf(item.type) !==
                              -1 && (
                              <>
                                {item.answers.map((answer, key) => (
                                  <NumberInput
                                    key={key}
                                    id={answer.id}
                                    question={item.id}
                                    type={item.type}
                                    label={answer.content}
                                    min={questionConfig[item.type].min}
                                    max={questionConfig[item.type].max}
                                    name={`answer_${answer.id}`}
                                    value={
                                      form[`answer_${answer.id}`]
                                        ? form[`answer_${answer.id}`].value
                                        : ""
                                    }
                                    previousValue={
                                      rater &&
                                      previousForm[`answer_${answer.id}`]
                                        ? previousForm[`answer_${answer.id}`]
                                            .value
                                        : ""
                                    }
                                    onChange={this._inputOnChange}
                                    disabled={this._questionIsDisabled(item)}
                                    copied={
                                      item.originalQuestionnaireId !== null
                                    }
                                  />
                                ))}
                              </>
                            )}
                            {["currency"].indexOf(item.type) !== -1 && (
                              <>
                                {item.answers.map((answer, key) => (
                                  <>
                                    <CurrencyInput
                                      key={key}
                                      id={answer.id}
                                      data-id={answer.id}
                                      data-question={item.id}
                                      type={item.type}
                                      label={answer.content}
                                      name={`answer_${answer.id}`}
                                      defaultValue={
                                        form[`answer_${answer.id}`]
                                          ? form[`answer_${answer.id}`].value
                                          : ""
                                      }
                                      decimalsLimit={2}
                                      disabled={this._questionIsDisabled(item)}
                                      onValueChange={(value, name) => {
                                        this._inputOnChange({
                                          target: {
                                            name: name,
                                            value: value,
                                            type: item.type,
                                            dataset: {
                                              id: answer.id,
                                              question: item.id,
                                            },
                                          },
                                        });
                                      }}
                                      className="input input--currency"
                                    />
                                    <span
                                      className={classNames("input__sufix", {
                                        "input__sufix--new":
                                          item.originalQuestionnaireId !== null,
                                      })}
                                    >
                                      EUR
                                    </span>

                                    {rater &&
                                      previousForm[`answer_${answer.id}`] && (
                                        <div className="input__previous">
                                          {
                                            previousForm[`answer_${answer.id}`]
                                              .value
                                          }{" "}
                                          EUR
                                        </div>
                                      )}
                                  </>
                                ))}
                              </>
                            )}
                            {item.type === "custom-commlist" && (
                              <>
                                {item.answers.map((answer, key) => (
                                  <QuestionCommlist
                                    key={key}
                                    name={`answer_${answer.id}`}
                                    data={form[`answer_${answer.id}`]}
                                    previousData={
                                      rater
                                        ? previousForm[`answer_${answer.id}`]
                                        : null
                                    }
                                    id={answer.id}
                                    question={item.id}
                                    copied={
                                      item.originalQuestionnaireId !== null
                                    }
                                    onChange={this._inputOnChange}
                                  />
                                ))}
                              </>
                            )}
                            {item.type === "custom-benefits" && (
                              <>
                                {item.answers.map((answer, key) => (
                                  <QuestionBenefits
                                    key={key}
                                    name={`answer_${answer.id}`}
                                    data={form[`answer_${answer.id}`]}
                                    previousData={
                                      rater
                                        ? previousForm[`answer_${answer.id}`]
                                        : null
                                    }
                                    id={answer.id}
                                    question={item.id}
                                    copied={
                                      item.originalQuestionnaireId !== null
                                    }
                                    onChange={this._inputOnChange}
                                  />
                                ))}
                              </>
                            )}
                            {item.type === "custom-diversity" && (
                              <>
                                {item.answers.map((answer, key) => (
                                  <QuestionDiversity
                                    key={key}
                                    name={`answer_${answer.id}`}
                                    data={form[`answer_${answer.id}`]}
                                    previousData={
                                      rater
                                        ? previousForm[`answer_${answer.id}`]
                                        : null
                                    }
                                    id={answer.id}
                                    question={item.id}
                                    copied={
                                      item.originalQuestionnaireId !== null
                                    }
                                    onChange={this._inputOnChange}
                                  />
                                ))}
                              </>
                            )}
                            {item.type === "custom-targets" && (
                              <>
                                {item.answers.map((answer, key) => (
                                  <QuestionTargets
                                    key={key}
                                    name={`answer_${answer.id}`}
                                    data={form[`answer_${answer.id}`]}
                                    previousData={
                                      rater
                                        ? previousForm[`answer_${answer.id}`]
                                        : null
                                    }
                                    id={answer.id}
                                    question={item.id}
                                    copied={
                                      item.originalQuestionnaireId !== null
                                    }
                                    onChange={this._inputOnChange}
                                  />
                                ))}
                              </>
                            )}
                          </>
                        )}
                        {item.notice && (
                          <div className="notice">{item.notice}</div>
                        )}
                      </article>

                      {item.commentsRequired && (
                        <>
                          <div className="question__info">
                            {item.commentInfo}
                          </div>
                          <CommentInput
                            name={`question_comments_${item.id}`}
                            question={item}
                            value={
                              form[`question_comments_${item.id}`]
                                ? form[`question_comments_${item.id}`].value
                                : ""
                            }
                            copied={item.originalQuestionnaireId !== null}
                            previousValue={
                              rater &&
                              previousForm[`question_comments_${item.id}`]
                                ? previousForm[`question_comments_${item.id}`]
                                    .value
                                : ""
                            }
                            onChange={this._inputOnChange}
                            disabled={this._questionIsDisabled(item)}
                          />
                        </>
                      )}

                      {item.documentsRequired && (
                        <Documents
                          items={documents[`question_${item.id}`]}
                          copied={item.originalQuestionnaireId !== null}
                          previousItems={
                            previousDocuments[`question_${item.id}`]
                          }
                          inputName={`question_document_${item.id}`}
                          entityType="question"
                          entityId={item.id}
                          onUpload={this._documentOnUpload}
                          onDelete={this._documentOnDelete}
                          disabled={this._questionIsDisabled(item) && !rater}
                        />
                      )}

                      {rater && (
                        <CommentList
                          disabled={data.gradingLocked}
                          entityId={item.id}
                          questionnaireId={parseInt(id, 10)}
                          action="certifierAreaQuestionCommentSet"
                          comment={item.certifierComment}
                          comments={item.certifierComments}
                        />
                      )}
                    </div>
                  ))}
                </>
              )}
            </div>

            {!this.props.aboveBeyond && (
              <div className="additional">
                <h3 className="heading heading-small">
                  {messages.additionalDocumentation}
                </h3>
                <p className="notice">{messages.additionalDocumentationInfo}</p>
                {rater && (
                  <div
                    style={{ marginTop: "10px" }}
                    className="question__enable"
                    onClick={this._certifierQuestionnaireOnEdit}
                  >
                    {certifierEditing ? (
                      <>{messages.lockAnswer}</>
                    ) : (
                      <>{messages.editAnswer}</>
                    )}
                  </div>
                )}
                <CommentInput
                  name={`area_comments_${activeArea.id}`}
                  area={activeArea.id}
                  value={
                    form[`area_comments_${activeArea.id}`]
                      ? form[`area_comments_${activeArea.id}`].value
                      : ""
                  }
                  previousValue={
                    rater && previousForm[`area_comments_${activeArea.id}`]
                      ? previousForm[`area_comments_${activeArea.id}`].value
                      : ""
                  }
                  onChange={this._inputOnChange}
                  disabled={this._questinnaireIsDisabled()}
                  copied={
                    data.questionnaire.originalQuestionnaireId !== undefined &&
                    data.questionnaire.originalQuestionnaireId !== null
                  }
                />
                <Documents
                  items={documents[areaIndex]}
                  previousItems={previousDocuments[areaIndex]}
                  inputName={`area_document_${activeArea.id}`}
                  entityType="area"
                  entityId={activeArea.id}
                  onUpload={this._documentOnUpload}
                  onDelete={this._documentOnDelete}
                  disabled={this._questinnaireIsDisabled()}
                  copied={
                    data.questionnaire.originalQuestionnaireId !== undefined &&
                    data.questionnaire.originalQuestionnaireId !== null
                  }
                />
              </div>
            )}

            {rater && systemGrades && (
              <div>
                <div className="grade__summary">
                  <div className="table">
                    <h2>{messages.totalGrades}</h2>
                    {systemGrades[areaIndex] && (
                      <div className="grade__summary__content">
                        <div className="grade__summary__items">
                          {systemGrades[areaIndex].items.map((item, key) => (
                            <div key={key} className="grade__item">
                              <div className="grade__label">{item.grade}</div>
                              <div
                                className="grade__line"
                                style={{
                                  width:
                                    Math.round(
                                      (100 * item.count) /
                                        systemGrades[areaIndex].count
                                    ) + "%",
                                }}
                              ></div>
                              <div className="grade__value">{item.count}</div>
                            </div>
                          ))}
                        </div>
                        <div style={{ textAlign: "left" }}>
                          <strong>
                            {messages.averageGrade}:{" "}
                            {(
                              systemGrades[areaIndex].total /
                              systemGrades[areaIndex].count
                            ).toFixed(1)}
                          </strong>
                        </div>
                      </div>
                    )}
                  </div>
                  {systemGradesImportant && (
                    <div className="table">
                      <h2>{messages.keyQuestions}</h2>
                      {systemGradesImportant[areaIndex] && (
                        <div className="grade__summary__content">
                          <div className="grade__summary__items">
                            {systemGradesImportant[areaIndex].items.map(
                              (item, key) => (
                                <div key={key} className="grade__item">
                                  <div className="grade__label">
                                    {item.grade}
                                  </div>
                                  <div
                                    className="grade__line"
                                    style={{
                                      width:
                                        Math.round(
                                          (100 * item.count) /
                                            systemGradesImportant[areaIndex]
                                              .count
                                        ) + "%",
                                    }}
                                  ></div>
                                  <div className="grade__value">
                                    {item.count}
                                  </div>
                                </div>
                              )
                            )}
                          </div>
                          <div style={{ textAlign: "left" }}>
                            <strong>
                              {messages.averageGrade}:{" "}
                              {(
                                systemGradesImportant[areaIndex].total /
                                systemGradesImportant[areaIndex].count
                              ).toFixed(1)}
                            </strong>
                          </div>
                        </div>
                      )}
                    </div>
                  )}
                </div>

                <RateBox
                  disabled={data.gradingLocked}
                  entityId={activeArea.id}
                  title={messages.selectGrade}
                  grade={certifierGrades[areaIndex]}
                  onClick={this._onGradeClick}
                >
                  {rater &&
                    certifierFinalGradesPrevious[areaIndex] !== undefined && (
                      <div className="rate__previous">
                        {messages.previousYear}{" "}
                        {certifierFinalGradesPrevious[areaIndex]}
                      </div>
                    )}
                  <CommentList
                    disabled={data.gradingLocked}
                    entityId={activeArea.id}
                    comment={certifierComments[areaIndex]}
                    comments={activeArea.certifierComments}
                    onSave={this._certifierAreaCommentOnSave}
                  />
                </RateBox>

                {data.finalized === 1 && (
                  <RateBox
                    entityId={activeArea.id}
                    title={messages.selectFinalGrade}
                    grade={certifierFinalGrades[areaIndex]}
                    onClick={this._onFinalGradeClick}
                    additionalClass="rate--final"
                    disabled={!data.mainCertifier}
                  >
                    {certifiers && certifiers[areaIndex] && (
                      <div className="certifiers">
                        {certifiers[areaIndex].map((item, key) => (
                          <div key={key} className="certifiers__item">
                            <h3>{item.certifier}</h3>
                            <h4>
                              {item.grade !== undefined ? item.grade : "-"}
                            </h4>
                            {item.comment && <p>{item.comment}</p>}
                          </div>
                        ))}
                      </div>
                    )}
                    <CommentList
                      entityId={activeArea.id}
                      comment={certifierFinalComments[areaIndex]}
                      onSave={this._certifierAreaFinalCommentOnSave}
                      disabled={!data.mainCertifier || data.gradingLocked}
                    />
                  </RateBox>
                )}
              </div>
            )}
          </>
        )}
        <Tabs
          active={activeAreaIndex}
          items={tabs}
          onClick={this._onTabClick}
        />
      </div>
    );
  }
}
