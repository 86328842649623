import classNames from "classnames";
import React, { Component } from "react";

import ReactQuill from "react-quill";
import "react-quill/dist/quill.bubble.css";

const editorModules = {
  syntax: false,
  toolbar: [
    ["bold", "italic", "underline"],
    [{ size: ["small", false, "large", "huge"] }],
    [{ list: "ordered" }, { list: "bullet" }],
    ["clean"],
  ],
};

export default class ReportAbHighlights extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentContent: this.props.value ? this.props.value : "",
    };
  }

  shouldComponentUpdate(nextProps, nextState) {
    return JSON.stringify(this.state) !== JSON.stringify(nextState);
  }

  _inputOnChange = (value) => {
    this.props.onChange({
      target: {
        name: this.props.name,
        value: value,
      },
    });

    this.setState({
      currentContent: value,
    });
  };

  _suggestionOnClick = (event) => {
    let current = this.state.currentContent;
    const adding = event.currentTarget.innerHTML;

    if (!current || current.indexOf("<ul>") === -1) {
      current = (current ? current : "") + `<ul><li>${adding}</li></ul>`;
    } else {
      current = current.replace("</ul>", `<li>${adding}</li></ul>`);
    }

    this.setState({
      currentContent: current,
    });
  };

  render() {
    const { id, additionalClass, background, content, suggestions, title } =
      this.props;

    const { currentContent } = this.state;

    return (
      <div
        className={classNames("report__page__wrapper", additionalClass)}
        id={id}
      >
        <div
          className="report__page report__page--ab"
          style={{
            backgroundImage: background ? `url(${background})` : "none",
          }}
        >
          <div className="report__section--padding">
            <div className="report_ab_highlights">
              <h2 dangerouslySetInnerHTML={{ __html: title }} />
              <ReactQuill
                className="report__input"
                theme="bubble"
                modules={editorModules}
                value={currentContent}
                defaultValue={content}
                onChange={this._inputOnChange}
              />
            </div>
          </div>
          <div className="report__ab__badges">
            <img alt="" src="/assets/images/report/ab/badge.png" />
          </div>
        </div>
        <div className="report__options">
          {suggestions.map((item, key) => (
            <div key={key} className="report__options__checkitem">
              <p
                onClick={this._suggestionOnClick}
                dangerouslySetInnerHTML={{ __html: item.content }}
              />
            </div>
          ))}
        </div>
      </div>
    );
  }
}
