import React, { Component } from "react";
import { Documents, Loader } from "../components";
import API from "../api";
import { getLanguage } from "../utils/utils";

const translations = {
  hr: {
    documents: "Dokumenti",
    additionalDocumentation: "Dodatna dokumentacija",
  },
  en: {
    documents: "Documents",
    additionalDocumentation: "Additional documentation",
  },
  sr: {
    documents: "Dokumenti",
    additionalDocumentation: "Dodatna dokumentacija",
  },
};

export default class DocumentList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      id: props.match.params.id,
      data: null,
      messages: translations[getLanguage()],
    };
  }

  componentDidMount() {
    this.loadData();
  }

  _documentOnUpload = async (event) => {
    let value = null;

    await API.post("", {
      ...{
        id: this.state.id,
        action: "questionnaireDocumentSubmit",
      },
      ...event.target,
    })
      .then((response) => {
        value = response.data;
      })
      .catch((error) => {
        value = {
          error: true,
        };

        alert(this.state.messages.errorAnswerNotSaved);
      });

    return value;
  };

  _documentOnDelete = async (documentId) => {
    await API.post("", {
      id: this.state.id,
      action: "questionnaireDocumentDelete",
      documentId: documentId,
    })
      .then((response) => {})
      .catch((error) => {});
  };

  loadData = async () => {
    await API.get("", {
      params: {
        id: this.state.id,
        action: "documentList",
        language: getLanguage(),
      },
    })
      .then((response) => {
        this.setState({
          data: response.data,
        });
      })
      .catch((error) => {});
  };

  render() {
    const { data, messages } = this.state;

    if (!data) {
      return <Loader />;
    }

    return (
      <div className="page__content">
        <article className="article">
          <h2>{messages.documents}</h2>
        </article>
        {data.map((area, key) => (
          <div key={key}>
            <h2 style={{ marginTop: "20px" }}>{area.title}</h2>
            {area.questions.map((question, questionKey) => (
              <div key={questionKey}>
                <h4 style={{ marginTop: "15px" }}>
                  {question.position}. {question.title}
                </h4>
                <Documents
                  items={question.documents}
                  inputName={`question_document_${area.id}`}
                  entityType="question"
                  entityId={question.id}
                  disabled={false}
                  onUpload={this._documentOnUpload}
                  onDelete={this._documentOnDelete}
                />
              </div>
            ))}
            <h4 style={{ marginTop: "15px" }}>
              {messages.additionalDocumentation}
            </h4>
            <Documents
              items={area.documents}
              inputName={`area_document_${area.id}`}
              entityType="area"
              entityId={area.id}
              disabled={false}
              onUpload={this._documentOnUpload}
              onDelete={this._documentOnDelete}
            />
          </div>
        ))}
      </div>
    );
  }
}
