import React, { Component } from 'react';
import ReportBadge from './ReportBadge';
import { forEmployer } from "./components/ReportTemplates";
import { getLanguage } from '../../utils/utils';

export default class ReportForEmployer extends Component {
  render() {
    return <div className="report__page__wrapper" id="page-about">
      <div className="report__page report_page--split_11">
        <div className="report__info report__section--padding" dangerouslySetInnerHTML={{__html: forEmployer[getLanguage()]}}/>
        <div className="report__default">
          <img alt="" src={`/assets/images/info/info-em-${getLanguage()}.png`}/>
        </div>
      </div>
      <ReportBadge/>
    </div>
  }
}