import React, { Component } from "react";
import { BarChart, Bar, LabelList, Legend, XAxis, YAxis } from "recharts";
import ReportContent from "./ReportContent";

export default class ReportBenchmark extends Component {
  render() {
    const { ab, benchmarkLabel, data, id, messages, subtitle, title } =
      this.props;

    return (
      <ReportContent ab={ab} id={id} title={title} subtitle={subtitle}>
        <div style={{ fontSize: "0.8rem", marginTop: "20px" }}>
          <BarChart
            width={700}
            height={350}
            data={data}
            layout="vertical"
            margin={{ top: 5, right: 35, bottom: 5, left: 5 }}
          >
            <Bar
              name={messages.yourOrganization}
              dataKey="selfValue"
              fill={ab ? "#f9d877" : "#3dc5f1"}
            >
              <LabelList
                position="right"
                formatter={(value) =>
                  value === 0.5111
                    ? "n/a"
                    : value === 0.5112
                    ? "0%"
                    : `${value}%`
                }
              />
            </Bar>
            <Bar name={benchmarkLabel} dataKey="otherValue" fill="#eaeaea">
              <LabelList
                position="right"
                formatter={(value) =>
                  value === 0.5111
                    ? "n/a"
                    : value === 0.5112
                    ? "0%"
                    : `${value}%`
                }
              />
            </Bar>
            <XAxis
              type="number"
              domain={[0, 100]}
              ticks={[0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100]}
            />
            <YAxis
              type="category"
              dataKey="content"
              width={300}
              style={{
                fontSize: "0.7rem",
              }}
            />
            <Legend
              align="left"
              wrapperStyle={{ left: 304 }}
              formatter={(value, entry, index) => (
                <span className="report__legend__label">{value}</span>
              )}
            />
          </BarChart>
        </div>
      </ReportContent>
    );
  }
}
