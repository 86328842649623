import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import classNames from "classnames";
import { getLanguage } from "../utils/utils";
import { CompanyContext } from "../context/CompanyContext";
import { LanguageContext } from "../context/LanguageContext";

const translations = {
  hr: {
    homepage: "Početna",
    logout: "Odjava",
  },
  en: {
    homepage: "Homepage",
    logout: "Log out",
  },
  sr: {
    homepage: "Početna",
    logout: "Odjava",
  },
};

export default class Header extends Component {
  constructor(props) {
    super(props);

    this.state = {
      mobileMenuOpen: false,
      showLogout: false,
      language: getLanguage(),
      messages: translations[getLanguage()],
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.showLogout !== prevProps.showLogout) {
      this.setState({
        showLogout: this.props.showLogout,
      });
    }
  }

  onMobileMenuClick = () => {
    this.setState({
      mobileMenuOpen: !this.state.mobileMenuOpen,
    });
  };

  hideMobileMenu = () => {
    this.setState({
      mobileMenuOpen: false,
    });
  };

  onLanguageClick = (event) => {
    event.preventDefault();
    const language = event.target.dataset.language;
    localStorage.setItem("language", language);
    this.hideMobileMenu();
    window.location.reload();
  };

  render() {
    let headerClassNames = ["header"];

    if (this.state.mobileMenuOpen) {
      headerClassNames.push("header--open");
    }

    const { messages } = this.state;

    return (
      <LanguageContext.Consumer>
        {({ language, getLanguages, setLanguage }) => (
          <CompanyContext.Consumer>
            {({ name }) => (
              <header className={headerClassNames.join(" ")}>
                <div className="wrap">
                  <div className="desktop-menu">
                    <div className="header__logo">
                      <NavLink onClick={this.hideMobileMenu} exact to="/">
                        <img
                          alt="CEP"
                          src={`/assets/logo_${getLanguage()}.svg`}
                        />
                      </NavLink>
                    </div>
                    <div className="header__company">{name}</div>
                    <div className="header__menu">
                      <NavLink
                        onClick={this.hideMobileMenu}
                        to="/"
                        className="header__link"
                        exact
                      >
                        {messages.homepage}
                      </NavLink>
                      <NavLink
                        onClick={this.hideMobileMenu}
                        to="/login"
                        className="header__link"
                        exact
                      >
                        {messages.logout}
                      </NavLink>
                      | &nbsp;
                      {getLanguages().map((item, key) => (
                        <span
                          key={key}
                          data-language={item}
                          onClick={() => {
                            setLanguage(item);
                          }}
                          className={classNames(
                            "header__link",
                            "header__link__language",
                            { "header__link--active": language === item }
                          )}
                        >
                          {item.toUpperCase()}
                        </span>
                      ))}
                    </div>
                  </div>
                  <div className="mobile-menu">
                    <img
                      onClick={this.onMobileMenuClick}
                      className="mobile-menu__trigger"
                      alt=""
                      src="/assets/icons/menu.svg"
                    />
                  </div>
                </div>
              </header>
            )}
          </CompanyContext.Consumer>
        )}
      </LanguageContext.Consumer>
    );
  }
}
