import React, { Component } from "react";
import { getLanguage } from "../utils/utils";

const translations = {
  hr: {
    hrProcess: "HR proces",
    measures: "Mjere",
    target: "Target",
  },
  en: {
    hrProcess: "HR process",
    measures: "Measures",
    target: "Target",
  },
  sr: {
    hrProcess: "HR proces",
    measures: "Mjere",
    target: "Target",
  },
};

const list = {
  hr: [
    { id: "1", label: "I. upravljanje organizacijskom kulturom" },
    { id: "2", label: "II. employer branding" },
    { id: "3", label: "III. regrutiranje i selekcija" },
    { id: "4", label: "IV. praćenje radne uspješnosti" },
    { id: "5", label: "V. nagrađivanje i benefiti" },
    { id: "6", label: "VI. usavršavanje i razvoj zaposlenika" },
    { id: "7", label: "VII. razvoj karijere i napredovanje" },
    { id: "8", label: "VIII. nasljeđivanje" },
    { id: "9", label: "IX. razvoj talenata" },
    { id: "10", label: "X. razvoj menadžera" },
    { id: "11", label: "XI. zadržavanje i upravljanje znanjem u organizaciji" },
    { id: "12", label: "XII. interna komunikacija" },
    { id: "13", label: "XIII. razvoj kulture inovativnosti" },
    { id: "14", label: "XIV. upravljanje raznolikošću i inkluzija" },
    { id: "15", label: "XV. briga o dobrobiti zaposlenika" },
    { id: "16", label: "XVI. angažiranost zaposlenika" },
    {
      id: "17",
      label: "XVII. organizacijska klima / zadovoljstvo zaposlenika",
    },
    { id: "18", label: "XVIII. zadržavanje u organizaciji" },
    { id: "19", label: "XIX. offboarding" },
    { id: "20", label: "XX. onboarding" },
    { id: "21", label: "XXI. sistematizacija radnih mjesta i opisi poslova" },
    { id: "22", label: "XXII. balans privatno-poslovno" },
  ],
  en: [
    { id: "1", label: "I. organisational culture management" },
    { id: "2", label: "II. employer branding" },
    { id: "3", label: "III. recruitment and selection" },
    { id: "4", label: "IV. performance management" },
    { id: "5", label: "V. compensation and benefits" },
    { id: "6", label: "VI. training and development" },
    { id: "7", label: "VII. career development and promotion" },
    { id: "8", label: "VIII. succession" },
    { id: "9", label: "IX. talent management" },
    { id: "10", label: "X. development of managers' competencies" },
    {
      id: "11",
      label: "XI. retention and knowledge management in the organisation",
    },
    { id: "12", label: "XII. internal communication" },
    { id: "13", label: "XIII. developing a culture of innovation" },
    { id: "14", label: "XIV. diversity management and inclusion" },
    { id: "15", label: "XV. taking care of employees' well-being" },
    { id: "16", label: "XVI. employee engagement" },
    { id: "17", label: "XVII. organisational climate/employee satisfaction" },
    { id: "18", label: "XVIII. retention of employees" },
    { id: "19", label: "XIX. offboarding" },
    { id: "20", label: "XX. onboarding" },
    { id: "21", label: "XXI. organisational design" },
    { id: "22", label: "XXII. work-life balance" },
  ],
  sr: [
    { id: "1", label: "I. upravljanje organizacionom kulturom" },
    { id: "2", label: "II. employer branding" },
    { id: "3", label: "III. regrutacija i selekcija" },
    { id: "4", label: "IV. praćenje radne uspešnosti" },
    { id: "5", label: "V. nagrađivanje i benefiti" },
    { id: "6", label: "VI. usavršavanje i razvoj zaposlenih" },
    { id: "7", label: "VII. razvoj karijere i napredovanje" },
    { id: "8", label: "VIII. nasleđivanje" },
    { id: "9", label: "IX. razvoj talenata" },
    { id: "10", label: "X. razvoj menadžera" },
    { id: "11", label: "XI. zadržavanje i upravljanje znanjem u organizaciji" },
    { id: "12", label: "XII. interna komunikacija" },
    { id: "13", label: "XIII. razvoj kulture inovativnosti" },
    { id: "14", label: "XIV. upravljanje raznolikošću i inkluzija" },
    { id: "15", label: "XV. briga o dobrobiti zaposlenih" },
    { id: "16", label: "XVI. angažovanost zaposlenih" },
    { id: "17", label: "XVII. organizaciona klima / zadovoljstvo zaposlenih" },
    { id: "18", label: "XVIII. zadržavanje u organizaciji" },
    { id: "19", label: "XIX. offboarding" },
    { id: "20", label: "XX. onboarding" },
    { id: "21", label: "XXI. sistematizacija radnih mesta i opisi poslova" },
    { id: "22", label: "XXII. balans privatno-poslovno" },
  ],
};

export default class QuestionTargets extends Component {
  constructor(props) {
    super(props);

    let data = {};

    try {
      data = JSON.parse(this.props.data.value);
    } catch (error) {}

    this.state = {
      data: data,
      items: list[getLanguage()],
      messages: translations[getLanguage()],
    };
  }

  _inputOnChange = (event) => {
    let data = this.state.data;
    data[event.target.name] = event.target.value;

    this.setState({
      data: data,
    });

    this.props.onChange({
      target: {
        name: this.props.name,
        value: JSON.stringify(this.state.data),
        dataset: {
          id: this.props.id,
          question: this.props.question,
        },
      },
    });
  };

  render() {
    const { data, items, messages } = this.state;

    return (
      <table className="table">
        <thead>
          <tr>
            <th>{messages.hrProcess}</th>
            <th>{messages.measures}</th>
            <th>{messages.target}</th>
          </tr>
        </thead>
        <tbody>
          {items.map((item, key) => (
            <tr key={key}>
              <td>{item.label}</td>
              <td style={{ whiteSpace: "nowrap" }}>
                <textarea
                  type="text"
                  name={`subfunction_measure_${item.id}`}
                  value={
                    data[`subfunction_measure_${item.id}`]
                      ? data[`subfunction_measure_${item.id}`]
                      : ""
                  }
                  className="input input-area input-area-table"
                  onChange={this._inputOnChange}
                />
              </td>
              <td>
                <textarea
                  type="text"
                  name={`subfunction_target_${item.id}`}
                  value={
                    data[`subfunction_target_${item.id}`]
                      ? data[`subfunction_target_${item.id}`]
                      : ""
                  }
                  className="input input-area input-area-table"
                  onChange={this._inputOnChange}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  }
}
