import React, { Component } from "react";

export default class ReportCustomBenchmark extends Component {
  render() {
    const { benchmarks, labelAverage } = this.props;

    return (
      <div className="report_benchmark">
        <div className="report_benchmark__reference">
          <div className="report_benchmark__reference__line"></div>
          <div className="report_benchmark__reference__label">
            {labelAverage}
          </div>
        </div>
        <div className="report_benchmark__items">
          {benchmarks.map((item, key) => (
            <div key={key} className="report_benchmark__item">
              <div
                className="report_benchmark__item__label"
                dangerouslySetInnerHTML={{
                  __html: item.content
                    .replace(
                      "XXX",
                      item.selfValue === 0.5111
                        ? "n/a"
                        : item.selfValue === 0.5112
                        ? "0"
                        : item.selfValue
                    )
                    .replace("n/a%", "n/a"),
                }}
              ></div>
              <div className="report_benchmark__item__reference">
                {item.otherValue} {item.unit}
              </div>
              <div className="report_benchmark__item__line">
                <div
                  className="report_benchmark__item__line__indicator"
                  style={{
                    width: `${Math.min(
                      100,
                      (50 * item.selfValue) / item.otherValue
                    )}%`,
                  }}
                ></div>
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  }
}
