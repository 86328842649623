import React, { Component } from 'react';
import ReportBadge from './ReportBadge';
import classNames from 'classnames';

export default class ReportChart extends Component {
  render() {
    const {
      id,
      className,
    } = this.props;

    return <div className="report__page__wrapper" id={id}>
      <div className={classNames('report__page', 'report_page--split_12', className)}>
        <div className="report__info report__section--padding" style={{ paddingRight: 0 }}>
          <h2 dangerouslySetInnerHTML={{__html: this.props.title}}/>
          <h3 dangerouslySetInnerHTML={{__html: this.props.subtitle}}/>
          <div dangerouslySetInnerHTML={{__html: this.props.description}}/>
        </div>
        <div className="report__chart">
          {this.props.children}
        </div>
      </div>
      {this.props.options}
      <ReportBadge light={ className === 'report__page--dark' }/>
    </div>
  }
}