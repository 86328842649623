import React, { Component } from "react";
import { getLanguage } from "../../utils/utils";
import { officialConfirmation } from "./components/ReportTemplates";

export default class ReportConfirmation extends Component {
  render() {
    const { certifiers } = this.props;

    return (
      <div className="report__page__wrapper" id="page-confirmation">
        <div className="report__page">
          <div className="report_confirmation report__section--padding">
            <div className="report_confirmation__info" dangerouslySetInnerHTML={{__html: officialConfirmation[getLanguage()]}} />
            <div className="report_certifier_list">
              <div className="report_certifier">
                <h2>{certifiers.first}</h2>
                <p>{certifiers.firstSubtitle}</p>
                <p>{certifiers.firstTitle}</p>
              </div>
              <div className="report_certifier">
                <h2>{certifiers.second}</h2>
                <p>{certifiers.secondSubtitle}</p>
                <p>{certifiers.secondTitle}</p>
              </div>
              <div className="report_certifier">
                <h2>{certifiers.third}</h2>
                <p>{certifiers.thirdSubtitle}</p>
                <p>{certifiers.thirdTitle}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
