import React, { Component } from "react";
import Questionnaires from "./Questionnaires";
export default class Home extends Component {
  componentDidMount() {
    this.props.showLogout(true);
  }

  render() {
    return (
      <div className="home">
        <Questionnaires />
      </div>
    );
  }
}
