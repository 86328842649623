import React, { Component } from "react";
const classNames = require("classnames");

export default class NumberInput extends Component {
  _inputOnChange = (event) => {
    this.props.onChange(event);
  };

  render() {
    const {
      id,
      copied,
      disabled,
      hidden,
      label,
      min,
      max,
      name,
      question,
      type,
      value,
      previousValue,
    } = this.props;

    return (
      <div className="input_box--number">
        <label data-id={id}>
          <span data-id={id} dangerouslySetInnerHTML={{ __html: label }} />
          {hidden !== true && (
            <>
              <input
                className={classNames(
                  "input",
                  "input-number",
                  { "input--new": copied },
                  { "input-percentage": type === "percentage" }
                )}
                type="number"
                min={min}
                max={max}
                step="1"
                data-question={question}
                data-id={id}
                name={name}
                value={value ? value : ""}
                onChange={this._inputOnChange}
                disabled={disabled}
              />
              {type === "percentage" && (
                <span
                  className={classNames("input__sufix", {
                    "input__sufix--new": copied,
                  })}
                >
                  %
                </span>
              )}
              {previousValue && (
                <div className="input__previous">{previousValue}</div>
              )}
            </>
          )}
        </label>
      </div>
    );
  }
}
