export function getLanguage() {
  const enabledLanguages = ["hr", "sr", "en"];
  let language = localStorage.getItem("language");

  const urlData = window.location.pathname.split("/");

  if (urlData[1] && enabledLanguages.includes(urlData[1])) {
    language = urlData[1];
  }

  return enabledLanguages.indexOf(language) > -1
    ? language
    : enabledLanguages[0];
}

export function getCountries() {
  return ["al", "at", "ba", "hr", "hu", "me", "mk", "pl", "rs", "si"];
}
