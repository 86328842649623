import React, { Component } from 'react';

export default class Language extends Component {
  componentDidMount() {
    const language = window.location.pathname.replace('/', '');
    localStorage.setItem('language', language);
    window.location.href = '/';
  }

  componentWillMount() {

  }

	render() {
		return (
			<div className="page__content">
			</div>
		)
	}
}