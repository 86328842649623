import React, { Component } from "react";
import ReportBadge from "./ReportBadge";

import ReactQuill from "react-quill";
import "react-quill/dist/quill.bubble.css";

const editorModules = {
  syntax: false,
  toolbar: [
    ["bold", "italic", "underline"],
    [{ size: ["small", false, "large", "huge"] }],
    [{ list: "ordered" }, { list: "bullet" }],
    ["clean"],
  ],
};

export default class ReportEducations extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentContent: this.props.content,
    };
  }

  shouldComponentUpdate(nextProps, nextState) {
    return JSON.stringify(this.state) !== JSON.stringify(nextState);
  }

  _inputOnChange = (value) => {
    this.props.onChange({
      target: {
        name: "educations",
        value: value,
      },
    });

    this.setState({
      currentContent: value,
    });
  };

  _suggestionOnClick = (event) => {
    let current = this.state.currentContent;
    const adding = event.currentTarget.innerHTML;

    if (!current || current.indexOf("<ul>") === -1) {
      current = (current ? current : "") + `<ul><li>${adding}</li></ul>`;
    } else {
      current = current.replace("</ul>", `<li>${adding}</li></ul>`);
    }

    this.setState({
      currentContent: current,
    });
  };

  render() {
    const { id, content, editable, subtitle, suggestions, title } = this.props;

    const { currentContent } = this.state;

    return (
      <div className="report__page__wrapper" id="page-educations">
        <div className="report__page report__page--educations">
          <div className="report__info report__section--padding">
            <h2>{title}</h2>
            <p>{subtitle}</p>
            <ReactQuill
              readOnly={!editable}
              key={id}
              className="report__input"
              theme="bubble"
              modules={editorModules}
              value={currentContent}
              defaultValue={content}
              onChange={this._inputOnChange}
            />
          </div>
        </div>
        {suggestions && (
          <div className="report__options">
            {suggestions.map((item, key) => (
              <div key={key} className="report__options__checkitem">
                <p
                  onClick={editable ? this._suggestionOnClick : null}
                  dangerouslySetInnerHTML={{ __html: item.content }}
                />
              </div>
            ))}
          </div>
        )}
        <ReportBadge />
      </div>
    );
  }
}
