import React, { Component } from "react";

export default class ReportLegend extends Component {
  render() {
    const { first, second } = this.props;

    return (
      <div className="report_legend">
        <div className="report_legend_item">
          <div className="report_legend_color report_legend_color--primary"></div>
          <div className="report_legend_label">{first}</div>
        </div>
        <div className="report_legend_item">
          <div className="report_legend_color report_legend_color--highlight"></div>
          <div className="report_legend_label">{second}</div>
        </div>
      </div>
    );
  }
}
