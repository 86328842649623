import React, { Component } from "react";
import API from "../api";
import { getLanguage } from "../utils/utils";
import { NavLink } from "react-router-dom/cjs/react-router-dom.min";

export default class QuestionList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      id: props.match.params.id,
      data: null,
    };
  }

  componentDidMount() {
    this.loadData();
  }

  loadData = async () => {
    await API.get("", {
      params: {
        id: this.state.id,
        action: "questions",
        language: getLanguage(),
      },
    })
      .then((response) => {
        this.setState({
          data: response.data,
        });
      })
      .catch((error) => {});
  };

  render() {
    const { data } = this.state;

    if (!data) {
      return <div className="loading">Učitavam...</div>;
    }

    return (
      <div className="page__content">
        <article className="article">
          <h2>Pitanja</h2>
        </article>
        {data.map((area, key) => (
          <div key={key}>
            <h3>{area.title}</h3>
            {area.questions.map((question, key) => (
              <div style={{ display: "flex", padding: "10px 0" }}>
                <div style={{ flex: 1 }}>
                  <NavLink to="/test">{question.question}</NavLink>
                </div>
                <div style={{ padding: "0 10px" }}>
                  {question.documentCount === 0 ? (
                    <img
                      style={{ width: "30px" }}
                      alt=""
                      src="https://uxwing.com/wp-content/themes/uxwing/download/checkmark-cross/red-x-icon.png"
                    />
                  ) : (
                    <img
                      style={{ width: "30px" }}
                      alt=""
                      src="https://freeiconshop.com/wp-content/uploads/edd/checkmark-flat.png"
                    />
                  )}
                </div>
                <div style={{ padding: "0 10px" }}></div>
              </div>
            ))}
          </div>
        ))}
      </div>
    );
  }
}
