/* eslint-disable jsx-a11y/anchor-has-content */
import React, { Component } from "react";
import { Helmet } from "react-helmet";
import {
  ReportAbHighlights,
  ReportCategoryChart,
  ReportCategoryHightlightsSuggestions,
  ReportChart,
  ReportConfirmation,
  ReportContent,
  ReportCover,
  ReportHighlights,
  ReportIndex,
  ReportInfo,
  ReportIntro,
  ReportOutro,
  ReportOveral,
  ReportEducations,
  ReportForEmployer,
  ReportTimeline,
  ReportBenchmark,
  ReportCustomBenchmark,
} from "../components/report";
import {
  BarChart,
  Bar,
  ComposedChart,
  LabelList,
  Legend,
  PolarGrid,
  PolarAngleAxis,
  PolarRadiusAxis,
  Radar,
  RadarChart,
  ReferenceLine,
  XAxis,
  YAxis,
} from "recharts";
import { ReportCircleChart } from "../components/report/components";
import {
  audit,
  abAbout,
  abAboutCerifiers,
  abMethodology,
  whatsNext,
  abAboutCertificate,
  abEpMethodology,
  abEvaluation,
  abExecSummary,
  abCategories,
  abFuture,
  abInclusion,
  abInnovation,
  abImpact,
  abSatisfaction,
} from "../components/report/components/ReportTemplates";
import { getCountries, getLanguage } from "../utils/utils";
import API from "../api";
import classNames from "classnames";
import "./css/report.css";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ReportFullInput from "../components/report/ReportFullInput";
import { NavLink } from "react-router-dom";
import { Loader } from "../components";
import ReportLegend from "../components/report/ReportLegend";

const abCategoryDescription = {
  future: abFuture,
  inclusion: abInclusion,
  innovation: abInnovation,
  impact: abImpact,
  satisfaction: abSatisfaction,
};

const translations = {
  hr: {
    aboveBeyond: "Above&Beyond",
    employerPartner: "Employer Partner",
    report: "Izvješće",
    download: "Preuzmi",
    loading: "Učitavam...",
    overallResult: "Ukupni rezultat",
    overallResultIntro:
      "Stručnjaci za ljudske resurse procjenili su vaše HR procese kroz 7 <em>employee life cycle</em> koraka i uporedili ih s najboljim svjetskim praksama. Proces evaluacije temelji se na unapred definiranim standardima.<br/><br/>Evaluacija vaših praksi upravljanja ljudskim potencijalima pokazala je sljedeći rezultat:",
    benchmarkAnalysis: "<em>Benchmark</em> analiza",
    yourOrganization: "Vaša organizacija",
    employerPartnerAvg: "Employer Partner avg.",
    aboveBeyondAvg: "Above&Beyond avg.",
    highlights: "Snage",
    suggestions: "Sugestije",
    resultsKeyTopics: "Rezultati na ključnim temama",
    resultsKeyTopicsDescription:
      "Evaluacija je napravljena i za 10 ključnih tema <em>employee life cycle</em> modela u odnosu na najbolje svjetske prakse. <br/><br/>Evaluacija je pokazala sljedeći rezultat",
    resultsELCSteps: "Rezultati na <em>employee life cycle</em> koracima",
    resultsDiveIn: "Dublji pogled u rezultate",
    resultsDiveInDescription:
      "Nakon što ste proučili sve glavne rezultate koje ste postigli, donosimo vam prijedloge za poboljšanje praksi upravljanja ljudskim resursima prilagođene upravo vama i detaljan <em>benchmark</em> koji će vas usmjeriti u pravom smjeru.",
    whatsNext: "Što je sljedeće?",
    educationTitle: "Grow to lead, lead to grow.",
    educationIntro:
      "Prema vašim potrebama odabrali smo edukacije koje smatramo korisnima za vaš razvoj:",
    yourCareTitle: "Vaša briga za zaposlenike u brojevima",
    yourCareTitleMultiline:
      "Vaša briga za zaposlenike<br/><strong>u brojevima</strong>",
    keepInMindTitle: "Razmislite o <strong>povećanju</strong> ovih KPI-eva",
    outroTitle: "Vrijeme je da<br/>podijelite<br/>svoj uspjeh!",
    outroAuditTitle:
      "Zajedno<br/> gradimo odlična<br/> radna mjesta<br/> diljem svijeta",
    changesError: "Došlo je do greškre prilikom spremanja izmjena",
    benchmarkTitle: "Benchmark",
    benchmarkSubmit: "Spremi",
    countryHR: "Hrvatska",
    countryRS: "Srbija",
    country: "Država",
    countries: {
      al: "Albanija",
      at: "Austrija",
      ba: "Bosna i Hercegovina",
      hr: "Hrvatska",
      hu: "Mađarska",
      me: "Crna Gora",
      mk: "Makedonija",
      pl: "Poljska",
      rs: "Srbija",
      si: "Slovenija",
    },
    industry: "Industrija",
    primaryBusinessType: {
      1: "FMCG",
      2: "Financijska",
      3: "ICT",
      4: "Farmaceutska",
      5: "Turizam i ugostiteljstvo",
      6: "Maloprodaja",
      7: "Prerađivačka",
      8: "Stručne djelatnosti",
      9: "Mediji",
      10: "Igre na sreću",
      11: "Javni sektor",
      12: "Energetska",
      13: "Ostalo",
    },
    yourResults: "Vaši rezultati",
    yourResultsIntro:
      "U nastavku donosimo vaš ukupni rezultat kao i glavne rezultate koje ste postigli u odnosu na prosjek rezultata koje ostvaruju druge organizacije.",
    neededActivitiesInvestment:
      "Potrebne aktivnosti: kontinuirano ulaganje u zaposlenike",
    neededActivitiesHr: "Potrebne aktivnosti: HR procesi",
    neededActivitiesStrategy:
      "Potrebne aktivnosti: strategija i strateški kapaciteti",
    strengthsStrategy: "Vaše snage: strategija i strateški kapaciteti",
    strengthsInvestment: "Vaše snage: kontinuirano ulaganje u zaposlenike",
    strengthsProcesses: "Vaše snage: HR procesi",
    actionPlanPriorities: "Prioriteti akcijskog plana",
    actionPlanTimeline: "<em>Timeline</em> za provođenje akcijskog plana",
    statusQuoRisks: "Rizici statusa quo",
    companyGroup: "Grupacija",
    doNotShow: "Sakrij",
    show: "Prikaži",
    reportLocked: "Ovaj izvještaj je zaključan za uređivanje.",
    abCoverIntro: "Čestitamo",
    abCoverOutro: "na stjecanju Above and Beyond certifikata!",
    abCoverInfo:
      "Pokazali ste izvrsnost u upravljanju ljudskim potencijalima u Employer Partner certificiranju, a sada ste dosegli i <strong>najvišu razinu kvalitete HR-a.</strong> Ovom certifikacijom potvrdili ste svoje mjesto među <strong>10% najboljih Poslodavaca Partnera.</strong>",
    abCoverCertificated: "Godina certifikacije:",
    abResultsTitle:
      'Pregled <span style={{ color: "#dfaf56" }}>rezultata</span>',
    abResultsInfo:
      "<p>U Employer Partner certifikaciji postigli ste natprosječan ukupni rezultat zbog čega ste ušli u Above and Beyond certifikaciju.</p><p>Povrh toga, dosegli ste Above and Beyond standard koji vas je postavio na 10% najboljih Poslodavca Partnera.</p>",
    abResultsCategoriesTitle:
      'Rezultati u <span style={{ color: "#dfaf56" }}>kategorijama</span>',
    abResultsCategoriesInfo:
      "Ovdje možete vidjeti usporedbu svojih rezultata u svim Above and Beyond kategorijama.",
    abWhatYouDoBest: "Što radite najbolje?",
    abHighlightedNumbers: `<span style="color:#dfaf56">Istaknuti brojevi </span> iz Above and Beyond rezultata`,
    abWordsFromCertificationTeam: `Par riječi<br/> <span style="color:#dfaf56">certifikacijskog tima</span>`,
  },
  en: {
    aboveBeyond: "Above&Beyond",
    employerPartner: "Employer Partner",
    report: "Report",
    download: "Download",
    loading: "Loading...",
    overallResult: "Overall result",
    overallResultIntro:
      "The 7 steps of the Employee Life Cycle are evaluated by the HR Experts from the Certification Team and compared to the best global practices. The process of evaluation is based on strong principles to have one standard for all.<br/><br/>The overall evaluation has shown the following result:",
    benchmarkAnalysis: "Benchmark Analysis",
    yourOrganization: "Your organization",
    employerPartnerAvg: "Employer Partner avg.",
    aboveBeyondAvg: "Above&Beyond avg.",
    highlights: "Highlights",
    suggestions: "Suggestions",
    resultsKeyTopics: "Results on the key topics",
    resultsKeyTopicsDescription:
      "The 10 key topics of the Employee Life Cycle are evaluated and compared to the best global practices.<br/><br/>The overall evaluation has shown the following result:",
    resultsELCSteps: "Results on the Employee Life Cycle steps",
    resultsDiveIn: "Let's dive in!",
    resultsDiveInDescription:
      "After reading all the main results that you achieved, we bring you tailor-made suggestions for improving HR practices and in-depth benchmark that will navigate you in the right direction.",
    whatsNext: "What's next",
    educationTitle: "Grow to lead, lead to grow.",
    educationIntro:
      "According to your needs, we selected educations that we find useful for your growth:",
    yourCareTitle: "Your care for employees in numbers",
    yourCareTitleMultiline:
      "Your care for employees<br/><strong>in numbers</strong>",
    keepInMindTitle: "Keep in mind to <strong>increase</strong> these KPIs",
    outroTitle: "It is time to<br/> share your<br/> success!",
    outroAuditTitle:
      "Together<br/> we build great<br/> work places<br/> around the world",
    changesError: "There was an error saving changes.",
    benchmarkTitle: "Benchmark",
    benchmarkSubmit: "Save",
    countryHR: "Croatia",
    countryRS: "Serbia",
    country: "Country",
    countries: {
      al: "Albania",
      at: "Austria",
      ba: "Bosnia and Herzegovina",
      hr: "Croatia",
      hu: "Hungary",
      me: "Montenegro",
      mk: "Macedonia",
      pl: "Poland",
      rs: "Serbia",
      si: "Slovenia",
    },
    industry: "Industry",
    primaryBusinessType: {
      1: "FMCG",
      2: "Finance",
      3: "ICT",
      4: "Pharma",
      5: "Hospitality",
      6: "Retail",
      7: "Industrial",
      8: "Professional Services",
      9: "Media",
      10: "Gaming",
      11: "Public Sector",
      12: "Energy",
      13: "Other",
    },
    yourResults: "Your results",
    yourResultsIntro:
      "We present your overall result as well as the main results you have achieved in relation to the average results achieved by other organizations.",
    neededActivitiesInvestment:
      "Required activities: continuous investment in employees",
    neededActivitiesHr: "Required activities: HR processes",
    neededActivitiesStrategy:
      "Required activities: strategy and strategic capacities",
    strengthsStrategy: "Your strengths: strategy and strategic capacities",
    strengthsInvestment: "Your strengths: continuous investment in employees",
    strengthsProcesses: "Your strengths: HR processes",
    actionPlanPriorities: "Action plan priorities",
    actionPlanTimeline: "Timeline for action plan implementation",
    statusQuoRisks: "Risks of the status quo",
    companyGroup: "Group",
    doNotShow: "Do not show",
    show: "Show",
    reportLocked: "This report is locked for editing.",
    abCoverIntro: "Congratulations",
    abCoverOutro: "for reaching Above and Beyond!",
    abCoverInfo:
      "This company showed excellence in human resources management in Employer Partner certification, and now it achieved <strong>the highest level of HR quality.</strong> With this certification, it confirmed its place in the <strong>top 10% of Employer Partners.</strong>",
    abCoverCertificated: "Certificated in:",
    abResultsTitle: 'Results <span style={{ color: "#dfaf56" }}>summary</span>',
    abResultsInfo:
      "<p>In the Employer Partner certification, you reached above average total score which is why you entered the Above and Beyond certification.</p><p>On top of that, you reached the Above and Beyond standard which placed you in the top 10% of Employer Partners.</p>",
    abResultsCategoriesTitle:
      'Results in <span style={{ color: "#dfaf56" }}>categories</span>',
    abResultsCategoriesInfo:
      "Here you can see a comparison of your results in all of the Above and Beyond categories.",
    abWhatYouDoBest: "What do you do best?",
    abHighlightedNumbers: `<span style="color:#dfaf56">Highlighted numbers</span> from Above and Beyond results`,
    abWordsFromCertificationTeam: `A few words from the<br/> <span style="color:#dfaf56">certification team</span>`,
  },
  sr: {
    aboveBeyond: "Above&Beyond",
    employerPartner: "Employer Partner",
    report: "Izveštaj",
    download: "Preuzmi",
    loading: "Učitavam...",
    overallResult: "Ukupni rezultat",
    overallResultIntro:
      "Stručnjaci za ljudske resurse procenili su vaše HR procese kroz 7 <em>employee life cycle</em> koraka i uporedili ih s najboljim svetskim praksama. Proces evaluacije temelji se na unapred definisanim standardima.<br/><br/>Evaluacija vaših praksi upravljanja ljudskim potencijalima pokazala je sledeći rezultat:",
    benchmarkAnalysis: "<em>Benchmark</em> analiza",
    yourOrganization: "Vaša organizacija",
    employerPartnerAvg: "Employer Partner prosek",
    aboveBeyondAvg: "Above&Beyond avg.",
    highlights: "Snage",
    suggestions: "Sugestije",
    resultsKeyTopics: "Rezultati na ključnim temama",
    resultsKeyTopicsDescription:
      "Evaluacija je napravljena i za 10 ključnih tema <em>employee life cycle</em> modela u odnosu na najbolje svetske prakse. <br/><br/>Evaluacija je pokazala sledeći rezultat:",
    resultsELCSteps: "Rezultati na <em>employee life cycle</em> koracima",
    resultsDiveIn: "Dublji pogled u rezultate",
    resultsDiveInDescription:
      "Nakon što ste proučili sve glavne rezultate koje ste postigli, donosimo vam predloge za poboljšanje praksi upravljanja ljudskim resursima prilagođene upravo vama i detaljan <em>benchmark</em> koji će vas uputiti u pravom smeru.",
    whatsNext: "Što je sledeće?",
    educationTitle: "Grow to lead, lead to grow.",
    educationIntro:
      "Prema vašim potrebama odabrali smo edukacije koje smatramo korisnima za vaš razvoj:",
    yourCareTitle: "Vaša briga za zaposlene u brojevima",
    yourCareTitleMultiline:
      "Vaša briga za zaposlene<br/><strong>u brojevima</strong>",
    keepInMindTitle: "Razmislite o <strong>povećanju</strong> ovih KPI-ja",
    outroTitle: "Vreme je da<br/>podelite svoj<br/>uspeh!",
    outroAuditTitle:
      "Zajedno<br/> gradimo odlična<br/> radna mjesta<br/> diljem svijeta",
    changesError: "Došlo je do greškre prilikom spremanja izmjena",
    benchmarkTitle: "Benchmark",
    benchmarkSubmit: "Spremi",
    countryHR: "Hrvatska",
    countryRS: "Srbija",
    country: "Država",
    countries: {
      al: "Albanija",
      at: "Austrija",
      ba: "Bosna i Hercegovina",
      hr: "Hrvatska",
      hu: "Mađarska",
      me: "Crna Gora",
      mk: "Makedonija",
      pl: "Poljska",
      rs: "Srbija",
      si: "Slovenija",
    },
    industry: "Industrija",
    primaryBusinessType: {
      1: "FMCG",
      2: "Finansijska",
      3: "ICT",
      4: "Farmaceutska",
      5: "Turizam i ugostiteljstvo",
      6: "Maloprodaja",
      7: "Proizvodna",
      8: "Stručne delatnosti",
      9: "Mediji",
      10: "Igre na sreću",
      11: "Javni sektor",
      12: "Energetska",
      13: "Ostalo",
    },
    yourResults: "Vaši rezultati",
    yourResultsIntro:
      "U nastavku donosimo vaš ukupni rezultat kao i glavne rezultate koje ste postigli u odnosu na prosjek rezultata koje ostvaruju druge organizacije.",
    neededActivitiesInvestment:
      "Potrebne aktivnosti: kontinuirano ulaganje u zaposlenike",
    neededActivitiesHr: "Potrebne aktivnosti: HR procesi",
    neededActivitiesStrategy:
      "Potrebne aktivnosti: strategija i strateški kapaciteti",
    strengthsStrategy: "Vaše snage: strategija i strateški kapaciteti",
    strengthsInvestment: "Vaše snage: kontinuirano ulaganje u zaposlenike",
    strengthsProcesses: "Vaše snage: HR procesi",
    actionPlanPriorities: "Prioriteti akcijskog plana",
    actionPlanTimeline: "<em>Timeline</em> za provođenje akcijskog plana",
    statusQuoRisks: "Rizici statusa quo",
    companyGroup: "Grupacija",
    doNotShow: "Sakrij",
    show: "Prikaži",
    reportLocked: "Ovaj izveštaj je zaključan za uređivanje.",
    abCoverIntro: "Congratulations",
    abCoverOutro: "for reaching Above and Beyond!",
    abCoverInfo:
      "This company showed excellence in human resources management in Employer Partner certification, and now it achieved <strong>the highest level of HR quality.</strong> With this certification, it confirmed its place in the <strong>top 10% of Employer Partners.</strong>",
    abCoverCertificated: "Certificated in:",
    abResultsTitle: 'Results <span style={{ color: "#dfaf56" }}>summary</span>',
    abResultsInfo:
      "<p>In the Employer Partner certification, you reached above average total score which is why you entered the Above and Beyond certification.</p><p>On top of that, you reached the Above and Beyond standard which placed you in the top 10% of Employer Partners.</p>",
    abResultsCategoriesTitle:
      'Results in <span style={{ color: "#dfaf56" }}>categories</span>',
    abResultsCategoriesInfo:
      "Here you can see a comparison of your results in all of the Above and Beyond categories.",
    abWhatYouDoBest: "What do you do best?",
    abHighlightedNumbers: `<span style="color:#dfaf56">Highlighted numbers</span> from Above and Beyond results`,
    abWordsFromCertificationTeam: `A few words from the<br/> <span style="color:#dfaf56">certification team</span>`,
  },
};

export default class Report extends Component {
  constructor(props) {
    super(props);

    this.state = {
      id: this.props.match.params.id,
      ab: false,
      data: null,
      categories: [],
      content: null,
      contentUpdated: {},
      saveTimeout: null,
      messages: translations[getLanguage()],
      benchmark: false,
      benchmarkLoading: false,
      benchmarkCountry: null,
      benchmarkIndustry: null,
      benchmarkGroup: null,
      editable: false,
    };
  }

  componentDidMount() {
    this.loadData();
  }

  loadData = async () => {
    await API.get("", {
      params: {
        action: "companyCategories",
      },
    })
      .then((response) => {
        this.setState({
          categories: response.data,
        });
      })
      .catch((error) => {});

    await API.get("", {
      params: {
        id: this.state.id,
        action: "report",
        benchmarkCountry: this.state.benchmarkCountry,
        benchmarkIndustry: this.state.benchmarkIndustry,
        benchmarkGroup: this.state.benchmarkGroup,
        language: getLanguage(),
      },
    })
      .then((response) => {
        this.setState({
          data: response.data,
          editable: !response.data.info.reportLocked,
          content: response.data.content ? response.data.content : {},
        });
      })
      .catch((error) => {});
  };

  _benchmarkOnChange = (event) => {
    const name = event.target.name;
    this.setState({
      [name]: event.target.value,
    });
  };

  _benchmarkOnSubmit = async (event) => {
    event.preventDefault();

    this.setState({
      benchmarkLoading: true,
    });

    await this.loadData();

    this.setState({
      benchmarkLoading: false,
    });
  };

  _contentOnChange = (event) => {
    console.log("UPDATING", event.target.name, event.target.value);
    if (this.state.saveTimeout) {
      console.log("Clearing timeout");
      clearTimeout(this.state.saveTimeout);
    }

    let contentUpdated = this.state.contentUpdated;
    contentUpdated[event.target.name] = event.target.value;

    this.setState({
      contentUpdated: contentUpdated,
      saveTimeout: setTimeout(this._saveContent, 3000),
    });
  };

  _saveContent = async () => {
    console.log("Saving content");

    const contentToSave = this.state.contentUpdated;
    this.setState({
      contentUpdated: {},
    });

    await API.post("", {
      id: this.state.id,
      action: "reportContentSaveItems",
      language: getLanguage(),
      content: contentToSave,
    })
      .then((response) => {})
      .catch((error) => {
        toast(this.state.messages.changesError, {
          type: "error",
        });

        this.setState({
          contentUpdated: { ...contentToSave, ...this.state.contentUpdated },
        });
      });
  };

  render() {
    const {
      id,
      ab,
      benchmarkCountry,
      benchmarkIndustry,
      benchmarkGroup,
      benchmarkLoading,
      categories,
      content,
      data,
      editable,
      messages,
    } = this.state;

    if (!data) {
      return <div className="loading">{messages.loading}</div>;
    }

    return (
      <div className="page__wrapper">
        {!editable && (
          <div className="page__notice">{messages.reportLocked}</div>
        )}
        <div className="page__report">
          <h2>{messages.report}</h2>
          <Helmet>
            <title>{data.info.name} - Employer Partner</title>
          </Helmet>
          <div className="backlink">
            <NavLink to={`/${id}`} />
          </div>
          {data.info.advanced === 1 && (
            <div className="report__tabs">
              <span
                className={classNames("button", { "button--light": ab })}
                onClick={() => this.setState({ ab: false })}
              >
                {messages.employerPartner}
              </span>
              <span
                className={classNames("button", { "button--light": !ab })}
                onClick={() => this.setState({ ab: true })}
              >
                {messages.aboveBeyond}
              </span>
            </div>
          )}
          <div className="report__print">
            <button className="button" onClick={() => window.print()}>
              {messages.download}
            </button>
          </div>

          {ab && (
            <>
              <ReportContent
                ab={true}
                zoomed={true}
                background={`/assets/images/report/ab/${getLanguage()}/slide-01.jpg`}
              >
                <div style={{ padding: "0 0 0 0" }}>
                  <h1
                    style={{
                      fontFamily: "DmSansBold",
                      fontSize: "1.8rem",
                      margin: "70px 0 20px 0",
                    }}
                  >
                    {messages.abCoverIntro}
                    <br />
                    <span style={{ color: "#dfaf56" }}>{data.info.name}</span>
                    <br />
                    {messages.abCoverOutro}
                  </h1>
                  <p
                    style={{ maxWidth: "380px" }}
                    dangerouslySetInnerHTML={{ __html: messages.abCoverInfo }}
                  />
                  <p>
                    <strong>
                      {messages.abCoverCertificated}
                      {` `}
                      <span
                        style={{ color: "#dfaf56" }}
                        onInput={(event) =>
                          this._contentOnChange({
                            target: {
                              name: "abReportDate",
                              value: event.currentTarget.textContent,
                            },
                          })
                        }
                        contentEditable={editable}
                        suppressContentEditableWarning={true}
                      >
                        {content.abReportDate
                          ? content.abReportDate
                          : data.info.year}
                      </span>
                    </strong>
                  </p>
                </div>
              </ReportContent>

              <ReportContent
                ab={true}
                hideBadge={true}
                background={`/assets/images/report/ab/${getLanguage()}/slide-02.jpg`}
              >
                <div
                  dangerouslySetInnerHTML={{ __html: abAbout[getLanguage()] }}
                />
              </ReportContent>
              <ReportContent
                ab={true}
                hideBadge={true}
                background={`/assets/images/report/ab/${getLanguage()}/slide-03.jpg`}
              >
                <div
                  dangerouslySetInnerHTML={{
                    __html: abAboutCerifiers[getLanguage()],
                  }}
                />
              </ReportContent>
              <ReportContent
                ab={true}
                hideBadge={true}
                background={`/assets/images/report/ab/${getLanguage()}/slide-04.jpg`}
              >
                <div
                  dangerouslySetInnerHTML={{
                    __html: abMethodology[getLanguage()],
                  }}
                />
              </ReportContent>
              <ReportContent
                ab={true}
                background={`/assets/images/report/ab/${getLanguage()}/slide-05.jpg`}
              >
                <div
                  dangerouslySetInnerHTML={{
                    __html: abAboutCertificate[getLanguage()],
                  }}
                />
              </ReportContent>
              <ReportContent
                ab={true}
                background={`/assets/images/report/ab/${getLanguage()}/slide-06.jpg`}
              ></ReportContent>
              <ReportContent
                ab={true}
                background={`/assets/images/report/ab/${getLanguage()}/slide-07.jpg`}
              >
                <div
                  dangerouslySetInnerHTML={{
                    __html: abEpMethodology[getLanguage()],
                  }}
                />
              </ReportContent>
              <ReportContent
                ab={true}
                background={`/assets/images/report/ab/${getLanguage()}/slide-08.jpg`}
              ></ReportContent>
              <ReportContent
                ab={true}
                background={`/assets/images/report/ab/${getLanguage()}/slide-09.jpg`}
              >
                <div
                  dangerouslySetInnerHTML={{
                    __html: abEvaluation[getLanguage()],
                  }}
                />
              </ReportContent>
              <ReportContent
                ab={true}
                background={`/assets/images/report/ab/${getLanguage()}/slide-10.jpg`}
              >
                <div
                  dangerouslySetInnerHTML={{
                    __html: abExecSummary[getLanguage()],
                  }}
                />
              </ReportContent>

              <ReportContent
                ab={true}
                background={`/assets/images/report/ab/${getLanguage()}/slide-11.jpg`}
              >
                <div
                  dangerouslySetInnerHTML={{
                    __html: abCategories[getLanguage()],
                  }}
                />
                <ul
                  style={{
                    color: "#dfaf56",
                    left: "445px",
                    position: "absolute",
                    top: "345px",
                  }}
                >
                  {data.aboveBeyond.map((item, key) => (
                    <>
                      {item.percentage >= 70 && (
                        <li key={key}>
                          <strong>{item.category}</strong>
                        </li>
                      )}
                    </>
                  ))}
                </ul>
              </ReportContent>

              <ReportContent additionalClass="report__ab__results" ab={true}>
                <h2
                  style={{ fontSize: "1.6rem", margin: "40px 0 0 0" }}
                  dangerouslySetInnerHTML={{ __html: messages.abResultsTitle }}
                />
                <div
                  style={{
                    alignItems: "center",
                    display: "flex",
                    paddingBottom: "40px",
                  }}
                >
                  <div>
                    <ReportCircleChart
                      value={data.result.overal}
                      previousValue={data.result.average}
                      size={180}
                      stroke={30}
                      gold={true}
                    />
                    <ReportLegend
                      first={messages.yourOrganization}
                      second={messages.employerPartnerAvg}
                    />
                  </div>
                  <div
                    className="report__ab__info"
                    dangerouslySetInnerHTML={{ __html: messages.abResultsInfo }}
                  />
                  <div className="report__ab__chart">
                    <BarChart
                      margin={{ top: 15, right: 5, bottom: 5, left: 5 }}
                      width={400}
                      height={400}
                      data={data.aboveBeyond.filter(
                        (item) => item.percentage >= 70
                      )}
                    >
                      <Bar
                        name={data.info.year}
                        dataKey="percentage"
                        fill="#f9d877"
                      >
                        <LabelList
                          position="top"
                          formatter={(value) => `${value}%`}
                        />
                      </Bar>
                      <XAxis
                        type="category"
                        dataKey="category"
                        width={200}
                        interval={0}
                        height={140}
                      />
                      <YAxis type="number" domain={[0, 100]} />
                    </BarChart>
                  </div>
                </div>
              </ReportContent>

              <ReportContent ab={true}>
                <div style={{ display: "flex" }}>
                  <div style={{ padding: "60px 0 0 0" }}>
                    <h2
                      style={{ fontSize: "1.6rem" }}
                      dangerouslySetInnerHTML={{
                        __html: messages.abResultsCategoriesTitle,
                      }}
                    />
                    <p
                      style={{ maxWidth: "240px" }}
                      dangerouslySetInnerHTML={{
                        __html: messages.abResultsCategoriesInfo,
                      }}
                    />
                  </div>
                  <div>
                    <RadarChart
                      outerRadius={150}
                      width={500}
                      height={500}
                      data={data.aboveBeyond}
                      fontSize={11}
                    >
                      <PolarGrid />
                      <PolarAngleAxis dataKey="categoryWithValue" />
                      <PolarRadiusAxis
                        fontSize={9}
                        angle={90}
                        domain={[0, 100]}
                      />
                      <Radar
                        dataKey="percentage"
                        stroke="#b9762c"
                        fill="#f9d877"
                        fillOpacity={0.6}
                      />
                    </RadarChart>
                  </div>
                </div>
              </ReportContent>

              <ReportBenchmark
                ab={true}
                benchmarkLabel={messages.aboveBeyondAvg}
                id="page-ab-benchmark"
                title={messages.abHighlightedNumbers}
                messages={messages}
                data={data.abBenchmarksPercentage.slice(0, 7)}
              />

              <ReportBenchmark
                ab={true}
                benchmarkLabel={messages.aboveBeyondAvg}
                title={messages.abHighlightedNumbers}
                messages={messages}
                data={data.abBenchmarksPercentage.slice(7)}
              />

              <ReportContent ab={true} title={messages.abHighlightedNumbers}>
                <ReportCustomBenchmark
                  benchmarks={data.abBenchmarks}
                  labelAverage={messages.aboveBeyondAvg}
                />
              </ReportContent>

              {data.aboveBeyond.map((item, key) => (
                <>
                  {item.percentage >= 70 && (
                    <>
                      <ReportContent
                        ab={true}
                        id="page-ab-steps"
                        background={`/assets/images/report/ab/${getLanguage()}/slide-category.jpg`}
                      >
                        <div
                          dangerouslySetInnerHTML={{
                            __html:
                              abCategoryDescription[
                                item.category.toLowerCase()
                              ][getLanguage()],
                          }}
                        />
                      </ReportContent>
                      <ReportAbHighlights
                        id="page-ab-highlights"
                        background={`/assets/images/report/ab/${getLanguage()}/slide-background.jpg`}
                        title={`<span style="color:#dfaf56">${item.category}:</span> ${messages.abWhatYouDoBest}`}
                        onChange={this._contentOnChange}
                        name={`abHighLights${item.category}`}
                        value={content[`abHighLights${item.category}`]}
                        suggestions={data.abHighlights.filter(
                          (highlight) =>
                            highlight.category === item.category &&
                            highlight.grade === 4
                        )}
                      />
                      <ReportAbHighlights
                        id="page-ab-highlights-additional"
                        additionalClass={
                          content[`abHighLightsAdditional${item.category}`]
                            ? null
                            : "report__page__wrapper--empty"
                        }
                        background={`/assets/images/report/ab/${getLanguage()}/slide-background.jpg`}
                        title={`<span style="color:#dfaf56">${item.category}:</span> ${messages.abWhatYouDoBest}`}
                        onChange={this._contentOnChange}
                        name={`abHighLightsAdditional${item.category}`}
                        value={
                          content[`abHighLightsAdditional${item.category}`]
                        }
                        suggestions={data.abHighlights.filter(
                          (highlight) =>
                            highlight.category === item.category &&
                            highlight.grade === 4
                        )}
                      />
                    </>
                  )}
                </>
              ))}

              <ReportFullInput
                editable={editable}
                ab={true}
                name="wordsFromTeam"
                title={messages.abWordsFromCertificationTeam}
                defaultValue={content.wordsFromTeam}
                onChange={this._contentOnChange}
              />

              <ReportContent
                ab={true}
                background={`/assets/images/report/ab/${getLanguage()}/slide-29.jpg`}
              ></ReportContent>
              <ReportContent
                ab={true}
                background={`/assets/images/report/ab/${getLanguage()}/slide-30.jpg`}
              ></ReportContent>
              <ReportContent
                ab={true}
                background={`/assets/images/report/ab/${getLanguage()}/slide-final.jpg`}
              >
                <>
                  <a
                    href="https://www.linkedin.com/company/employer-partner/"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{
                      display: "block",
                      height: "20px",
                      left: "110px",
                      position: "absolute",
                      top: "242px",
                      width: "130px",
                    }}
                  ></a>
                  <a
                    href="mailto:contact@employerpartner.eu"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{
                      display: "block",
                      height: "20px",
                      left: "110px",
                      position: "absolute",
                      top: "277px",
                      width: "190px",
                    }}
                  ></a>
                  <a
                    href="https://selectio.hr/"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{
                      display: "block",
                      height: "20px",
                      left: "100px",
                      position: "absolute",
                      top: "400px",
                      width: "100px",
                    }}
                  ></a>
                  <a
                    href="https://employerpartner.eu/"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{
                      display: "block",
                      height: "20px",
                      left: "180px",
                      position: "absolute",
                      top: "400px",
                      width: "130px",
                    }}
                  ></a>
                </>
              </ReportContent>
            </>
          )}

          {!ab && (
            <>
              <ReportCover
                editable={editable}
                info={data.info}
                content={content}
                onChange={this._contentOnChange}
              />
              <ReportIndex passed={data.info.passed} />
              {!data.info.passed && (
                <>
                  <ReportContent
                    id="page-selectio"
                    background="/assets/images/report-bg-logo.png"
                  >
                    <div
                      style={{ fontSize: "0.8rem", padding: "0 0 0 320px" }}
                      dangerouslySetInnerHTML={{
                        __html: audit.about[getLanguage()],
                      }}
                    />
                  </ReportContent>
                </>
              )}

              <ReportContent>
                <div>
                  <img
                    style={{ maxWidth: "100%" }}
                    alt=""
                    src={`/assets/images/temp-grow-${getLanguage()}.png`}
                  />
                </div>
              </ReportContent>
              {data.info.passed && (
                <>
                  <ReportForEmployer />
                  <ReportIntro />
                </>
              )}
              {!data.info.passed && (
                <>
                  <ReportContent
                    id="page-hr-analysis"
                    background={`/assets/images/report-infochart-right-${getLanguage()}.png`}
                  >
                    <div
                      style={{
                        fontSize: "0.9rem",
                        padding: "0 360px 0 0",
                        width: "700px",
                      }}
                      dangerouslySetInnerHTML={{
                        __html: audit.hrAnalysis[getLanguage()],
                      }}
                    />
                  </ReportContent>
                  <ReportContent
                    id="page-structural-overview"
                    background={`/assets/images/report-infochart-${getLanguage()}.png`}
                  >
                    <div
                      style={{
                        fontSize: "0.9rem",
                        padding: "0 0 0 450px",
                      }}
                      dangerouslySetInnerHTML={{
                        __html: audit.structuralOverview[getLanguage()],
                      }}
                    />
                  </ReportContent>
                  <ReportContent
                    id="page-winners"
                    background={`/assets/images/report-audit-5-${getLanguage()}.png`}
                  ></ReportContent>
                </>
              )}
              <ReportInfo />
              {/*
              <ReportContent>
                <h2 style={{ margin: '0 0 -15px 0', zIndex: 2 }}>Current award holders</h2>
                <div>
                  <img
                    style={{ maxWidth: "100%" }}
                    alt=""
                    src="/assets/images/report-holders.png"
                  />
                </div>
              </ReportContent>
              */}

              <ReportContent
                hideBadge={true}
                id="page-your-result"
                background="/assets/images/report-cover-blue.jpg"
              >
                <div
                  style={{
                    color: "#fff",
                    padding: "0 0 0 0",
                    width: "400px",
                  }}
                >
                  <h2>{messages.yourResults}</h2>
                  <p>{messages.yourResultsIntro}</p>
                </div>
              </ReportContent>
              <ReportOveral
                title={messages.overallResult}
                description={messages.overallResultIntro}
              >
                <ReportCircleChart
                  value={data.result.overal}
                  previousValue={data.result.previous}
                  size={260}
                  stroke={40}
                  showLine={true}
                />
                {data.result.previous && (
                  <ReportLegend
                    first={data.info.year}
                    second={data.info.previousYear}
                  />
                )}
              </ReportOveral>
              <ReportChart
                id="page-result-benchmark"
                title={messages.overallResult}
                subtitle={messages.benchmarkAnalysis}
                className="report_chart--overall"
                options={
                  <div className="report__options">
                    <div className="report__benchmark">
                      <h3>{messages.benchmarkTitle}</h3>
                      <form method="post" onSubmit={this._benchmarkOnSubmit}>
                        <fieldset className="fieldset">
                          <label className="label">{messages.country}</label>
                          <select
                            name="benchmarkCountry"
                            className="input"
                            onChange={this._benchmarkOnChange}
                          >
                            <option value="">-</option>
                            {getCountries().map((item, key) => (
                              <option key={key} value={item}>
                                {messages.countries[item]}
                              </option>
                            ))}
                          </select>
                        </fieldset>
                        <fieldset className="fieldset">
                          <label className="label">{messages.industry}</label>
                          <select
                            name="benchmarkIndustry"
                            className="input input--medium"
                            onChange={this._benchmarkOnChange}
                          >
                            <option value="">-</option>
                            {categories.map((item, key) => (
                              <option key={key} value={item.id}>
                                {messages.primaryBusinessType[item.id]}
                              </option>
                            ))}
                          </select>
                        </fieldset>
                        {data.info.company.group && (
                          <fieldset className="fieldset">
                            <label className="label">
                              {messages.companyGroup}
                            </label>
                            <select
                              name="benchmarkGroup"
                              className="input input--medium"
                              onChange={this._benchmarkOnChange}
                            >
                              <option value="">-</option>
                              <option value="1">{messages.show}</option>
                            </select>
                          </fieldset>
                        )}
                        <fieldset>
                          {benchmarkLoading ? (
                            <Loader />
                          ) : (
                            <button className="button">
                              {messages.benchmarkSubmit}
                            </button>
                          )}
                        </fieldset>
                      </form>
                    </div>
                  </div>
                }
              >
                {benchmarkLoading ? (
                  <Loader />
                ) : (
                  <ComposedChart
                    width={500}
                    height={300}
                    data={[
                      {
                        name: "",
                        grade: data.result.overal,
                        others: data.result.average,
                        benchmarkCountry: data.result.benchmarkCountry,
                        benchmarkIndustry: data.result.benchmarkIndustry,
                        benchmarkGroup: data.result.benchmarkGroup,
                      },
                    ]}
                  >
                    <Bar
                      name={messages.yourOrganization}
                      dataKey="grade"
                      fill="#3dc5f1"
                    >
                      <LabelList
                        position="inside"
                        formatter={(value) => `${value}%`}
                      />
                    </Bar>
                    <Bar
                      name={messages.employerPartnerAvg}
                      dataKey="others"
                      fill="#eaeaea"
                    >
                      <LabelList
                        position="inside"
                        formatter={(value) => `${value}%`}
                      />
                    </Bar>

                    {benchmarkCountry && (
                      <Bar
                        name={messages.countries[this.state.benchmarkCountry]}
                        dataKey="benchmarkCountry"
                        fill="#022E49"
                      >
                        <LabelList
                          position="inside"
                          style={{ fill: "#fff" }}
                          formatter={(value) => `${value}%`}
                        />
                      </Bar>
                    )}

                    {benchmarkIndustry && (
                      <Bar
                        name={
                          messages.primaryBusinessType[
                            this.state.benchmarkIndustry
                          ]
                        }
                        dataKey="benchmarkIndustry"
                        fill="#56C4C4"
                      >
                        <LabelList
                          position="inside"
                          formatter={(value) => `${value}%`}
                        />
                      </Bar>
                    )}

                    {benchmarkGroup && (
                      <Bar
                        name={messages.companyGroup}
                        dataKey="benchmarkGroup"
                        fill="#56C4C4"
                      >
                        <LabelList
                          position="inside"
                          formatter={(value) => `${value}%`}
                        />
                      </Bar>
                    )}

                    <XAxis type="category" dataKey="name" width={200} />
                    <YAxis type="number" domain={[0, 100]} />
                    <Legend
                      align="left"
                      wrapperStyle={{ left: 64 }}
                      formatter={(value, entry, index) => (
                        <span className="report__legend__label">{value}</span>
                      )}
                    />
                    <ReferenceLine y="75" stroke="#ea3158" />
                  </ComposedChart>
                )}
              </ReportChart>

              <ReportChart
                id="page-key-topics"
                title={messages.resultsKeyTopics}
                description={messages.resultsKeyTopicsDescription}
              >
                <ComposedChart
                  width={500}
                  height={350}
                  data={data.keyTopics}
                  layout="vertical"
                >
                  <XAxis
                    type="number"
                    domain={[0, 3]}
                    ticks={[0, 0.5, 1, 1.5, 2, 2.5, 3]}
                  />
                  <Bar name={data.info.year} dataKey="grade" fill="#3dc5f1" />
                  <YAxis type="category" dataKey="content" width={200} />
                  <ReferenceLine x="2" stroke="#ea3158" />
                  {data.info.previousYear && (
                    <>
                      <Bar
                        name={data.info.previousYear}
                        dataKey="previousGrade"
                        fill="#014e7d"
                      />
                      <Legend
                        align="left"
                        wrapperStyle={{ left: 204 }}
                        formatter={(value, entry, index) => (
                          <span className="report__legend__label">{value}</span>
                        )}
                      />
                    </>
                  )}
                </ComposedChart>
              </ReportChart>

              <ReportChart
                id="page-key-topics-benchmark"
                title={messages.resultsKeyTopics}
                subtitle={messages.benchmarkAnalysis}
              >
                <ComposedChart
                  width={500}
                  height={350}
                  data={data.keyTopics}
                  layout="vertical"
                >
                  <Bar
                    name={messages.yourOrganization}
                    dataKey="grade"
                    fill="#3dc5f1"
                  />
                  <Bar
                    name={messages.employerPartnerAvg}
                    dataKey="others"
                    fill="#eaeaea"
                  />

                  {benchmarkCountry && (
                    <Bar
                      name={messages.countries[this.state.benchmarkCountry]}
                      dataKey="benchmarkCountry"
                      fill="#022E49"
                    />
                  )}

                  {benchmarkIndustry && (
                    <Bar
                      name={
                        messages.primaryBusinessType[
                          this.state.benchmarkIndustry
                        ]
                      }
                      dataKey="benchmarkIndustry"
                      fill="#56C4C4"
                    />
                  )}

                  {benchmarkGroup && (
                    <Bar
                      name={messages.companyGroup}
                      dataKey="benchmarkGroup"
                      fill="#56C4C4"
                    />
                  )}

                  <XAxis
                    type="number"
                    domain={[0, 3]}
                    ticks={[0, 0.5, 1, 1.5, 2, 2.5, 3]}
                  />
                  <YAxis type="category" dataKey="content" width={200} />
                  <ReferenceLine x="2" stroke="#ea3158" />
                  <Legend
                    align="left"
                    wrapperStyle={{ left: 204 }}
                    formatter={(value, entry, index) => (
                      <span className="report__legend__label">{value}</span>
                    )}
                  />
                </ComposedChart>
              </ReportChart>

              <ReportChart
                id="page-elc"
                title={messages.resultsELCSteps}
                description=""
              >
                <BarChart
                  margin={{ top: 15, right: 5, bottom: 5, left: 5 }}
                  width={500}
                  height={400}
                  data={data.categories}
                >
                  <Bar name={data.info.year} dataKey="grade" fill="#3dc5f1">
                    <LabelList
                      position="top"
                      formatter={(value) => `${value}%`}
                    />
                  </Bar>
                  <XAxis
                    type="category"
                    dataKey="content"
                    width={200}
                    interval={0}
                    height={140}
                    angle="-60"
                    textAnchor="end"
                  />
                  <YAxis type="number" domain={[0, 100]} />

                  {data.info.previousYear && (
                    <>
                      <Bar
                        name={data.info.previousYear}
                        dataKey="previousGrade"
                        fill="#014e7d"
                      >
                        <LabelList
                          position="top"
                          formatter={(value) => `${value}%`}
                        />
                      </Bar>
                      <Legend
                        align="left"
                        wrapperStyle={{ left: 64 }}
                        formatter={(value, entry, index) => (
                          <span className="report__legend__label">{value}</span>
                        )}
                      />
                    </>
                  )}
                </BarChart>
              </ReportChart>
              <ReportChart
                id="page-elc-benchmark"
                title={messages.resultsELCSteps}
                description={messages.benchmarkAnalysis}
              >
                <BarChart
                  margin={{ top: 15, right: 5, bottom: 5, left: 5 }}
                  width={500}
                  height={400}
                  data={data.categories}
                >
                  <Bar
                    name={messages.yourOrganization}
                    dataKey="grade"
                    fill="#3dc5f1"
                  >
                    <LabelList
                      position="top"
                      formatter={(value) => `${value}%`}
                    />
                  </Bar>
                  <Bar
                    name={messages.employerPartnerAvg}
                    dataKey="others"
                    fill="#eaeaea"
                  >
                    <LabelList
                      position="top"
                      formatter={(value) => `${value}%`}
                    />
                  </Bar>

                  {benchmarkCountry && (
                    <Bar
                      name={messages.countries[benchmarkCountry]}
                      dataKey="benchmarkCountry"
                      fill="#022E49"
                    >
                      <LabelList
                        position="top"
                        formatter={(value) => `${value}%`}
                      />
                    </Bar>
                  )}

                  {benchmarkIndustry && (
                    <Bar
                      name={messages.primaryBusinessType[benchmarkIndustry]}
                      dataKey="benchmarkIndustry"
                      fill="#56C4C4"
                    >
                      <LabelList
                        position="top"
                        formatter={(value) => `${value}%`}
                      />
                    </Bar>
                  )}

                  {benchmarkGroup && (
                    <Bar
                      name={messages.companyGroup}
                      dataKey="benchmarkGroup"
                      fill="#56C4C4"
                    >
                      <LabelList
                        position="top"
                        formatter={(value) => `${value}%`}
                      />
                    </Bar>
                  )}

                  <XAxis
                    type="category"
                    dataKey="content"
                    width={200}
                    interval={0}
                    height={140}
                    angle="-60"
                    textAnchor="end"
                  />
                  <YAxis type="number" domain={[0, 100]} />
                  <Legend
                    align="left"
                    wrapperStyle={{ left: 64 }}
                    formatter={(value, entry, index) => (
                      <span className="report__legend__label">{value}</span>
                    )}
                  />
                </BarChart>
              </ReportChart>
              <ReportChart
                id="page-steps"
                title={messages.resultsDiveIn}
                description={messages.resultsDiveInDescription}
                className="report__page--dark"
              />

              {data.categories.map((item, key) => {
                let barSize = 15;
                if (benchmarkCountry) barSize -= 5;
                if (benchmarkIndustry) barSize -= 5;
                if (benchmarkGroup) barSize -= 5;
                if (data.info.previousYear) barSize -= 5;

                return (
                  <div key={key}>
                    <ReportCategoryChart
                      title={item.content}
                      value={item.grade}
                      previousValue={item.previousGrade}
                    >
                      <BarChart
                        width={550}
                        height={50 + item.areas.length * 60}
                        data={item.areas}
                        layout="vertical"
                      >
                        <Bar
                          name={
                            data.info.previousYear
                              ? data.info.year
                              : messages.yourOrganization
                          }
                          dataKey="grade"
                          fill="#3dc5f1"
                          barSize={barSize}
                        />

                        {data.info.previousYear && (
                          <Bar
                            name={data.info.previousYear}
                            dataKey="previousGrade"
                            fill="#014e7d"
                            barSize={barSize}
                          />
                        )}

                        <Bar
                          name={messages.employerPartnerAvg}
                          dataKey="others"
                          fill="#eaeaea"
                          barSize={barSize}
                        />

                        {benchmarkCountry && (
                          <Bar
                            name={
                              messages.countries[this.state.benchmarkCountry]
                            }
                            dataKey="benchmarkCountry"
                            fill="#022E49"
                            barSize={barSize}
                          />
                        )}

                        {benchmarkIndustry && (
                          <Bar
                            name={
                              messages.primaryBusinessType[
                                this.state.benchmarkIndustry
                              ]
                            }
                            dataKey="benchmarkIndustry"
                            fill="#56C4C4"
                            barSize={barSize}
                          />
                        )}

                        {benchmarkGroup && (
                          <Bar
                            name={messages.companyGroup}
                            dataKey="benchmarkGroup"
                            fill="#56C4C4"
                            barSize={barSize}
                          />
                        )}

                        <XAxis
                          type="number"
                          domain={[0, 3]}
                          ticks={[0, 0.5, 1, 1.5, 2, 2.5, 3]}
                        />
                        <YAxis type="category" dataKey="content" width={200} />
                        <Legend
                          align="left"
                          wrapperStyle={{ left: 204 }}
                          formatter={(value, entry, index) => (
                            <span className="report__legend__label">
                              {value}
                            </span>
                          )}
                        />
                      </BarChart>
                    </ReportCategoryChart>
                    <ReportCategoryHightlightsSuggestions
                      heading={messages.highlights}
                      name={`highlights_${item.id}`}
                      title={item.content}
                      score={item.grade}
                      previousScore={item.previousGrade}
                      content={content[`highlights_${item.id}`]}
                      onChange={this._contentOnChange}
                      suggestions={item.highlights}
                      editable={editable}
                    />
                    <ReportCategoryHightlightsSuggestions
                      heading={messages.suggestions}
                      name={`suggestions_${item.id}`}
                      title={item.content}
                      score={item.grade}
                      previousScore={item.previousGrade}
                      content={content[`suggestions_${item.id}`]}
                      onChange={this._contentOnChange}
                      suggestions={item.suggestions}
                      editable={editable}
                    />
                    {!data.info.passed && (
                      <ReportCategoryHightlightsSuggestions
                        heading={messages.suggestions}
                        name={`suggestions_additional_${item.id}`}
                        title={item.content}
                        score={item.grade}
                        previousScore={item.previousGrade}
                        content={content[`suggestions_additional_${item.id}`]}
                        onChange={this._contentOnChange}
                        suggestions={item.suggestions}
                        editable={editable}
                      />
                    )}
                  </div>
                );
              })}

              <ReportHighlights
                id="page-care-highlights"
                title={messages.yourCareTitleMultiline}
                onChange={this._contentOnChange}
                name="highlightsFirst"
                value={content.highlightsFirst}
                suggestions={data.highlights.filter(
                  (item) => item.grade >= 3 || item.grade === null
                )}
                editable={editable}
              />

              {data.info.passed && (
                <ReportHighlights
                  id="page-care-highlights2"
                  title={messages.yourCareTitleMultiline}
                  onChange={this._contentOnChange}
                  name="highlightsSecond"
                  value={content.highlightsSecond}
                  suggestions={data.highlights.filter(
                    (item) => item.grade >= 3 || item.grade === null
                  )}
                  editable={editable}
                />
              )}

              <ReportHighlights
                id="page-care-suggestions"
                type="dimmed"
                title={messages.keepInMindTitle}
                onChange={this._contentOnChange}
                name="suggestions"
                value={content.suggestions}
                suggestions={data.highlights.filter(
                  (item) => item.grade < 3 && item.grade !== null
                )}
                editable={editable}
              />

              {!data.info.passed && (
                <ReportHighlights
                  id="page-care-suggestions-additional"
                  type="dimmed"
                  title={messages.keepInMindTitle}
                  onChange={this._contentOnChange}
                  name="suggestionsAdditional"
                  value={content.suggestionsAdditional}
                  suggestions={data.highlights.filter(
                    (item) => item.grade < 3 && item.grade !== null
                  )}
                  editable={editable}
                />
              )}

              <ReportContent
                id="page-care-benchmark"
                title={messages.yourCareTitle}
                subtitle={messages.benchmarkAnalysis}
              >
                <ReportCustomBenchmark
                  benchmarks={data.benchmarks}
                  labelAverage={messages.employerPartnerAvg}
                />
              </ReportContent>
              <ReportBenchmark
                benchmarkLabel={messages.employerPartnerAvg}
                title={messages.yourCareTitle}
                subtitle={messages.benchmarkAnalysis}
                messages={messages}
                data={data.benchmarksPercentage.slice(0, 9)}
              />
              <ReportBenchmark
                benchmarkLabel={messages.employerPartnerAvg}
                title={messages.yourCareTitle}
                subtitle={messages.benchmarkAnalysis}
                messages={messages}
                data={data.benchmarksPercentage.slice(9)}
              />

              <ReportEducations
                id="educations"
                editable={editable}
                title={messages.educationTitle}
                subtitle={messages.educationIntro}
                content={content.educations ? content.educations : ""}
                onChange={this._contentOnChange}
                suggestions={data.educations}
              />

              {data.info.passed ? (
                <>
                  <ReportConfirmation certifiers={data.certifiers} />
                  <ReportFullInput
                    editable={editable}
                    id="page-whatsnext"
                    name="whatsNext"
                    title={messages.whatsNext}
                    defaultValue={
                      content.whatsNext
                        ? content.whatsNext
                        : whatsNext[getLanguage()]
                    }
                    onChange={this._contentOnChange}
                  />
                </>
              ) : (
                <>
                  <ReportContent
                    hideBadge={true}
                    id="page-key-activities"
                    background="/assets/images/report-cover-blue.jpg"
                  >
                    <div
                      style={{
                        color: "#fff",
                        padding: "0 0 0 0",
                        width: "400px",
                      }}
                      dangerouslySetInnerHTML={{
                        __html: audit.keyActivities[getLanguage()],
                      }}
                    />
                  </ReportContent>
                  <ReportFullInput
                    editable={editable}
                    id="page-strengths-strategy"
                    name="strengthsStrategy"
                    title={messages.strengthsStrategy}
                    defaultValue={
                      content.strengthsStrategy ? content.strengthsStrategy : ""
                    }
                    onChange={this._contentOnChange}
                  />
                  <ReportFullInput
                    editable={editable}
                    id="page-activities-strategy"
                    name="activitiesStrategy"
                    title={messages.neededActivitiesStrategy}
                    defaultValue={
                      content.activitiesStrategy
                        ? content.activitiesStrategy
                        : ""
                    }
                    onChange={this._contentOnChange}
                  />
                  <ReportFullInput
                    editable={editable}
                    id="page-strengths-investment"
                    name="strengthsInvestment"
                    title={messages.strengthsInvestment}
                    defaultValue={
                      content.strengthsInvestment
                        ? content.strengthsInvestment
                        : ""
                    }
                    onChange={this._contentOnChange}
                  />
                  <ReportFullInput
                    editable={editable}
                    id="page-activities-investment"
                    name="activitiesInvestment"
                    title={messages.neededActivitiesInvestment}
                    defaultValue={
                      content.activitiesInvestment
                        ? content.activitiesInvestment
                        : ""
                    }
                    onChange={this._contentOnChange}
                  />
                  <ReportFullInput
                    editable={editable}
                    id="page-strengths-processes"
                    name="strengthsProcesses"
                    title={messages.strengthsProcesses}
                    defaultValue={
                      content.strengthsProcesses
                        ? content.strengthsProcesses
                        : ""
                    }
                    onChange={this._contentOnChange}
                  />
                  <ReportFullInput
                    editable={editable}
                    id="page-activities-processes"
                    name="activitiesProcesses"
                    title={messages.neededActivitiesHr}
                    defaultValue={
                      content.activitiesProcesses
                        ? content.activitiesProcesses
                        : ""
                    }
                    onChange={this._contentOnChange}
                  />

                  <ReportContent
                    hideBadge={true}
                    id="page-action-plan"
                    background="/assets/images/report-cover-blue.jpg"
                  >
                    <div
                      style={{
                        color: "#fff",
                        padding: "0 0 0 0",
                        width: "400px",
                      }}
                      dangerouslySetInnerHTML={{
                        __html: audit.actionPlanAndTimeline[getLanguage()],
                      }}
                    />
                  </ReportContent>
                  <ReportFullInput
                    editable={editable}
                    id="page-action-plan"
                    name="actionPlan"
                    title={messages.actionPlanPriorities}
                    defaultValue={content.actionPlan ? content.actionPlan : ""}
                    onChange={this._contentOnChange}
                  />
                  <ReportFullInput
                    editable={editable}
                    id="page-action-plan-timeline"
                    name="actionPlanTimeline"
                    title={messages.actionPlanTimeline}
                    defaultValue={
                      content.actionPlanTimeline
                        ? content.actionPlanTimeline
                        : ""
                    }
                    onChange={this._contentOnChange}
                  />
                  <ReportContent
                    hideBadge={true}
                    id="page-change-management"
                    background="/assets/images/report-cover-blue.jpg"
                  >
                    <div
                      style={{
                        color: "#fff",
                        padding: "0 0 0 0",
                        width: "400px",
                      }}
                      dangerouslySetInnerHTML={{
                        __html: audit.changeManagement[getLanguage()],
                      }}
                    />
                  </ReportContent>
                  <ReportContent background="/assets/images/report-bg-logo.png">
                    <div
                      style={{ fontSize: "0.8rem", padding: "0 100px 0 320px" }}
                      dangerouslySetInnerHTML={{
                        __html: audit.agilityHrProcess[getLanguage()],
                      }}
                    />
                  </ReportContent>
                  <ReportContent
                    hideBadge={true}
                    id="page-roles"
                    background="/assets/images/report-cover-blue.jpg"
                  >
                    <div
                      style={{
                        color: "#fff",
                        padding: "0 0 0 0",
                        width: "400px",
                      }}
                      dangerouslySetInnerHTML={{
                        __html: audit.roles[getLanguage()],
                      }}
                    />
                  </ReportContent>
                  <ReportContent
                    background={`/assets/images/report-audit-9-${getLanguage()}.png`}
                  ></ReportContent>
                  <ReportContent background="/assets/images/report-audit-10.png">
                    <div
                      style={{ fontSize: "0.8rem", padding: "0 320px 0 0" }}
                      dangerouslySetInnerHTML={{
                        __html: audit.rolesInfo[getLanguage()],
                      }}
                    />
                  </ReportContent>
                  <ReportContent background="/assets/images/report-audit-11.png">
                    <div
                      style={{ fontSize: "0.8rem", padding: "0 0 0 320px" }}
                      dangerouslySetInnerHTML={{
                        __html: audit.boardRole[getLanguage()],
                      }}
                    />
                  </ReportContent>
                  <ReportContent background="/assets/images/report-audit-12.png">
                    <div
                      style={{ fontSize: "0.8rem", padding: "0 320px 0 0" }}
                      dangerouslySetInnerHTML={{
                        __html: audit.hrRole[getLanguage()],
                      }}
                    />
                  </ReportContent>
                  <ReportContent background="/assets/images/report-audit-13.png">
                    <div
                      style={{ fontSize: "0.8rem", padding: "0 0 0 320px" }}
                      dangerouslySetInnerHTML={{
                        __html: audit.managerRole[getLanguage()],
                      }}
                    />
                  </ReportContent>
                  <ReportContent
                    hideBadge={true}
                    background="/assets/images/report-bg-logo-right.png"
                  >
                    <div
                      style={{ fontSize: "0.8rem", padding: "0 320px 0 0" }}
                      dangerouslySetInnerHTML={{
                        __html: audit.competenceCulture[getLanguage()],
                      }}
                    />
                  </ReportContent>
                  <ReportContent background="/assets/images/report-bg-logo.png">
                    <div
                      style={{ fontSize: "0.8rem", padding: "0 100px 0 320px" }}
                      dangerouslySetInnerHTML={{
                        __html: audit.developmentHr[getLanguage()],
                      }}
                    />
                  </ReportContent>
                  <ReportContent background="/assets/images/report-bg-logo.png">
                    <div
                      style={{ fontSize: "0.8rem", padding: "0 100px 0 320px" }}
                      dangerouslySetInnerHTML={{
                        __html: audit.measureHr[getLanguage()],
                      }}
                    />
                  </ReportContent>
                  <ReportContent
                    hideBadge={true}
                    background="/assets/images/report-bg-logo-right.png"
                  >
                    <div
                      style={{ fontSize: "0.8rem", padding: "0 350px 0 0" }}
                      dangerouslySetInnerHTML={{
                        __html: audit.developmentBoard[getLanguage()],
                      }}
                    />
                  </ReportContent>
                  <ReportContent
                    hideBadge={true}
                    background="/assets/images/report-bg-logo-right.png"
                  >
                    <div
                      style={{ fontSize: "0.8rem", padding: "0 350px 0 0" }}
                      dangerouslySetInnerHTML={{
                        __html: audit.measureBoard[getLanguage()],
                      }}
                    />
                  </ReportContent>

                  <ReportContent
                    hideBadge={true}
                    id="page-risks"
                    background="/assets/images/report-cover-blue.jpg"
                  >
                    <div
                      style={{
                        color: "#fff",
                        padding: "0 0 0 0",
                        width: "400px",
                      }}
                      dangerouslySetInnerHTML={{
                        __html: audit.risks[getLanguage()],
                      }}
                    />
                  </ReportContent>
                  <div className="report__risks">
                    {[...Array(10)].map((x, i) => (
                      <ReportContent
                        additionalClass="report__section--padding report_midtitle_section"
                        background="/assets/images/report-background-blue-left.png"
                      >
                        <div className="report_midtitle">
                          {messages.statusQuoRisks}
                        </div>
                        <div
                          style={{ fontSize: "0.8rem" }}
                          dangerouslySetInnerHTML={{
                            __html: audit[`risks${i}`][getLanguage()],
                          }}
                        />
                      </ReportContent>
                    ))}
                  </div>
                  <ReportConfirmation certifiers={data.certifiers} />
                </>
              )}

              <ReportTimeline
                editable={editable}
                passed={data.info.passed}
                date={data.info.date}
                content={content.timeline}
                onChange={this._contentOnChange}
              />
              <ReportOutro
                title={
                  data.info.passed
                    ? messages.outroTitle
                    : messages.outroAuditTitle
                }
              />
            </>
          )}
        </div>
      </div>
    );
  }
}
